@use '../base/setting' as *;

.l-wrap {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.l-main {
  @include media-sp {
    margin-top: 68px;
  }
}