@use '../../base/setting' as *;

.sustainability-intro {
  background: url(/company/sustainability/assets/img/bg_intro-pc.jpg) no-repeat center center / cover;
  padding: 60px 24px;

  @include media-sp {
    background-image: url(/company/sustainability/assets/img/bg_intro-sp.jpg);
    padding: 40px 20px;
  }

  &__inner {
    margin: 0 auto;
    max-width: $narrow-content-width;

    p {
      line-height: 2;
    }
  }
}

.sustainability-contents {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -4px 0 0;

  &__item {
    margin: 5px 4px 0 0;

    &.-col-4 {
      width: calc(33.333% - 4px);
    }

    &.-col-3 {
      width: calc(25% - 4px);
    }

    @include media-sp {
      &.-col-4 {
        width: calc(100% - 4px);
      }

      &.-col-3 {
        width: calc(50% - 4px);
      }
    }
  }
}

.sustainability-our_sdgs-title {
  width: 100%;
  padding: 40px;
  background-image: linear-gradient(60deg, #17B29F, #85C837);

  @include media-sp {
    width: calc(100% + 40px);
    margin: 0 -20px;
    padding: 32px 20px;
  }

  h2 {
    margin-bottom: 20px;
  }

  p {
    font-size: 1.8rem;
    font-weight: 700;
    color: $white;

    @include media-sp {
      font-size: 1.4rem;
    }
  }
}

.sustainability-our_sdgs-detail {
  &__kv {
    margin-bottom: 48px;

    @include media-sp {
      margin: 0 -20px 40px;
    }
  }

  &__date {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 24px;

    @include media-sp {
      margin-bottom: 20px;
    }

    span:first-child {
      font-size: 1.8rem;
      font-weight: 700;
      color: $navy_80;
      margin-right: 16px;

      @include media-sp {
        font-size: 1.6rem;
        margin-right: 4px;
      }
    }
  }

  &__sns {
    margin-bottom: 64px;

    @include media-sp {
      margin-bottom: 40px;
    }
  }

  &__contents {
    margin-bottom: 32px;
  }

  &__ttl {
    display: block;
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 0.5em;

    @include media-sp {
      font-size: 1.6rem;
    }
  }
}

.sustainability-materiality-about {
  display: flex;
  gap: 20px 16px;

  @include media-sp {
    flex-direction: column;
  }

  >div {
    flex: 1;
  }
}