@use '../base/setting' as *;

.c-breadcrumb {
  padding: 16px 56px;
  background: $white;
  line-height: 1.2;
  overflow-y: auto;
  border-bottom: 1px solid $navy_20;

  @include media-sp {
    padding: 12px 20px;
  }

  &__inner {
    ol {
      display: flex;
      white-space: nowrap;

      li {
        position: relative;
        display: flex;
        font-size: 1.2rem;

        span {
          display: inline-block;
        }

        &:not(:first-child) {
          &::before {
            content: "\e90a";
            font-family: 'icomoon';
            font-weight: normal;
            margin-right: 12px;
            color: $navy_60;
          }
        }

        &:not(:last-child) {
          color: $navy_60;

          span {
            margin-right: 12px;
          }
        }

        &:last-child {
          pointer-events: none;

          @include media-sp {
            padding-right: 15px;
          }
        }

        @media (hover: hover) {
          a:hover span {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
