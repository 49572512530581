@use '../base/setting' as *;

.c-card {
  display: block;
  position: relative;

  &.-award {
    &::before {
      content: "";
      width: 40px;
      height: 40px;
      display: block;
      background: url(/common/img/icon_award.svg) no-repeat center center / contain;
      position: absolute;
      top: -10px;
      left: -10px;
      z-index: 5;

      @include media-sp {
        width: 30px;
        height: 30px;
        top: -5px;
        left: -5px;
      }
    }
  }

  &.-bg-gray {
    background: $gray_20;
  }

  &.-bg-beige {
    background: $beige_20;
    border-radius: 16px;

    @include media-sp {
      border-radius: 8px;
    }
  }

  &.-bg-white {
    background: $white;
  }

  @include media-pc-tb {

    &.c-card.-row01,
    &.c-card.-row02,
    &.c-card.-row03 {
      display: flex;
      gap: 40px;
      flex-direction: row;
      align-items: center;

      .c-card__inner {
        flex: 1;
      }
    }

    &.c-card.-row03 {
      padding-bottom: 40px;
      border-bottom: 1px solid $navy_20;
    }
  }

  &:only-child {
    height: 100%;
  }

  &__img {
    width: 100%;
    overflow: hidden;

    &:not(:only-child) {
      margin-bottom: 15px;

      @include media-sp {
        margin-bottom: 10px;
      }
    }

    img {
      width: 100%;
      @include transition;
    }

    &.-round {
      border-radius: 16px;

      @include media-sp {
        border-radius: 8px;
      }
    }

    &.-border {
      border: 1px solid $navy_20;
    }

    &.-modal {
      position: relative;

      &::after {
        content: "\e915";
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: rgba($white, 0.9);
        border-radius: 100%;
        font-size: 2.4rem;
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        color: $orange;
        position: absolute;
        left: 20px;
        bottom: 20px;

        @include media-sp {
          width: 30px;
          height: 30px;
          font-size: 1.8rem;
          left: 10px;
          bottom: 10px;
        }
      }
    }

    .c-card.-solution-abw & {
      aspect-ratio: 16/9;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    @include media-pc-tb {

      .c-card.-row01 &,
      .c-card.-row03 & {
        width: calc(100% / 3 - 30px);
        margin-bottom: 0;
      }

      .c-card.-row02 & {
        width: calc(100% / 4 - 30px);
        margin-bottom: 0;
      }
    }

    &+.c-card__inner p {
      font-size: 1.4rem;

      @include media-sp {
        font-size: 1.2rem;
      }
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;

    .c-card.-bg-white &,
    .c-card.-bg-gray &,
    .c-card.-bg-beige & {
      padding: 20px 24px;

      @include media-sp {
        padding: 24px 20px;
      }
    }

    .c-card.-bg-white .c-card__img+&,
    .c-card.-bg-gray .c-card__img+&,
    .c-card.-bg-beige .c-card__img+& {
      padding: 0 20px 25px;

      @include media-sp {
        padding: 0 10px 15px;
      }
    }

    p {
      width: 100%;
    }
  }

  &__tag {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 8px;

    @include media-pc-tb {
      .c-card.-row01 & {
        margin-bottom: 20px;
      }

      .c-card.-case-studies & {
        width: inherit;
        margin-right: 14px;

        &+.c-card__date {
          width: inherit;
        }
      }
    }

    .c-card.-solution-abw & {
      width: inherit;
      margin-right: 14px;

      &+.c-card__date {
        width: inherit;
      }
    }
  }

  &__date {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.4rem;
    font-weight: 700;
    color: $navy_80;
    margin-bottom: 5px;

    @include media-sp {
      font-size: 1.2rem;
    }
  }

  &__ttl {
    width: 100%;
    line-height: 1.5;
    font-weight: 700;
    position: relative;
    margin-bottom: 10px;

    @include media-sp {

      .c-card.-row03 & {
        font-size: 1.4rem;
      }
    }

    &:only-child {
      margin-bottom: 0;
    }

    .c-card.-solution-abw & {
      small {
        font-size: 1.4rem;
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    width: 100%;

    @include media-sp {
      margin-top: 20px;
    }
  }

  &.-txt-white {
    color: $white;
  }
}

a.c-card .c-card__ttl::after {
  content: "\e90a";
  font-family: 'icomoon';
  font-weight: normal;
  line-height: 1;
  color: $orange;
  margin: 0 0.25em;
}

a[target="_blank"].c-card .c-card__ttl::after {
  content: "\e900";
}

a[href*=".pdf"].c-card .c-card__ttl::after {
  content: "\e904";
}

@media (hover: hover) {
  a.c-card:hover {
    .c-card__img {
      img {
        transform: scale(1.1, 1.1);
      }
    }

    .c-card__ttl span {
      text-decoration: underline;
    }
  }
}

.c-card-product {
  display: flex;
  flex-direction: column;

  &:only-child {
    height: 100%;
  }

  &__img {
    width: 100%;
    overflow: hidden;
    border-radius: 16px;

    @include media-sp {
      border-radius: 8px;
    }

    &:not(:last-child) {
      margin-bottom: 15px;

      @include media-sp {
        margin-bottom: 8px;
      }
    }

    img {
      width: 100%;
      @include transition;
    }
  }

  &__ttl {
    font-weight: 700;
    position: relative;
    margin-bottom: 10px;

    &:not(:last-child) {
      margin-bottom: 10px;

      @include media-sp {
        margin-bottom: 8px;
      }
    }
  }

  &__tag {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__txt {
    font-size: 1.4rem;

    @include media-sp {
      font-size: 1.2rem;
    }

    &:not(:last-child) {
      margin-bottom: 25px;

      @include media-sp {
        margin-bottom: 12px;
      }
    }

    &:has(+ .c-card-product__link) {
      margin-bottom: 8px;
    }
  }

  &__link {
    font-size: 1.4rem;

    @include media-sp {
      font-size: 1.2rem;
    }

    &:not(:last-child) {
      margin-bottom: 25px;

      @include media-sp {
        margin-bottom: 12px;
      }
    }

    a {
      &::after {
        content: "\e90a";
        margin-left: 0.25em;
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        color: $orange;
      }

      @media (hover: hover) {
        &:hover span {
          text-decoration: underline;
        }
      }
    }
  }

  &__btn {
    width: 100%;
    margin-top: auto;

    .c-btn {
      max-width: inherit;
    }
  }
}

.c-card-topics {
  display: block;
  overflow: hidden;
  padding-bottom: 24px;
  border-bottom: 1px solid $navy_20;

  &:only-child {
    height: 100%;
  }

  &__img {
    border: 1px solid $navy_20;
    border-radius: 16px;
    margin-bottom: 16px;
    overflow: hidden;
  }

  &__date {
    font-size: 1.4rem;
    font-weight: 700;
    color: $navy_80;
    margin-bottom: 10px;
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 10px;
    padding-right: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;

    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      @include centering(false, true);
      right: 0;
      color: $orange;
    }
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &[target="_blank"] .c-card-topics__title::after {
    content: "\e900";
  }
}


.c-card-showroom-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px 28px;

  @include media-sp {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}

.c-card-showroom {
  padding-bottom: 32px;
  border-bottom: 1px solid $navy_20;

  @include media-sp {
    padding-bottom: 24px;
  }

  &__img {
    margin-bottom: 16px;
  }

  &__tag {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
  }

  &__ttl {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__txt {
    margin-bottom: 32px;

    @include media-sp {
      margin-bottom: 24px;
    }

    p:not(:last-child) {
      margin-bottom: 1em;
    }
  }
}
