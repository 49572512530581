@use '../base/setting' as *;

.c-calendar {
  max-width: 1090px;
  margin: 0 auto;

  &__slider {
    margin: 0 -20px 16px;
    position: relative;

    @include media-sp {
      margin: 0 -10px 8px;
    }
  }

  &__item {
    vertical-align: bottom;
    padding: 0 20px;

    @include media-sp {
      padding: 0 10px;
    }
  }

  &__table {
    width: 100%;
    border-top: 1px solid $navy_20;
    border-left: 1px solid $navy_20;

    th,
    td {
      border-right: 1px solid $navy_20;
      border-bottom: 1px solid $navy_20;
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.6;
    }

    thead th {
      padding: 5px 2px;
      background: $navy_80;
      color: $white;
      text-align: center;
      font-weight: 700;
    }

    tbody {
      th {
        background: $gray_40;
        font-size: 1.2rem;
        font-weight: 400;
        padding: 6px 2px;

        @include media-sp {
          padding: 5px 2px;
        }

        &:first-child {
          color: $orange;
        }

        &:last-child {
          color: $navy_60;
        }
      }

      td {

        >span,
        >button {
          display: block;
          width: 100%;
          text-align: center;
          padding: 5px 2px;

          @include media-sp {
            padding: 4px 2px;
          }
        }

        >button {
          @include transition;

          >span {
            color: $navy_60;
            text-decoration: underline;
          }

          &:hover {
            background: #F6F8F9;

            >span {
              text-decoration: none;
            }
          }
        }

        &.-off {
          color: $orange;
          background: #F7F7F7;
        }

        @include media-sp {
          font-size: 1.2rem;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    background: $gray_20;
    font-size: 1.4rem;

    @include media-sp {
      height: 30px;
      font-size: 1rem;
    }
  }

  &__arrows {
    @include media-pc-tb {
      display: none;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 16px;
    padding: 0 20px;
  }

  &__prev,
  &__next {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: $gray_20;
    border-radius: 100%;
    cursor: pointer;

    &::before {
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $navy;
    }

    &.slick-disabled {
      cursor: default;

      &::before {
        color: $navy_40;
      }
    }
  }

  &__prev {

    &::before {
      content: "\e907";
    }
  }

  &__next {

    &::before {
      content: "\e906";
    }
  }

  &__dots {
    @include media-pc-tb {
      display: none;
    }

    .slick-dots {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px 0;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;

        button {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background: $gray_60;
          position: relative;
          text-indent: -9999px;
        }

        &.slick-active {
          button {
            outline: none;
            background: $orange;
          }
        }
      }

    }
  }
}