@use '../../base/setting' as *;

.news-detail {
  &__head {
    display: flex;
    margin-bottom: 40px;

    @include media-pc-tb {
      justify-content: space-between;
      align-items: center;
    }

    @include media-sp {
      flex-direction: column;
      margin-bottom: 32px;
    }
  }

  &__date {
    font-size: 1.8rem;
    font-weight: 700;
    color: $navy_80;
    width: 6em;
    flex-shrink: 0;
    margin-right: 24px;

    @include media-sp {
      font-size: 1.6rem;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  &__tag {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    flex: 1;
  }

  &__sns {
    flex-shrink: 0;

    @include media-sp {
      width: 100%;
      margin-top: 16px;
    }
  }
}

.news-detail-press {
  display: flex;
  border: 1px solid $gray;
  padding: 20px 0;

  @include media-sp {
    flex-direction: column;
    padding: 0 20px;
  }

  &__ttl {
    width: 32%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid $gray;
    padding: 20px 40px;

    @include media-sp {
      width: 100%;
      border-right: 0;
      border-bottom: 1px solid $gray;
      padding: 20px 0px;
    }

    >div {
      width: 100%;
      max-width: 174px;
      margin: 0 auto;

      @include media-sp {
        max-width: inherit;
      }
    }
  }

  &__address {
    width: 68%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;

    @include media-sp {
      width: 100%;
      padding: 30px 0px;
    }

    >div {
      width: 100%;
      max-width: 434px;
      margin: 0 auto;

      @include media-sp {
        max-width: inherit;
      }
    }
  }

  &__name {
    font-size: 2.2rem;
    line-height: 1.6;
    margin-bottom: 16px;

    @include media-sp {
      font-size: 1.8rem;
    }
  }
}

.news-guidelines {
  &__policy {
    height: 525px;
    border: 1px solid $gray_60;
    overflow-y: auto;

    @include media-sp {
      max-height: 50vh;
    }
  }

  &__inner {
    padding: 24px 30px;

    @include media-sp {
      padding: 24px 20px;
    }
  }

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    border: 1px solid $gray_60;
    border-top: 0;

    &.-active {
      background: #FFEFEE;
    }
  }
}