@use '../base/setting' as *;

.c-cta {
  @include media-pc-tb {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 340px;
    padding: 30px 20px;
    background: no-repeat center center / cover;
    position: relative;

    @include media-sp {
      height: 120px;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba($navy_80, 0.8);
      mix-blend-mode: multiply;
      @include transition;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &.-contact {
      background-image: url(/common/img/bg_cta01-pc.jpg);
    }

    &.-showroom {
      background-image: url(/common/img/bg_cta02-pc.jpg);
    }

    &.-resources {
      background-image: url(/common/img/bg_cta03-pc.jpg);
    }

    @include media-sp {
      &.-contact {
        background-image: url(/common/img/bg_cta01-sp.jpg);
      }

      &.-showroom {
        background-image: url(/common/img/bg_cta02-sp.jpg);
      }

      &.-resources {
        background-image: url(/common/img/bg_cta03-sp.jpg);
      }
    }

    @media (hover: hover) {
      &:hover {
        &::before {
          background: rgba($navy_80, 0.5);
        }

        .c-cta__btn {
          color: $white;
          background: $orange;
          border-color: $orange;

          &::after {
            color: $white;
          }
        }
      }
    }
  }

  &__icon {
    width: 128px;
    margin-bottom: 20px;
    position: relative;
    z-index: 3;

    img {
      width: 100%;
    }

    @include media-sp {
      display: none;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 242px;
    min-height: 56px;
    padding: 0 40px;
    border-radius: 100px;
    background: $white;
    border: 1px solid $navy_20;
    color: $base-font-color;
    font-weight: 700;
    line-height: 1.4;
    position: relative;
    @include transition;
    z-index: 3;

    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      @include centering(false, true);
      right: 16px;
      color: $orange;
    }
  }
}
