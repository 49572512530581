@use '../base/setting' as *;

.c-list {

  >li {
    line-height: 1.75;

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  &.-order {
    >li {
      display: flex;
      gap: 0.5em;

      >div {
        &:first-child {
          font-weight: 700;
        }

        &:last-child {
          flex: 1;

          >.c-list.-order {
            margin-top: 0.5em;

            >li {
              &:not(:last-child) {
                margin-bottom: 0.25em;
              }
            }
          }
        }
      }
    }
  }

  &.-dot {
    >li {
      padding-left: 1.25em;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 4px;
        height: 4px;
        background: $navy;
        border-radius: 100%;
        position: absolute;
        top: 0.8em;
        left: 4px;
      }
    }
  }

  &.-check {
    >li {
      padding-left: 1.5em;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 14px;
        height: 1.8em;
        background: url(/common/img/icon_check.svg) no-repeat center center / contain;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.c-list-note {
  font-size: 1.2rem;

  >li {
    display: flex;
    gap: 0.75em;

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }

    >div {
      &:last-child {
        flex: 1;
      }
    }
  }

  &.-order {
    >li {
      >div {
        &:first-child {
          width: 2em;
        }
      }
    }
  }

  @include media-sp {}
}

.c-list-material {
  li {
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 1.5;
    gap: 10px;

    &::before {
      content: "";
      display: block;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    img {
      width: 40px;
    }

    span {
      flex: 1;
    }
  }

  &__item01 {
    color: #005EAD;

    &::before {
      background-image: url(/company/common/img/icon_material01.svg);
    }
  }

  &__item02 {
    color: #009398;

    &::before {
      background-image: url(/company/common/img/icon_material02.svg);
    }
  }

  &__item03 {
    color: #009398;

    &::before {
      background-image: url(/company/common/img/icon_material03.svg);
    }
  }

  &__item04 {
    color: #6FA554;

    &::before {
      background-image: url(/company/common/img/icon_material04.svg);
    }
  }

  &__item05 {
    color: #005EAD;

    &::before {
      background-image: url(/company/common/img/icon_material05.svg);
    }
  }
}

.c-list-sdgs {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  @include media-sp {
    gap: 5px;
  }

  li {
    width: 74px;

    @include media-sp {
      width: 55px;
    }

    img {
      width: 100%;
    }
  }

  @include media-sp {
    &.-sp-small {
      li {
        width: 40px;
      }
    }
  }
}

.u-font-listmark {
  padding-left: 1.25em;
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    background: $navy;
    border-radius: 100%;
    position: absolute;
    top: 0.8em;
    left: 4px;
  }
}

.c-tel-list {
  margin: 24px 0;
  border-top: 1px solid $navy_20;
  line-height: 1;

  @include media-sp {
    margin: 16px 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $navy_20;
    padding: 24px 0;
    gap: 16px;

    @include media-sp {
      padding: 16px 0;
    }

    dd {
      flex: 1;
    }
  }

  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 84px;
    height: 40px;
    background: $navy;
    color: $white;
    font-size: 1.8rem;
    font-weight: 700;

    @include media-sp {
      width: 80px;
      height: 38px;
      font-size: 1.6rem;
    }
  }

  &__free-dial {
    display: block;
    width: 70px;

    img {
      width: 100%;
    }
  }

  &__num {
    font-size: 2.8rem;
    font-weight: 700;
    color: $navy;
  }
}
