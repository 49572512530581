@use '../base/setting' as *;

.column-top-heading {
  background: #DDEEFF;
  position: relative;
  padding-bottom: 6vw;

  @media (min-width: 2800px) {
    padding-bottom: 4vw;
  }

  @include media-sp {
    padding-bottom: 9.5vw;
  }

  &::before {
    content: "";
    display: block;
    width: 110%;
    min-height: 400px;
    height: 25vw;
    background: $white;
    border-bottom-left-radius: 55% 15vw;
    border-bottom-right-radius: 55% 15vw;
    margin-left: -5%;
    margin-right: -5%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__wrap {
    padding: 48px 115px 0;
    background: linear-gradient(180deg, $white 0%, $white 50%, transparent 50%, transparent 100%);

    @include media-pc-s {
      padding: 48px 72px 0;
    }

    @include media-tb {
      padding: 48px 48px 0;
    }

    @include media-sp {
      background: $white;
      padding: 30px 20px 0;
    }
  }

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
    position: relative;
    z-index: 10;
  }

  &__ttl {
    margin-bottom: 45px;

    @include media-pc-tb {
      display: flex;
    }

    @include media-sp {
      margin-bottom: 30px;
    }

    >div {
      &:first-child {
        position: relative;

        @include media-pc-tb {
          flex: 1;
          margin-right: 64px;
        }

        h1 {
          margin: 25px 0 40px 0;

          @include media-sp {
            margin: 0 0 16px 0;

            img {
              width: 80%;
              min-width: 270px;
            }
          }
        }

        >p {
          font-weight: 700;

          @include media-sp {
            font-size: 1.2rem;
            margin-bottom: 16px;
          }
        }
      }

      &:last-child {
        @include media-pc-tb {
          width: 50%;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

.column-category {
  display: flex;

  @include media-sp {
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px 0;
    margin: 0 -10px !important;
  }

  li {
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 72px;
      background: $navy_20;
      position: absolute;
      top: 0;
      right: 0;
    }

    @include media-pc-tb {
      flex: 1;

      &:last-child::after {
        display: none;
      }
    }

    @include media-sp {
      width: 25%;

      &:nth-child(4)::after {
        display: none;
      }

      &:nth-child(5)::before {
        content: "";
        display: block;
        width: 1px;
        height: 72px;
        background: $navy_20;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @include media-pc-tb {
      padding-bottom: 10px;
    }

    @include media-sp {
      height: 76px;
    }

    &::after {
      content: "";
      display: block;
      width: calc(100% - 8px);
      max-width: 81px;
      height: 2px;
      background: $orange;
      @include centering(true, false);
      bottom: 0;
      @include transition;
      opacity: 0;
    }

    &.-current {
      &::after {
        opacity: 1;
      }
    }

    @media (hover: hover) {
      &:hover::after {
        opacity: 1;
      }
    }

    >span {
      display: block;

      &:first-child {
        width: 40px;
        margin-bottom: 10px;

        @include media-sp {
          margin-bottom: 2px;
        }

        img {
          width: 100%;
        }
      }

      &:last-child {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.6;
        text-align: center;

        @include media-sp {
          font-size: 1.2rem;
          line-height: 1.3;
        }
      }
    }
  }
}

.column-top-main {
  background: $white;
  position: relative;
  padding-bottom: 7vw;

  @media (min-width: 2549px) {
    padding-bottom: 5vw;
  }

  @include media-tb {
    padding-bottom: 9.5vw;
  }

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: calc(100% - 22vw);
    background: #DDEEFF;
    position: absolute;
    top: 0;
    left: 0;
  }

  &::after {
    content: "";
    display: block;
    width: 110%;
    min-height: 400px;
    height: 25vw;
    background: #DDEEFF;
    border-bottom-left-radius: 55% 8vw;
    border-bottom-right-radius: 55% 8vw;
    margin-left: -5%;
    margin-right: -5%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__wrap {
    padding: 0 115px;
    background: url(/resources/column/assets/img/img_new02.png) no-repeat calc(50% + 700px) calc(100% - 80px) / 244px auto;
    position: relative;
    z-index: 2;

    @media (min-width: 2000px) {
      padding-top: 48px;
    }

    @include media-pc-s {
      padding: 0 72px;
    }

    @include media-tb {
      padding: 0 48px;
    }

    @media (max-width: 1920px) {
      background-position: calc(100% - 120px) calc(100% - 80px);
    }

    @include media-sp {
      padding: 30px 20px 100px;
      background-size: 100px auto;
      background-position: calc(100% - 20px) 100%;
    }
  }

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
    position: relative;
    z-index: 10;
  }

  &__heading {
    display: flex;
    align-items: center;
    gap: 28px;
    margin-bottom: 24px;

    img {
      width: 141px;
    }

    >span {
      font-size: 3.4rem;
      font-weight: 700;
      color: $navy;

      >span {
        color: $orange;
      }
    }

    @include media-sp {
      gap: 16px;

      img {
        width: 60px;
      }

      >span {
        font-size: 2.8rem;
      }
    }
  }
}

.c-slider__item:has(.column-card) {
  padding-top: 10px;
}

.column-card {
  display: block;
  background: $white;
  border-radius: 16px;
  position: relative;

  &.-first,
  &.-second,
  &.-third {
    &::after {
      content: "";
      display: block;
      width: 60px;
      height: 60px;
      background: no-repeat center center/ contain;
      position: absolute;
      top: -10px;
      left: -10px;
      z-index: 5;
    }
  }

  &.-first::after {
    background-image: url(/resources/column/assets/img/icon_first.svg);
  }

  &.-second::after {
    background-image: url(/resources/column/assets/img/icon_second.svg);
  }

  &.-third::after {
    background-image: url(/resources/column/assets/img/icon_third.svg);
  }

  @include media-sp {
    border-radius: 8px;
  }

  &:only-child {
    height: 100%;
  }

  &__inner {
    padding: 24px;

    @include media-sp {
      padding: 16px;
    }
  }

  &__img {
    border: 1px solid $gray_60;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    @include media-sp {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    img {
      width: 100%;
      @include transition;
    }
  }

  &__category {
    margin-bottom: 16px;

    @include media-sp {
      margin-bottom: 8px;
    }

    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 160px;
      padding: 5px 16px;
      background: $gray_80;
      border-radius: 4px;
      font-size: 1.2rem;
      line-height: 1.5;
      font-weight: 700;
      color: $navy;
    }
  }

  &__sub-category {
    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 2px 12px;
      border: 1px solid $navy_40;
      border-radius: 4px;
      font-size: 1.4rem;
      line-height: 1.5;
      font-weight: 700;
      color: $navy_60;
    }
  }

  &__date {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.5;
    color: $navy_80;
    margin-bottom: 12px;
    position: relative;
  }

  &__ttl {
    font-size: 1.6rem;
    font-weight: 700;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
      margin: 0 0.25em;
    }
  }

  &.-new {
    .column-card__date::after {
      content: "NEW";
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55px;
      height: 24px;
      background: $orange;
      border-radius: 4px;
      font-size: 1.2rem;
      color: $white;
      @include centering(false, true);
      left: 8em;
    }
  }

  &.-large {
    @include media-pc-tb {
      display: flex;

      .column-card__img {
        width: 60%;
        border-top-left-radius: 16px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 16px;
      }

      .column-card__inner {
        width: 40%;
        padding: 40px;
      }

      .column-card__category {
        margin-bottom: 20px;
      }

      .column-card__date {
        font-size: 1.8rem;
        margin-bottom: 40px;
      }

      &.-new .column-card__date::after {
        font-size: 1.4rem;
      }

      .column-card__ttl {
        font-size: 2.2rem;
      }


    }
  }

  @media (hover: hover) {
    &:hover {
      .column-card__img {
        img {
          transform: scale(1.1, 1.1);
        }
      }

      .column-card__ttl span {
        text-decoration: underline;
      }
    }
  }
}

.column-heading-icon {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.5;
  color: $navy;
  margin-bottom: 24px;

  img {
    width: 60px;
  }

  &.-icon-small {
    img {
      width: 40px;
    }
  }

  &.-txt-small {
    font-size: 2.2rem;
  }

  @include media-sp {
    gap: 8px;
    font-size: 2.4rem;

    img {
      width: 40px;
    }

    &.-icon-small {
      img {
        width: 32px;
      }
    }

    &.-txt-small {
      font-size: 2.1rem;
    }
  }
}

.column-top-tag {
  padding: 32px 24px;
  background: $white;
  border-radius: 16px;

  @include media-sp {
    padding: 20px 16px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;
    gap: 24px 16px;
    line-height: 1.5;
    height: 78px;
    overflow: hidden;

    @include media-sp {
      font-size: 1.2rem;
      gap: 12px 4px;
      height: 144px;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 8px;
      height: 27px;
      color: $navy_80;

      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.column-pickup-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;

  @include media-sp {
    grid-template-columns: 1fr;
  }

  &__item {
    display: grid;
    height: 100%;
    gap: 16px 30px;
    grid-template-columns: 230px 1fr;
    grid-template-rows: max-content max-content 1fr;
    padding-bottom: 32px;
    border-bottom: 1px solid $gray_80;

    @include media-sp {
      gap: 10px 15px;
      grid-template-columns: 160px 1fr;
      grid-template-rows: max-content 1fr max-content;
    }

    @media (hover: hover) {
      &:hover {
        .column-pickup-list__img img {
          transform: scale(1.1, 1.1);
        }

        .column-pickup-list__ttl span {
          text-decoration: underline;
        }
      }
    }
  }

  &__img {
    grid-area: 1 / 1 / 4 / 2;
    border: 1px solid $gray_60;
    overflow: hidden;
    border-radius: 16px;
    aspect-ratio: 16/9;

    @include media-sp {
      grid-area: 1 / 1 / 3 / 2;
      border-radius: 8px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include transition;
    }
  }

  &__category {
    grid-area: 1 / 2 / 2 / 3;

    >span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 28px;
      padding: 2px 12px;
      font-size: 1.2rem;
      font-weight: 700;
      line-height: 1.5;
      color: $navy;
      background: $gray_80;
      border-radius: 4px;
    }

    @include media-sp {
      grid-area: 1 / 2 / 2 / 3;
    }
  }

  &__date {
    grid-area: 2 / 2 / 3 / 3;
    font-size: 1.4rem;
    font-weight: 700;
    color: $navy_80;

    @include media-sp {
      grid-area: 2 / 2 / 3 / 3;
    }
  }

  &__ttl {
    grid-area: 3 / 2 / 4 / 3;
    font-size: 1.6rem;
    font-weight: 700;

    &::after {
      content: "\e90a";
      margin-left: 0.25em;
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
    }

    @include media-sp {
      grid-area: 3 / 1 / 4 / 3;
    }
  }
}

.column-article {
  padding: 64px 115px;
  background: #DDEEFF;
  position: relative;
  overflow: hidden;

  @include media-pc-s {
    padding: 64px 72px;
  }

  @include media-tb {
    padding: 64px 48px;
  }

  @include media-sp {
    padding: 30px 20px 64px;
  }

  &::before {
    content: "";
    display: block;
    width: 110%;
    min-height: 400px;
    height: 25vw;
    background: $white;
    border-bottom-left-radius: 55% 15vw;
    border-bottom-right-radius: 55% 15vw;
    margin-left: -5%;
    margin-right: -5%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__inner {
    max-width: $narrow-content-width;
    margin: 0 auto;
    position: relative;
    z-index: 10;
  }

  &__head {
    display: flex;
    align-items: center;
    gap: 8px;

    @include media-sp {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__date {
    font-size: 1.4rem;
    font-weight: 700;
    color: $navy_80;

    @include media-sp {
      font-size: 1.4rem;
    }
  }

  &__heading {
    font-size: 3.4rem;
    font-weight: 700;
    line-height: 1.5;
    margin: 32px 0;

    @include media-sp {
      font-size: 2.8rem;
      margin: 16px 0 24px;
    }
  }

  &__share {
    margin-bottom: 48px;

    @include media-sp {
      margin-bottom: 16px;
    }
  }
}

.column-article-contents {
  background: $white;
  border-radius: 16px;

  @include media-sp {
    border-radius: 8px;
  }

  &__kv {
    border-radius: 16px;
    overflow: hidden;

    @include media-sp {
      border-radius: 8px;
    }
  }

  &__inner {
    padding: 84px 97px 40px;

    @include media-sp {
      padding: 32px 16px 40px;
    }
  }
}

.column-article-profile {
  &__heading {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.5;
    color: $navy;
    margin-bottom: 24px;

    img {
      width: 60px;
    }

    @include media-sp {
      gap: 8px;
      font-size: 2.4rem;

      img {
        width: 40px;
      }
    }
  }

  &__item {
    display: grid;
    background: $white;
    border-radius: 16px;
    padding: 40px;
    grid-template-columns: auto 1fr;

    @include media-pc-tb {
      grid-template-rows: auto auto 1fr;
    }

    @include media-sp {
      border-radius: 8px;
      padding: 24px;
      align-items: center;
    }

    &+& {
      margin-top: 24px;

      @include media-sp {
        margin-top: 16px;
      }
    }
  }

  &__img {
    width: 200px;
    margin-right: 52px;

    img {
      width: 100%;
      border-radius: 100%;
    }

    @include media-pc-tb {
      grid-area: 1 / 1 / 4 / 2;
    }

    @include media-sp {
      width: 80px;
      margin-right: 16px;
      grid-area: 1 / 1 / 2 / 2;
    }
  }

  &__name {
    font-size: 2.4rem;
    font-weight: 700;

    span {
      font-size: 1.6rem;
    }

    @include media-pc-tb {
      grid-area: 1 / 2 / 2 / 3;
      margin-bottom: 16px;
    }

    @include media-sp {
      font-size: 1.8rem;
      line-height: 1.4;
      grid-area: 1 / 2 / 2 / 3;

      span {
        font-size: 1.2rem;
      }
    }
  }

  &__position {
    font-weight: 700;

    @include media-pc-tb {
      grid-area: 2 / 2 / 3 / 3;
      margin-bottom: 24px;
    }

    @include media-sp {
      grid-area: 2 / 1 / 3 / 3;
      margin: 16px 0;
    }
  }

  &__story {
    font-size: 1.2rem;

    @include media-pc-tb {
      grid-area: 3 / 2 / 4 / 3;
    }

    @include media-sp {
      grid-area: 3 / 1 / 4 / 3;
    }
  }
}

.column-article-bottom {
  border: 1px solid $navy_20;
  border-radius: 16px;

  @include media-sp {
    border-radius: 8px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 30px;

    @include media-sp {
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $navy_20;
    }

    >div {
      &:first-child {
        width: 150px;
        margin-right: 16px;
        font-size: 1.8rem;
        font-weight: 700;

        @include media-sp {
          width: 100%;
          margin: 0 0 8px 0;
        }
      }
    }
  }

  &__taglist {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 40px;
    font-size: 1.4rem;

    a {
      color: $navy_80;

      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    @include media-sp {
      gap: 16px 24px;
    }
  }
}

.column-archive {
  padding: 55px 115px 64px;
  background: #DDEEFF;
  position: relative;
  overflow: hidden;

  @include media-pc-s {
    padding: 55px 72px 64px;
  }

  @include media-tb {
    padding: 55px 48px 64px;
  }

  @include media-sp {
    padding: 30px 20px 64px;
  }

  &::before {
    content: "";
    display: block;
    width: 110%;
    min-height: 400px;
    height: 25vw;
    background: $white;
    border-bottom-left-radius: 55% 15vw;
    border-bottom-right-radius: 55% 15vw;
    margin-left: -5%;
    margin-right: -5%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
    position: relative;
    z-index: 10;
  }

  &__heading {
    position: relative;
    margin-bottom: 55px;

    h1 {
      font-size: 3.4rem;
      font-weight: 700;
      line-height: 1.5;
    }

    @include media-sp {
      margin-bottom: 32px;

      h1 {
        font-size: 2.8rem;
        padding-bottom: 30px;
      }

      &::after {
        content: "";
        display: block;
        width: 100vw;
        height: 1px;
        background: $gray_80;
        position: absolute;
        bottom: 0;
        left: -20px;
      }
    }
  }

  &__category {
    margin-bottom: 32px;
  }

  &__tag {
    border: 1px solid $gray_80;
    border-radius: 8px;
    padding: 32px 24px;
    background: $white;
    margin-bottom: 48px;

    @include media-sp {
      padding: 20px 16px;
      margin-bottom: 32px;
    }
  }

  &__tag-list {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;
    gap: 24px 16px;
    line-height: 1.5;
    height: 78px;
    overflow: hidden;

    @include media-sp {
      font-size: 1.2rem;
      gap: 12px 4px;
      height: 144px;
    }
  }

  &__radio {
    cursor: pointer;
    position: relative;

    input {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 8px;
      height: 27px;
      color: $navy_80;
    }

    input:checked+span {
      background: $gray_80;
      border-radius: 4px;
    }

    @media (hover: hover) {
      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }
  }

  &__ttl {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 24px;

    @include media-sp {
      font-size: 1.6rem;
    }

    span {
      font-size: 2.4rem;
      margin-right: 0.25em;

      @include media-sp {
        font-size: 2.1rem;
      }
    }
  }
}

.column-series {
  padding: 55px 115px 32px;
  background: #DDEEFF;
  position: relative;
  overflow: hidden;

  @include media-pc-s {
    padding: 55px 72px 32px;
  }

  @include media-tb {
    padding: 55px 48px 32px;
  }

  @include media-sp {
    padding: 30px 20px;
  }

  &::before {
    content: "";
    display: block;
    width: 110%;
    min-height: 400px;
    height: 25vw;
    background: $white;
    border-bottom-left-radius: 55% 15vw;
    border-bottom-right-radius: 55% 15vw;
    margin-left: -5%;
    margin-right: -5%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
    position: relative;
    z-index: 10;
  }

  &__heading {
    position: relative;
    margin-bottom: 60px;

    h1 {
      font-size: 3.4rem;
      font-weight: 700;
      line-height: 1.5;
    }

    @include media-sp {
      margin-bottom: 24px;

      h1 {
        font-size: 2.8rem;
      }
    }
  }
}

.column-series-intro {
  display: flex;
  gap: 16px 28px;

  @include media-sp {
    flex-direction: column;
  }

  &__img {
    @include media-pc-tb {
      width: 32%;
      min-width: 400px;
    }

    img {
      border-radius: 16px;
      border: 1px solid $gray_60;

      @include media-sp {
        border-radius: 8px;
      }
    }
  }

  &__txt {
    flex: 1;
    font-size: 1.8rem;

    @include media-sp {
      font-size: 1.6rem;
    }

    >div {
      padding: 24px;

      @include media-sp {
        background: $white;
        padding: 8px 16px 24px;
        border-radius: 8px;
      }
    }
  }
}

.column-series-list {
  &__item {
    &+& {
      margin-top: 24px;
    }
  }
}

.column-series-card {
  display: flex;
  background: $white;
  border-radius: 16px;
  overflow: hidden;

  @include media-sp {
    border-radius: 8px;
    flex-direction: column;
  }

  &__img {
    overflow: hidden;

    @include media-pc-tb {
      width: 32%;
      min-width: 400px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid $gray_60;
      border-top-left-radius: 16px;
      @include transition;

      @include media-pc-tb {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }

      @include media-sp {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
  }

  &__inner {
    flex: 1;
    padding: 50px 28px;

    @include media-sp {
      padding: 16px 16px 24px;
    }
  }

  &__category {
    margin-bottom: 16px;

    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 160px;
      padding: 5px 16px;
      background: $gray_80;
      border-radius: 4px;
      font-size: 1.2rem;
      line-height: 1.5;
      font-weight: 700;
      color: $navy;
    }
  }

  &__sub-category {
    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 2px 12px;
      border: 1px solid $navy_40;
      border-radius: 4px;
      font-size: 1.4rem;
      line-height: 1.5;
      font-weight: 700;
      color: $navy_60;
    }
  }

  &__date {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;

    span:first-child {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1.5;
      color: $navy_80;
      position: relative;
    }
  }

  &__ttl {
    font-size: 1.6rem;
    font-weight: 700;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
      margin: 0 0.25em;
    }
  }

  @media (hover: hover) {
    &:hover {
      .column-series-card__img img {
        transform: scale(1.1, 1.1);
      }

      .column-series-card__ttl span {
        text-decoration: underline;
      }
    }
  }
}

.column-series-bottom {
  padding: 13vw 115px 64px;
  position: relative;

  @include media-pc-s {
    padding: 13vw 72px 64px;
  }

  @include media-tb {
    padding: 13vw 48px 64px;
  }

  @include media-sp {
    padding: 13vw 20px 32px;
  }

  &::before {
    content: "";
    display: block;
    width: 110%;
    height: 25vw;
    background: #DDEEFF;
    border-bottom-left-radius: 55% 15vw;
    border-bottom-right-radius: 55% 15vw;
    margin-left: -5%;
    margin-right: -5%;
    position: absolute;
    top: -16vw;
    left: 0;
  }

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
    position: relative;
    z-index: 10;
  }
}

.column-header {
  margin: 56px auto 0;
  text-align: center;
  padding: 0 115px;

  @include media-pc-s {
    padding: 0 72px;
  }

  @include media-tb {
    padding: 0 48px;
  }

  @include media-sp {
    margin: 48px auto 16px;
    padding: 0 20px;
  }

  @include media-sp-s {
    margin: 40px auto 0;
  }

  &__link {
    img {
      width: 100%;
      max-width: 618px;

      @include media-sp-s {
        max-width: 450px;
      }
    }

    @media (hover: hover) {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}