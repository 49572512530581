@font-face {
  font-family: 'icomoon';
  src: url('/common/fonts/icomoon.eot?m55ct4');
  src: url('/common/fonts/icomoon.eot?m55ct4#iefix') format('embedded-opentype'),
    url('/common/fonts/icomoon.ttf?m55ct4') format('truetype'),
    url('/common/fonts/icomoon.woff?m55ct4') format('woff'),
    url('/common/fonts/icomoon.svg?m55ct4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-blank:before {
  content: "\e900";
}

.icon-contact:before {
  content: "\e901";
}

.icon-down:before {
  content: "\e902";
}

.icon-global:before {
  content: "\e903";
}

.icon-pdf:before {
  content: "\e904";
}

.icon-search:before {
  content: "\e905";
}

.icon-arrow-left:before {
  content: "\e907";
}

.icon-arrow-right:before {
  content: "\e906";
}

.icon-menu:before {
  content: "\e908";
}

.icon-close:before {
  content: "\e909";
}

.icon-next:before {
  content: "\e90a";
}

.icon-consumer:before {
  content: "\e90b";
}

.icon-corp:before {
  content: "\e90c";
}

.icon-up:before {
  content: "\e90d";
}

.icon-itoki:before {
  content: "\e90e";
}

.icon-shop:before {
  content: "\e90f";
}

.icon-agency:before {
  content: "\e910";
}

.icon-open-tab:before {
  content: "\e911";
}

.icon-prev:before {
  content: "\e912";
}

.icon-share:before {
  content: "\e913";
}

.icon-task:before {
  content: "\e914";
}

.icon-expansion:before {
  content: "\e915";
}

.icon-alert:before {
  content: "\e916";
}

.icon-remove:before {
  content: "\e917";
}

.icon-add:before {
  content: "\e918";
}

.icon-download:before {
  content: "\e919";
}
