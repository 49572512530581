@use '../base/setting' as *;

.l-noscript {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background: $red;
  color: $white;
  z-index: 99999;
}

.l-header {

  &__inner {
    border-bottom: 1px solid $navy_20;
    transition: top 0.3s;

    &:only-child {
      height: 128px;

      @include media-sp {
        height: 68px;
      }

      .l-header__logo {
        width: 161px;

        @include media-sp {
          width: 103px;
        }
      }
    }
  }

  @include media-pc-tb {

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 64px;
      margin: 0 auto;
      background: $white;
      width: 100%;
      position: relative;
      z-index: 9999;
    }

    &__logo {
      width: 132px;
      @include centering(false, true);
      left: 0;
      margin-left: 32px;
      transition: opacity 0.3s;

      @media (max-width: 1365px) {
        width: 115px;
      }

      span {
        display: block;
        margin-top: 16px;
        font-size: 1.4rem;
        letter-spacing: 0.2em;
        line-height: 1;
        white-space: nowrap;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &__sp {
      display: none;
    }

    &__nav {
      width: 100%;
      height: 100%;
      display: flex !important;
      flex-direction: column;
    }

    &__subnav {
      order: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 44px;
      gap: 16px;
    }

    &__mainnav {
      order: 2;
      display: flex;
      justify-content: center;
    }

    &__search {
      display: none;
      position: absolute;
      top: 100px;
      left: 0;
      width: 100%;
      background: rgba($gray_80, 0.9);
      padding: 110px 20px 100px;

      &.-orange {
        background: rgba(#EFDCDB, 0.9);
      }

      &.-beige {
        background: rgba($beige, 0.9);
      }
    }

    &.-narrow {
      .l-header__nav {
        padding-left: 200px;

      }

      .l-header__mainnav {
        justify-content: flex-start;
      }
    }
  }

  @include media-sp {
    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 68px;
      margin: 0 auto;
      padding: 0 8px 0 16px;
      background: $white;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
    }

    &__logo {
      width: 103px;
      transition: opacity 0.3s;

      span {
        display: block;
        margin-top: 8px;
        font-size: 1rem;
        letter-spacing: 0.2em;
        line-height: 1;
      }
    }

    &__sp {
      display: flex;
      width: 100px;
      height: 50px;
      border-radius: 2px;
      overflow: hidden;
    }

    &__sp-search,
    &__sp-menu {
      display: block;
      width: 50px;
      height: 50px;
      position: relative;

      &::before {
        content: "\e905";
        font-size: 2.6rem;
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        @include centering(true, true);
        color: $navy;
      }

      &.-active {
        &::before {
          content: "\e909";
        }
      }
    }

    &__sp-search {
      &::before {
        content: "\e905";
      }

      &::after {
        content: "";
        width: 1px;
        height: 40px;
        background: $navy_20;
        @include centering(false, true);
        right: 0;
      }
    }

    &__sp-menu {
      &::before {
        content: "\e908";
      }
    }

    &__nav {
      display: none;
      position: fixed;
      top: 68px;
      left: 0;
      width: 100%;
      max-height: calc(100dvh - 68px);
      background: $white;
      overflow-y: auto;
    }

    &__search {
      display: none;
      position: fixed;
      top: 68px;
      left: 0;
      width: 100%;
      padding: 24px 20px;
      border-top: 1px solid $navy_20;
      background: rgba($gray_80, 0.9);

      &.-orange {
        background: rgba(#EFDCDB, 0.9);
      }

      &.-beige {
        background: rgba($beige, 0.9);
      }
    }
  }

  &__overlay {
    display: none;
    width: 100%;
    height: 100vh;
    background: rgba($black, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9998;
  }
}

.c-header-subnav {

  @include media-pc-tb {
    display: flex;
    align-items: center;
    height: 100%;

    &.-type01 {
      gap: 24px;

      @include media-tb {
        gap: 16px;
      }

    }

    &.-type02 {
      gap: 1px;
    }

    &__link {
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 1.2rem;
      position: relative;

      &.-shop,
      &.-agency,
      &.-contact,
      &.-search {
        gap: 4px;

        &::before {
          font-size: 2.2rem;
          font-family: 'icomoon';
          font-weight: normal;
          line-height: 1;
          color: $navy;
          transition: color 0.3s;
        }
      }

      &.-shop {
        &::before {
          content: "\e90f";
        }
      }

      &.-agency {
        &::before {
          content: "\e910";
        }
      }

      &.-contact {

        &::before {
          content: "\e901";
        }

        &::after {
          content: "";
          display: none;
          width: 1px;
          height: 30px;
          background: $navy_20;
          @include centering(false, true);
          left: -24px;

          @include media-tb {
            left: -16px;
          }
        }

        &:not(:first-child) {
          margin-left: 24px;

          @include media-tb {
            margin-left: 16px;
          }

          &::after {
            display: block;
          }
        }
      }

      &.-search {
        width: 58px;

        &::before {
          content: "\e905";
        }

        &.-active {
          color: $orange;

          &::before {
            font-size: 1.6rem;
            content: "\e909";
            color: $orange;
          }

          &::after {
            content: "";
            width: 100%;
            height: 4px;
            background: $orange;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }

      &:hover {
        span:last-child {
          text-decoration: underline;
        }

        &::before {
          color: $orange;
        }
      }

      &.-open-tab {
        &::after {
          content: "\e911";
          font-size: 1.6rem;
          font-family: 'icomoon';
          font-weight: normal;
          line-height: 1;
          color: $navy;
        }
      }
    }

    a.c-header-subnav__link[target="_blank"]::after {
      content: "\e900";
      font-size: 1.6rem;
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $navy;
    }

    &__btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1.1rem;
      line-height: 1.5;
      font-weight: 700;
      text-align: center;
      width: 90px;
      height: 100%;
      background: $gray_60;
      color: $navy;
      position: relative;

      &::after {
        content: "\e900";
        font-size: 1.6rem;
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        position: absolute;
        top: 6px;
        right: 6px;
        color: $navy;
      }

      &.-global {
        &::before {
          content: "\e903";
          font-size: 1.6rem;
          font-family: 'icomoon';
          font-weight: normal;
          line-height: 1;
        }
      }

      &:hover {
        background: rgba($gray_60, 0.5);
      }

      &.-orange {
        background: $orange_20;

        &:hover {
          background: rgba($orange_20, 0.5);
        }
      }

      &.-beige {
        background: $beige_60;

        &:hover {
          background: rgba($beige_60, 0.5);
        }
      }
    }
  }

  @include media-sp {
    display: flex;

    &.-type01 {
      flex-wrap: wrap;
    }

    &.-type02 {
      flex-direction: column;
      align-items: center;
      gap: 16px;
      padding: 32px 0;
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 50%;
      height: 68px;
      border-bottom: 1px solid $navy_20;

      &::before {
        font-size: 2.4rem;
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        color: $navy;
      }

      &:nth-child(2n) {
        border-left: 1px solid $navy_20;
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        &::after {
          content: "\e90a";
          font-family: 'icomoon';
          font-weight: normal;
          line-height: 1;
          color: $navy;
        }
      }

      &.-shop {
        &::before {
          content: "\e90f";
        }
      }

      &.-agency {
        &::before {
          content: "\e910";
        }
      }

      &.-contact {

        &::before {
          content: "\e901";
        }
      }

      &.-sp-col12 {
        width: 100%;
      }

      &.-search {
        display: none;
      }

      &.-open-tab {
        span::after {
          content: "\e911";
        }
      }
    }

    a.c-header-subnav__link[target="_blank"] span::after {
      content: "\e900";
    }

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      &::before {
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        color: $navy;
      }

      &::after {
        content: "\e900";
        font-size: 1.6rem;
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        color: $navy;
      }

      &.-corporate,
      &.-company,
      &.-consumer {
        width: 305px;
        height: 56px;
        border-radius: 50px;
        background: $white;
        border: 1px solid $navy_20;
        color: $base-font-color;
        font-weight: 700;
        position: relative;

        &::before {
          font-size: 2.4rem;
          @include centering(false, true);
          left: 24px;
        }

        &::after {
          @include centering(false, true);
          right: 24px;
        }

        br {
          display: none;
        }
      }

      &.-corporate::before {
        content: "\e90c";
      }

      &.-company::before {
        content: "\e90e";
        color: $orange;
      }

      &.-consumer::before {
        content: "\e90b";
      }

      &.-global {
        gap: 4px;

        &::before {
          content: "\e903";
          font-size: 1.6rem;
        }
      }
    }
  }
}

.c-header-nav {
  @include media-pc-tb {
    display: flex;
    gap: 4.687vw;
    width: fit-content;
    height: 55px;
    border-top: 1px solid $navy_20;
    padding: 0 0.5vw;

    @media (min-width: 1366px) and (max-width: 1680px) {
      gap: 3.513vw;
    }

    @media (min-width: 1280px) and (max-width: 1365px) {
      gap: 3.75vw;
    }

    @media (max-width: 1279px) {
      gap: 3.906vw;
    }

    @include media-tb {
      padding: 0;
    }

    &::after {
      content: "";
      width: 50vw;
      height: 1px;
      background: $navy_20;
      position: absolute;
      top: 44px;
      left: 50%;
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      height: 100%;
      font-size: 1.4rem;
      font-weight: 700;
      position: relative;
      @include transition;

      @include media-tb {
        font-size: 1.3rem;
      }

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 0;
        background: $orange;
        position: absolute;
        bottom: 0;
        left: 0;
        @include transition;
      }

      &.-current {
        &::before {
          height: 2px;
        }
      }

      &.-active,
      &:hover {
        color: $orange;

        &::before {
          height: 4px;
        }
      }

      &.-down {
        &::after {
          content: "\e902";
          font-family: 'icomoon';
          font-weight: normal;
          line-height: 1;
          color: $navy;
        }

        &.-active {
          &::after {
            content: "\e90d";
            color: $orange;
          }
        }
      }
    }

    &__drawer {
      display: none;
      width: 100%;
      background: $white;
      position: absolute;
      top: 100px;
      left: 0;
      padding: 24px 48px 32px;
    }

    &__drawer-close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 30px;
      height: 30px;
      border: 1px solid $gray_40;
      border-radius: 100%;
      @include transition;

      &::before {
        content: "\e909";
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        color: $navy;
        @include centering(true, true);
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &__drawer-pc {
      margin: 0 auto;
      max-width: $narrow-content-width;
    }

    &__drawer-sp {
      display: none !important;
    }
  }

  @include media-sp {

    &__item {
      border-bottom: 1px solid $navy_20;
    }

    &__link {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      padding: 8px 20px;
      font-size: 1.6rem;
      font-weight: 700;
      position: relative;

      &::after {
        content: "\e902";
        font-size: 1.6rem;
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        @include centering(false, true);
        right: 20px;
        color: $navy;
      }

      &.-active {
        &::after {
          content: "\e90d";
        }
      }
    }

    a.c-header-nav__link {
      &::after {
        content: "\e90a";
      }
    }

    &__drawer {
      display: none;
    }

    &__drawer-pc {
      display: none !important;
    }

    &__drawer-sp {
      padding: 0 20px;

      &.-orange {
        background: #FDF2F2;
      }

      &.-blue {
        background: $gray_20;
      }
    }

    &__drawer-close {
      display: none !important;
    }
  }
}

.c-header-drawer-top {

  @include media-pc-tb {
    margin-bottom: 12px;

    &__link {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      font-size: 2rem;
      font-weight: 700;
      position: relative;

      &::after {
        content: "\e90a";
        font-family: 'icomoon';
        font-size: 1.6rem;
        font-weight: normal;
        color: $navy;
        display: block;
      }

      &:hover span {
        text-decoration: underline;
      }
    }
  }
}

.l-header-drawer-grid {

  @include media-pc-tb {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -14px 0 !important;

    &+& {
      padding-top: 60px;
      position: relative;

      &::before {
        content: "";
        width: calc(100% - 28px);
        height: 1px;
        background: $navy_20;
        position: absolute;
        top: 30px;
        left: 20px;
      }
    }

    &__item {
      margin: 0 14px 0;

      @for $grid-col from 1 through 12 {
        &.-col-#{$grid-col} {
          width: calc(calc(100% * $grid-col / 12) - 28px);
        }
      }
    }
  }
}

.c-header-drawer-card {
  &:not(:only-child) {
    margin-bottom: 8px;
  }

  @include media-pc-tb {

    &__img {
      width: 100%;
      overflow: hidden;
      margin-bottom: 16px;

      img {
        width: 100%;
        @include transition;
      }
    }

    &__ttl {
      font-weight: 700;
      display: inline-flex;
      align-items: center;
      gap: 4px;
      position: relative;

    }
  }
}

a.c-header-drawer-card {
  @include media-pc-tb {
    .c-header-drawer-card__ttl {
      &::after {
        content: "\e90a";
        font-family: 'icomoon';
        font-size: 1.6rem;
        font-weight: normal;
        color: $navy;
        display: block;
      }
    }

    &:hover {
      .c-header-drawer-card__img {
        img {
          transform: scale(1.1, 1.1);
        }
      }

      .c-header-drawer-card__ttl span {
        text-decoration: underline;
      }
    }
  }
}

a[href*=".pdf"].c-header-drawer-card {

  .c-header-drawer-card__icon {

    &::before,
    &::after {
      content: "\e904";
      color: $orange;
    }
  }
}

.c-header-drawer-title {
  @include media-pc-tb {
    display: flex;
    align-items: center;
    min-height: 64px;
    width: 100%;
    border-bottom: 1px solid $navy_20;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.6;
    padding: 8px 20px 8px 8px;
    position: relative;
    margin-bottom: 12px;
  }
}

a.c-header-drawer-title {
  @include media-pc-tb {

    &::after {
      content: "\e90a";
      display: block;
      font-family: 'icomoon';
      font-weight: normal;
      color: $navy;
      @include centering(false, true);
      right: 0;
    }

    &:hover {
      background: #F2F5F7;
    }

    &[target="_blank"] {
      &::after {
        content: "\e900";
      }
    }
  }
}

.c-header-drawer-list {
  @include media-pc-tb {
    li {
      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }

  @include media-sp {
    >li {
      &:not(:last-child) {
        border-bottom: 1px solid $navy_20;
      }
    }
  }

  &__link {

    @include media-pc-tb {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      font-size: 1.4rem;
      line-height: 1.2;
      position: relative;

      &[target="_blank"]::after {
        color: $navy;
      }

      &:hover span {
        text-decoration: underline;
      }
    }

    @include media-sp {
      display: flex;
      align-items: center;
      width: 100%;
      height: 56px;
      font-size: 1.4rem;
      font-weight: 700;
      padding: 8px;
      position: relative;

      &::after {
        content: "\e90a";
        font-family: 'icomoon';
        font-weight: normal;
        color: $navy_40;
        @include centering(false, true);
        right: 8px;
      }

      &.-down {
        &::after {
          content: "\e902";
        }

        &.-active::after {
          content: "\e90d";
        }
      }
    }

    &[target="_blank"]::after {
      content: "\e900";
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
    }

    &[href*=".pdf"]::after {
      @include media-sp {
        content: "\e904";
        color: $orange;
      }
    }
  }

  &__lower {
    @include media-sp {
      display: none;
      margin: 0 -20px;
      padding: 20px;

      .-orange & {
        background: #EFDCDB;
      }

      .-blue & {
        background: $gray_60;
      }

      li a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 45px;
        font-size: 1.4rem;
        padding: 8px;
        position: relative;

        &::after {
          content: "\e90a";
          font-family: 'icomoon';
          font-weight: normal;
          color: $navy_40;
          @include centering(false, true);
          right: 8px;
        }

        &[target="_blank"]::after {
          content: "\e900";
          line-height: 1;
        }
      }
    }
  }
}

.l-header__search .mf_finder_container {
  position: relative;
  max-width: 800px;
  margin: 0 auto;

  .mf_finder_searchBox_items_wrapper {
    .mf_finder_searchBox_query_input {
      width: 100%;
      height: 52px;
      border-radius: 8px;
      background: $white;
      padding: 8px 20px;
      font-weight: 500;

      &::placeholder {
        color: $navy_40;
      }

      @include media-sp {
        height: 46px;
        padding: 8px 16px;
        border: 1px solid $navy_20;
      }
    }

    .mf_finder_searchBox_submit {
      width: 72px;
      height: 52px;
      font-weight: 700;
      color: $navy;
      background: transparent;
      @include centering(false, true);
      right: 0;

      @include media-sp {
        width: 46px;
        height: 46px;
      }

      &::before {
        content: "\e905";
        font-size: 2.4rem;
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        @include centering(true, true);
      }
    }

    .mf_finder_searchBox_items_wrapper {
      margin-bottom: 0;
    }

    .mf_finder_searchBox_items {
      border: 0;
    }
  }
}

.c-header-modal {
  max-width: 1260px;
  margin: 0 auto;
}
