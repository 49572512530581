@use '../base/setting' as *;

.c-input {
  width: 100%;
  max-width: 870px;
  height: 44px;
  border: 1px solid $navy_20;
  padding: 4px 10px;
  border-radius: 3px;
  font-size: 1.6rem;

  @include media-sp {
    height: 40px;
  }

  &.-zip {
    width: 185px;

    @include media-sp {
      width: 100%;
      max-width: 185px;
    }
  }

  &.-xsmall {
    width: 185px;
  }

  &.-small {
    max-width: 320px;
  }

  &::placeholder {
    color: rgba($base-font-color , 0.5);
  }

  &.error {
    border-color: $border-danger;
    background: $bg-danger;
  }
}

.c-textarea {
  width: 100%;
  max-width: 870px;
  height: 146px;
  border: 1px solid $navy_20;
  border-radius: 3px;
  font-size: 1.6rem;

  &::placeholder {
    color: rgba($base-font-color , 0.5);
  }

  &.error {
    border-color: $border-danger;
    background: $bg-danger;
  }
}

.c-selectbox {
  max-width: 870px;
  height: 44px;
  position: relative;
  font-size: 1.6rem;

  @include media-sp {
    height: 40px;
  }

  &.-xxsmall {
    width: 96px;
  }

  &.-xsmall {
    width: 185px;
  }

  &.-small {
    max-width: 320px;
  }

  &::after {
    content: "\e902";
    font-family: 'icomoon';
    font-weight: normal;
    line-height: 1;
    color: $orange;
    @include centering(false, true);
    right: 16px;
    pointer-events: none;
  }

  select {
    width: 100%;
    height: 100%;
    border: 1px solid $navy_20;
    background: $white;
    border-radius: 3px;
    padding: 4px 48px 4px 10px;

    &.error {
      border-color: $border-danger;
      background: $bg-danger;
    }
  }
}

.c-checkbox-wrap {
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 30px;

    &.-column {
      flex-direction: column;
    }

    @include media-sp {
      &.-sp-column {
        flex-direction: column;
      }
    }
  }

  &__wide {
    width: 100%;
  }

  &__extra {
    @include media-pc-tb {
      padding-right: 30px;
      border-right: 1px solid $navy_20;
    }

    @include media-sp {
      width: 100%;
    }
  }
}

.c-checkbox {
  padding-left: 28px;
  cursor: pointer;
  position: relative;
  display: inline-block;

  input {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    &::before {
      content: "";
      width: 16px;
      height: 16px;
      border: 1px solid $navy_20;
      border-radius: 2px;
      background: $white;
      cursor: pointer;
      position: absolute;
      top: 0.4em;
      left: 0;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0.7em;
      left: 4px;
      width: 8px;
      height: 5px;
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      transform: rotate(-45deg);
      opacity: 0;
    }
  }

  input:checked+span {
    &::before {
      background: $orange;
    }

    &::after {
      opacity: 1;
    }
  }

  input.error+span::before {
    border-color: $border-danger;
    background: $bg-danger;
  }

  &:has(input:disabled) {
    color: $navy_20;
    pointer-events: none;
  }
}

.c-radio {
  padding-left: 36px;
  cursor: pointer;
  position: relative;
  display: inline-block;

  input {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    &::before {
      content: "";
      width: 24px;
      height: 24px;
      border: 2px solid $navy_20;
      border-radius: 50%;
      background: $gray_20;
      cursor: pointer;
      position: absolute;
      top: 2px;
      left: 0;

      @include media-sp {
        top: 0;
      }
    }
  }

  input:checked+span::before {
    border: 6px solid $orange;
    background: $white;
  }

  input.error+span::before {
    border-color: $border-danger;
    background: $bg-danger;
  }
}

.c-search-item {
  display: flex;
  gap: 10px 30px;

  @include media-pc-tb {
    align-items: center;
  }

  @include media-sp {
    gap: 10px;
    flex-direction: column;

    &.-sp-row {
      flex-direction: row;
      align-items: center;

      .c-search-item__label {
        width: inherit;
      }
    }
  }

  &+& {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid $navy_20;

    @include media-sp {
      padding-top: 24px;
      margin-top: 24px;
    }
  }

  &__label {
    width: 5em;
    font-size: 1.6rem;
    font-weight: 700;

    @include media-sp {
      font-size: 1.6rem;
      width: 100%;
    }
  }

  &__body {
    flex: 1;

    @include media-sp {
      width: 100%;
    }
  }
}

.c-form {
  &__lead {
    margin-bottom: 48px;

    @include media-sp {
      margin-bottom: 40px;
    }
  }

  &__policy-ttl,
  &__personal-ttl,
  &__inquiry-ttl {
    margin-bottom: 24px;

    @include media-sp {
      margin-bottom: 16px;
    }

    .pbCornerQueryLabelOnly {
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.5;
      position: relative;
      padding-left: 0.75em;

      @include media-sp {
        font-size: 1.8rem;
      }

      &::before {
        content: "";
        width: 2px;
        height: 1.2em;
        background: $orange;
        position: absolute;
        top: 0.2em;
        left: 0;
      }
    }
  }

  &__personal-ttl,
  &__inquiry-ttl {
    margin-top: 48px;

    @include media-sp {
      margin-top: 40px;
    }
  }

  &__policy-txt {
    border: 1px solid $navy_20;
    height: 200px;
    overflow-y: scroll;
    margin-bottom: 32px;

    &.pb-of-visible {
      overflow-y: scroll !important;
    }

    @include media-sp {
      margin-bottom: 24px;
    }

    .pbCornerQueryLabelOnly {
      font-size: 1.4rem;
      padding: 24px 30px;

      @include media-sp {
        font-size: 1.2rem;
        padding: 16px;
      }
    }
  }

  td.pbLabel:not(.queryOneLineLabel) {
    background: $gray_20;
    font-weight: 700;
    position: relative;

    @include media-pc-tb {
      width: 28% !important;
      padding: 24px 80px 24px 24px;
      border: 1px solid $navy_20;
    }

    @include media-sp {
      display: block;
      padding: 16px 56px 16px 16px;
      width: 100% !important;
    }

    .pbCornerQueryLabelRequired {
      display: block;
      padding: 6px 8px;
      background: #E63D30;
      color: #fff;
      font-size: 1.2rem;
      line-height: 1;
      position: absolute;
      top: 26px;
      right: 30px;

      @include media-sp {
        font-size: 1.1rem;
        top: 16px;
        right: 16px;
      }
    }

    &+td {

      @include media-pc-tb {
        width: 78% !important;
        padding: 24px;
        border: 1px solid $navy_20;
      }

      @include media-sp {
        display: block;
        width: 100% !important;
        padding: 20px 0;
        border-top: 1px solid $navy_20;
        border-bottom: 1px solid $navy_20;
      }

      >div:not(:only-child):not(:last-child) {
        margin-bottom: 16px;
      }

      div {
        position: relative;
      }
    }
  }

  .pbInputForm {
    width: 100%;
    height: 44px;
    border: 1px solid $navy_20;
    padding: 4px 10px;
    border-radius: 3px;
    font-size: 1.6rem;

    &::placeholder {
      color: rgba($base-font-color , 0.5);
    }

    @include media-sp {
      height: 40px;
    }

    &.error {
      border-color: $border-danger;
      background: $bg-danger;
    }
  }

  textarea.pbInputForm {
    height: 200px;
  }

  input[type="checkbox"] {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;

    &+label {
      display: inline-block;
      padding-left: 1.5em;
      position: relative;

      &::before {
        content: "";
        width: 16px;
        height: 16px;
        border: 1px solid $navy_20;
        border-radius: 2px;
        background: $white;
        cursor: pointer;
        position: absolute;
        top: 0.4em;
        left: 0;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0.7em;
        left: 4px;
        width: 8px;
        height: 5px;
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        transform: rotate(-45deg);
        opacity: 0;
      }
    }

    &:checked+label {
      &::before {
        background: $orange;
      }

      &::after {
        opacity: 1;
      }
    }

    &.error+label::before {
      border-color: $border-danger;
      background: $bg-danger;
    }
  }

  input[type="radio"] {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;

    &+label {
      display: inline-block;
      position: relative;
      padding-left: 2em;

      &::before {
        content: "";
        width: 24px;
        height: 24px;
        border: 2px solid $navy_20;
        border-radius: 50%;
        background: $gray_20;
        cursor: pointer;
        position: absolute;
        top: 2px;
        left: 0;

        @include media-sp {
          top: 0;
        }
      }
    }

    &:checked+label::before {
      border: 6px solid $orange;
      background: $white;
    }

    &.error+label::before {
      border-color: $border-danger;
      background: $bg-danger;
    }
  }

  &__agree {
    @include media-sp {
      padding: 0 16px;
    }
  }

  &__input-name,
  &__input-name2 {
    display: flex;

    @include media-pc-tb {
      align-items: center;

      .pbInputForm {
        flex: 1;
      }
    }

    @include media-sp {
      flex-direction: column;
    }

    .pbOptionForth {
      display: block;
      font-weight: 700;
      margin-right: 1em;

      @include media-pc-tb {
        width: 2em;
      }

      @include media-sp {
        margin-bottom: 10px;
      }
    }

    >* {
      &:nth-child(4) {
        order: 5;
      }

      &:nth-child(5) {
        order: 4;

        @include media-pc-tb {
          margin-left: 40px;
        }

        @include media-sp {
          margin-top: 12px;
        }
      }
    }
  }

  &__input-zip {
    display: flex;
    align-items: center;
    gap: 15px;

    .pbInputForm {
      max-width: 157px;
    }

    .pbOptionForth:first-child {
      display: block;
      width: 1em;
      font-weight: 700;
    }
  }

  &__input-pref {
    max-width: 285px;
    height: 44px;
    position: relative;
    font-size: 1.6rem;

    @include media-sp {
      height: 40px;
    }

    &::after {
      content: "\e902";
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
      @include centering(false, true);
      right: 16px;
      pointer-events: none;
    }

    select {
      width: 100%;
      height: 100%;
      border: 1px solid $navy_20;
      background: $white;
      border-radius: 3px;
      padding: 4px 48px 4px 10px;

      &.error {
        border-color: $border-danger;
        background: $bg-danger;
      }
    }
  }

  &__input-tel,
  &__input-cellular,
  &__input-fax {
    input {
      max-width: 258px !important;
    }
  }

  &__radio-inquiry,
  &__radio-product-category {
    @include media-sp {
      padding: 0 16px;
    }

    table {
      width: 100%;

      td {
        display: flex;
        flex-wrap: wrap;
        gap: 16px 28px;

        @include media-sp {
          flex-direction: column;
        }
      }
    }
  }

  &__input-application_list {
    table {
      width: 100%;
    }

    td {
      &:has(input[type="checkbox"].error) {
        input[type="checkbox"]+label::before {
          border-color: $border-danger;
          background: $bg-danger;
        }
      }

      >div:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  &__recaptcha {

    .g-recaptcha {
      margin-top: 32px;

      >div {
        margin: 0 auto;
      }
    }
  }

  .forTdCellCenter {
    >.c-btn-wrap {
      margin-top: 32px;
    }
  }

  label.error {
    display: block;
    width: fit-content;
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 10px;
    color: $red;
    padding-left: 1.5em;
    position: relative;

    &::before {
      content: "\e916";
      font-size: 2rem;
      line-height: 1;
      font-family: 'icomoon';
      font-weight: normal;
      color: #E75B6E;
      position: absolute;
      top: 3px;
      left: 0;
    }
  }
}
