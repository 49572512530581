@use '../base/setting' as *;

.c-modal {
  display: none;
  width: 100%;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  &__bg {
    background: rgba($black, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95vw;
    height: 100dvh;
    max-width: $base-content-width;
    margin: 0 auto;
    overflow: hidden;

    &.-narrow {
      max-width: $narrow-content-width;
    }
  }

  &__content {
    width: 100%;
    max-height: 90dvh;
    padding: 80px 40px;
    overflow-y: auto;
    position: relative;
    background: $white;
    border-radius: 8px;

    @include media-sp {
      padding: 60px 20px 40px;
    }
  }

  &__iframe {
    @include centering(true, true);
    width: calc(100% - 40px);
    max-width: 750px;
  }

  &__iframe-inner {
    width: 100%;
    padding-top: 56.25%;
    position: relative;

    iframe {
      width: 100%;
      height: 100%;
      @include centering(true, true);
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 56px;
    border: 1px solid $navy_20;
    border-radius: 28px;
    font-weight: 700;
    margin: 60px auto 0;
    position: relative;
    @include transition;

    @include media-sp {
      margin-top: 30px;
    }

    &::after {
      content: "\e909";
      font-size: 1.6rem;
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
      @include centering(false, true);
      right: 20px;
    }

    @media (hover: hover) {
      &:hover {
        background: $orange;
        border-color: $orange;
        color: $white;

        &::after {
          color: $white;
        }
      }
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    border: 1px solid $gray_40;
    border-radius: 100%;
    @include transition;

    &::before {
      content: "\e909";
      font-size: 1.6rem;
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $navy;
      @include centering(true, true);
    }

    @media (hover: hover) {
      &:hover {
        opacity: 0.7;
      }
    }

    .c-modal__iframe & {
      top: -40px;
      right: 1px;

      &::before {
        color: $gray_40;
      }
    }
  }
}
