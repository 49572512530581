@use '../base/setting' as *;

* {

  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

:root {
  scroll-padding-top: 10px;

  @include media-sp {
    scroll-padding-top: 78px;
  }
}

html {
  font-size: 62.5%;
}

body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-weight: 400;
  line-height: 1.8;
  font-size: 1.6rem;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  overflow-wrap: break-word;

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @include media-sp {
    font-size: 1.4rem;

    &.-fixed {
      width: 100%;
      height: 100%;
      position: fixed;
    }
  }
}

a {
  text-decoration: none;
}

p {
  line-height: 1.75;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

dl,
dl dd {
  margin: 0;
}

table {
  border-collapse: collapse;
  line-height: 1.75;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

fieldset {
  appearance: none;
  margin: 0;
  border: none;
}

button {
  appearance: none;
  margin: 0;
  border: none;
  background: inherit;
  cursor: pointer;
}


@media (hover: hover) {
  a {
    cursor: pointer;
    @include transition;
  }
}

/* PC
----------------------------------------------------------- */

@include media-pc-tb {
  .sp\:view {
    display: none !important;
  }
}

/* SP
----------------------------------------------------------- */

@include media-sp {

  .pc\:view {
    display: none !important;
  }
}