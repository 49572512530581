@use '../base/setting' as *;

.consulting-hybrid-work-suggestion {
  position: relative;
  color: $white;
  text-align: center;
  padding-top: 25px;

  @include media-sp {
    padding-top: 23px;
  }

  >span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: $navy;
    font-size: 2.4rem;
    font-weight: 700;
    @include centering(true, false);
    top: 0;
    z-index: 5;

    @include media-sp {
      width: 46px;
      height: 46px;
      font-size: 2.2rem;
    }
  }

  &__ttl {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px;
    background: $gray;
    color: $white;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5;
    position: relative;

    @include media-sp {
      height: 120px;
      font-size: 1.7rem;
    }

    &::after {
      content: "";
      display: block;
      background: $gray;
      width: 100%;
      height: 34px;
      clip-path: polygon(0 0, 100% 0, 50% 100%);
      @include centering(true, false);
      bottom: -34px;

      @include media-sp {
        height: 30px;
        bottom: -30px;
      }
    }
  }

  &__img {
    margin: 34px 0 12px;
  }

  &__txt {
    border-radius: 4px;
    background: $gray;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.5;
    padding: 16px 20px;

    @include media-sp {
      font-size: 1.6rem;
      padding: 10px 16px;
    }
  }

  &.-color01 {

    .consulting-hybrid-work-suggestion__ttl,
    .consulting-hybrid-work-suggestion__ttl::after,
    .consulting-hybrid-work-suggestion__txt {
      background: #6BB6B9;
    }
  }

  &.-color02 {

    .consulting-hybrid-work-suggestion__ttl,
    .consulting-hybrid-work-suggestion__ttl::after,
    .consulting-hybrid-work-suggestion__txt {
      background: #2E888E;
    }
  }
}

.consulting-hybrid-work-flow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include media-sp {
    flex-direction: column;
    align-items: flex-end;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 2px;
      height: calc(100% - 5px);
      background: $navy_80;
      position: absolute;
      top: 0;
      left: 14px;
    }

    &::after {
      content: "";
      display: block;
      background: $navy_80;
      width: 30px;
      height: 15px;
      clip-path: polygon(0 0, 100% 0, 50% 100%);
      position: absolute;
      bottom: 0;
      left: 0;
    }

  }

  &__item {
    width: calc(33% - 58px);
    position: relative;

    &:first-child {

      &::before,
      &::after {
        content: "";
        background: $gray_80;
        position: absolute;

        @include media-sp {
          @include centering(true, false);
        }
      }

      &::before {
        width: 4px;
        height: 40px;

        @include media-pc-tb {
          top: 146px;
          right: -48px;
        }

        @include media-sp {
          bottom: -56px;
        }
      }

      &::after {
        width: 40px;
        height: 4px;

        @include media-pc-tb {
          top: 164px;
          right: -66px;
        }

        @include media-sp {
          bottom: -38px;
        }
      }
    }

    &:nth-child(2)::after {
      content: "";
      display: block;
      background: $gray_80;
      width: 30px;
      height: 57px;
      clip-path: polygon(0 0, 100% 50%, 0 100%);

      @include media-pc-tb {
        position: absolute;
        top: 136px;
        right: -59px;
      }

      @include media-sp {
        width: 57px;
        height: 30px;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
        @include centering(true, false);
        bottom: -46px;
      }
    }

    @include media-sp {
      width: calc(100% - 50px);

      &:first-child {
        order: 2;
        margin-bottom: 72px;
      }

      &:nth-child(2) {
        order: 3;
        margin-bottom: 72px;
      }

      &:nth-child(3) {
        order: 4;
      }
    }
  }

  &__ttl {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    img {
      width: 120px;
      margin-right: 16px;

      @include media-sp {
        width: 90px;
        margin-right: 12px;
      }
    }

    span {
      display: block;
      font-size: 2.6rem;
      line-height: 1.5;
      font-weight: 700;

      @include media-sp {
        font-size: 2rem;
      }

      >span {
        font-size: 2rem;

        @include media-sp {
          font-size: 1.6rem;
        }
      }
    }
  }

  &__txt {
    margin-bottom: 16px;
  }

  &__list {
    li {
      width: 100%;
      padding: 6px 12px;
      background: $gray;
      font-size: 1.6rem;
      font-weight: 700;
      color: $white;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &.-color01 {
      li {
        &:nth-child(odd) {
          background: #2E888E;
        }

        &:nth-child(even) {
          background: #6BB6B9;
        }
      }
    }

    &.-color02 {
      li {
        &:nth-child(odd) {
          background: #EEA324;
        }

        &:nth-child(even) {
          background: #EBBE72;
        }
      }
    }
  }

  &__term {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;

    @include media-sp {
      order: 1;
      margin-bottom: 16px;
    }

    @include media-pc-tb {
      margin-top: 24px;

      &::before {
        content: "";
        width: calc(100% - 5px);
        height: 2px;
        background: $navy_80;
        @include centering(false, true);
        left: 0;
      }
    }

    >span {
      @include media-pc-tb {
        &:first-child {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background: $navy_80;
          @include centering(false, true);
          left: 0;
        }
      }

      &:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 48px;
        background: $gray_40;
        font-size: 2rem;
        font-weight: 700;
        position: relative;
        z-index: 5;

        @include media-sp {
          width: 100%;
        }
      }

      @include media-pc-tb {
        &:last-child {
          display: block;
          background: $navy_80;
          width: 16px;
          height: 32px;
          clip-path: polygon(0 0, 100% 50%, 0 100%);
          @include centering(false, true);
          right: 0;
        }
      }
    }
  }
}

.consulting-aao-office {

  &__heading {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.5;
    border-bottom: 1px solid $navy_60;
    padding-bottom: 12px;
    margin-bottom: 24px;

    @include media-sp {
      font-size: 2.1rem;
    }

    &.-blue,
    &.-green {
      display: flex;
      align-items: center;
      gap: 16px;
      font-size: 2rem;
      border-bottom-width: 2px;

      img {
        width: 79px;
      }

      @include media-sp {
        font-size: 1.8rem;
        padding-bottom: 8px;

        img {
          width: 48px;
        }
      }
    }

    &.-blue {
      border-bottom-color: #4489AA;
    }

    &.-green {
      border-bottom-color: #329F94;
    }
  }

  &__list {
    font-size: 1.4rem;

    @include media-sp {
      font-size: 1.2rem;
    }

    li {
      margin-bottom: 30px;

      @include media-sp {
        margin-bottom: 24px;
      }

      >span {
        font-size: 2rem;
        font-weight: 700;

        @include media-sp {
          font-size: 1.6rem;
        }

        >span {
          color: $orange;
        }
      }
    }
  }

  &__fecundity {
    position: relative;

    @include media-pc-tb {
      display: flex;
      justify-content: space-between;

      &::before {
        content: "";
        display: block;
        background: $gray_80;
        width: 40px;
        height: 120px;
        clip-path: polygon(0 0, 100% 50%, 0 100%);
        @include centering(true, true);
      }
    }

    >div {
      @include media-pc-tb {
        width: 45%;
      }

      @include media-sp {
        &:first-child {
          position: relative;
          margin-bottom: 68px;

          @include media-sp {

            &::before {
              content: "";
              display: block;
              background: $gray_80;
              width: 60px;
              height: 20px;
              clip-path: polygon(0 0, 100% 0, 50% 100%);
              @include centering(true, false);
              bottom: -44px;
            }
          }
        }
      }
    }
  }
}

.consulting-aao-space {
  &__img {
    margin-bottom: 16px;
  }

  &__tag {
    margin-bottom: 8px;
  }

  &__ttl {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 12px;
  }
}

.consulting-abw-float {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 20px;
  z-index: 99;
  padding: 0 115px;

  &.-close {
    display: none !important;
  }

  @include media-pc-s {
    padding: 0 72px;
  }

  @include media-tb {
    padding: 0 48px;
  }

  @include media-sp {
    bottom: 0;
    padding: 0;
  }

  &__inner {
    margin: 0 auto;
    max-width: $narrow-content-width;
    background: $gray_20;
    position: relative;

    @include media-pc-tb {
      display: flex;
      justify-content: space-between;
      border: 1px solid $navy_20;
    }

    @include media-sp {
      border-top: 1px solid $navy_20;
    }
  }

  &__close {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    background: $white;
    border: 1px solid $gray_40;
    border-radius: 100%;
    @include transition;

    @include media-sp {
      top: -40px;
      right: inherit;
      left: 10px;
    }

    &::before {
      content: "\e909";
      font-size: 1.4rem;
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $navy;
      @include centering(true, true);
    }

    @media (hover: hover) {
      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__topics {
    flex: 1;
    display: flex;
    position: relative;

    &::after {
      content: "";
      width: 1px;
      height: 40px;
      background: $navy_20;
      @include centering(false, true);
      right: 0;
    }

    @include media-sp {
      display: none !important;
    }
  }

  &__topics-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    padding: 10px;
    font-size: 2.2rem;
    font-weight: 700;
  }

  &__topics-latest {
    display: block;
    padding: 12px 0;

    @media (hover: hover) {
      &:hover {
        .consulting-abw-float__topics-latest-ttl span {
          text-decoration: underline;
        }
      }
    }
  }

  &__topics-latest-date {
    display: flex;
    align-items: center;
    gap: 16px;

    span:last-child {
      font-size: 1.4rem;
      font-weight: 700;
      color: $navy_80;
    }
  }

  &__topics-latest-ttl {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.5;

    small {
      font-size: 1.4rem;
    }

    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
      margin: 0 0.25em;
    }
  }

  &__link {
    display: flex;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 176px;
    line-height: 1.5;
    font-weight: 700;

    @include media-sp {
      width: 33.33%;
      height: 64px;
    }

    &::after {
      content: "\e902";
      margin-left: 0.25em;
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
    }

    &.-movie {
      background: $gray_80;
    }

    &.-cs {
      color: $white;
      background: $navy;

      &::after {
        content: "\e90a";
        color: $white;
      }
    }

    @media (hover: hover) {
      &:hover span {
        text-decoration: underline;
      }
    }
  }
}

.consulting-abw-merit {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 28px;

  @include media-sp {
    flex-direction: column;
    gap: 16px;
  }

  &__item {
    background: $white;
    width: calc((100% - 56px) / 3);
    padding: 30px;

    @include media-sp {
      width: 100%;
      padding: 24px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__ttl {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 8px;

    @include media-sp {
      width: 100%;
    }
  }

  &__img {
    text-align: center;
    margin-bottom: 10px;

    img {
      max-width: 200px;
    }

    @include media-sp {
      width: calc(100% - 120px);
      margin-bottom: 0;

      img {
        width: 100%;
      }
    }
  }

  &__num {
    text-align: center;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.1;
    color: $orange;

    @include media-sp {
      width: 110px;
    }
  }

  &__txt {
    margin-top: 20px;
    font-size: 1.4rem;

    @include media-sp {
      width: 100%;
    }
  }
}

.consulting-abw-activity {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 28px;

  @include media-sp {
    flex-direction: column;
    gap: 20px;
  }

  &__block {
    border: 1px solid $navy_20;

    @include media-pc-tb {
      &:nth-child(odd) {
        width: calc((100% - 28px) / 5 * 3);
      }

      &:nth-child(even) {
        width: calc((100% - 28px) / 5 * 2);
      }

      display: flex;
      flex-wrap: wrap;
    }
  }

  &__ttl {
    width: 100%;
    font-size: 1.6rem;
    font-weight: 500;
    text-align: center;
    padding: 6px;
    background: $gray_40;
    border-bottom: 1px solid $navy_20;
  }

  &__item {
    @include media-pc-tb {
      flex: 1;

      &:not(:last-child) {
        border-right: 1px solid $navy_20;
      }
    }

    @include media-sp {
      display: flex;
      flex-direction: row-reverse;

      &:not(:last-child) {
        border-bottom: 1px solid $navy_20;
      }
    }
  }

  &__txt {
    padding: 16px;

    @include media-sp {
      flex: 1;
      padding: 16px 20px;
    }

    h3 {
      font-size: 1.6rem;
      line-height: 1.5;
      font-weight: 700;
      margin-bottom: 8px;

      @include media-pc-tb {
        text-align: center;
      }
    }

    p {
      font-size: 1.4rem;
    }
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;

    @include media-sp {
      padding: 20px;
    }

    img {
      width: 100%;
      max-width: 145px;

      @include media-sp {
        max-width: 70px;
      }
    }

    &.-bg01 {
      background: #3C6B8D;
    }

    &.-bg02 {
      background: #4B8696;
    }

    &.-bg03 {
      background: #4893AE;
    }

    &.-bg04 {
      background: #E2C863;
    }

    &.-bg05 {
      background: #D1B598;
    }

    &.-bg06 {
      background: #CE5040;
    }

    &.-bg07 {
      background: #EA9A37;
    }

    &.-bg08 {
      background: #DEB9B3;
    }

    &.-bg09 {
      background: #A0B455;
    }

    &.-bg10 {
      background: #7D7B77;
    }
  }
}

.consulting-abw-performance {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 28px;

  @include media-sp {
    flex-direction: column;
  }

  &__item {
    @include media-pc-tb {
      display: grid;
      grid-template-columns: 166px 1fr;
      gap: 20px 16px;
      width: calc(50% - 14px);
    }
  }

  &__img {
    @include media-pc-tb {
      grid-area: 1 / 1 / 2 / 3;
    }
  }

  &__ttl {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 44px;
    color: $white;
    font-size: 1.8rem;
    font-weight: 700;

    @include media-pc-tb {
      grid-area: 2 / 1 / 3 / 2;
    }

    @include media-sp {
      font-size: 1.4rem;
      margin-bottom: 12px;
    }

    &.-blue {
      background: #3C6B8D;
    }

    &.-yellow {
      background: #E2C863;
    }

    &.-pink {
      background: #DEB9B3;
    }

    &.-green {
      background: #A0B455;
    }
  }

  &__txt {
    @include media-pc-tb {
      grid-area: 2 / 2 / 3 / 3;
    }
  }
}

.consulting-abw-recruit-ttl {
  line-height: 1.5;
  margin-bottom: 24px;
  display: flex;
  align-items: baseline;
  gap: 8px 12px;

  @include media-sp {
    flex-direction: column;
  }

  >span {
    display: block;

    &:first-child {
      font-size: 2.2rem;
      font-weight: 700;
      padding: 6px 15px;
      background: $gray_20;

      @include media-sp {
        padding: 4px 10px;
        font-size: 1.8rem;
      }
    }

    &:last-child {
      flex: 1;
      font-size: 2rem;
      font-weight: 700;

      >span {
        font-size: 2.4rem;
        color: $orange;
      }
    }
  }
}