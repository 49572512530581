@use '../base/setting' as *;

.js-fadein {
  opacity: 0;
  transition: all 1s;
  transform: translateY(30px);

  &.-active {
    opacity: 1;
    transform: translateY(0);
  }
}

.top-mv {

  &__video {
    height: calc(100dvh - 256px);
    min-height: 400px;
    max-height: 640px;
    overflow: hidden;
    position: relative;

    @include media-sp {
      height: calc(100dvh - 172px);
      max-height: 400px;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 16 / 9;
      @include centering(true, true);

      @include media-sp {
        aspect-ratio: 25 / 32;
      }
    }
  }

  &__cta {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 0 20px;
    margin: 30px auto 75px;

    @include media-sp {
      gap: 15px;
      margin: 24px auto;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 360px;
    min-height: 56px;
    border-radius: 4px;
    padding: 0 40px;
    background: $white;
    border: 1px solid $navy_20;
    color: $base-font-color;
    font-weight: 700;
    line-height: 1.4;
    position: relative;
    @include transition;

    @include media-sp {
      font-size: 1.2rem;
    }

    &::after {
      content: "\e900";
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      @include centering(false, true);
      right: 16px;
      color: $navy;
    }

    &::before {
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      font-size: 2.4rem;
      @include centering(false, true);
      left: 24px;
      color: $navy;

      @include media-sp {
        font-size: 2rem;
        left: 16px;
      }
    }


    &.-company::before {
      content: "\e90e";
      color: $orange;
    }

    &.-consumer::before {
      content: "\e90b";
    }

    @media (hover: hover) {
      &:hover {
        color: $white;
        background: $orange;
        border-color: $orange;

        &::before,
        &::after {
          color: $white !important;
        }
      }
    }
  }
}

.top-important-news {
  margin-bottom: 75px;
  padding: 0 115px;

  @include media-pc-s {
    padding: 0 72px;
  }

  @include media-tb {
    padding: 0 48px;
  }

  @include media-sp {
    margin-bottom: 48px;
    padding: 0 20px;
  }

  li {
    color: #B11919;
  }

  a {
    color: #B11919;
    font-weight: 500;

    &[target="_blank"]::after {
      content: "\e900";
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      margin-left: 0.25em;
    }

    &[href*=".pdf"] {
      margin-right: 0.25em;

      &::after {
        content: "\e904";
      }
    }
  }

  &__block {
    max-width: $base-content-width;
    margin: 0 auto;
    background: #FFF6F5;
    padding: 30px;

    @include media-pc-tb {
      display: flex;
      gap: 4vw;
    }

    @include media-sp {
      padding: 16px;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 700;

    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    @include media-sp {
      margin-bottom: 24px;
    }
  }

  &__list {
    @include media-pc-tb {
      flex: 1;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 20px;

        @include media-sp {
          margin-bottom: 12px;
        }
      }

      a {
        span {
          text-decoration: underline;
        }

        @media (hover: hover) {
          &:hover span {
            text-decoration: none;
          }
        }
      }
    }
  }

  &__link {

    @include media-sp {
      text-align: right;
    }

    a {
      @media (hover: hover) {
        &:hover span {
          text-decoration: underline;
        }
      }
    }

  }
}

.top-products,
.top-solution {
  padding: 64px 0;

  @include media-pc-tb {

    &__inner {
      align-items: center;
      gap: 60px;
      width: 100%;
    }

    &__img {
      width: calc((50% - 760px) + (1520px * 3 / 5) - 60px);
      position: relative;

      figure {
        &:not(:first-child) {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
          @include transition;
        }
      }
    }

    &__contents {
      width: calc(1520px * 2 / 5);
      display: flex;
    }
  }

  @include media-sp {
    padding: 40px 0;

    &__img {
      margin-bottom: 16px;
    }

    &__contents {
      padding: 0 20px;
    }
  }

  &__inner {
    display: flex;
  }

  &__heading {
    margin-bottom: 30px;

    @include media-pc-tb {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 16px 24px;
    }

    @include media-sp {
      margin-bottom: 16px;
    }

    span {
      display: block;
      font-size: 1.4rem;
      letter-spacing: 0.06em;
      color: #48606A;

      @include media-sp {
        margin-top: 8px;
      }
    }
  }

  &__txt {
    margin-bottom: 42px;

    @include media-sp {
      margin-bottom: 32px;
    }

    p+p {
      margin-top: 1em;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    font-weight: 700;
    line-height: 1.5;
    position: relative;
    padding: 16px 32px 16px 24px;
    border-top: 1px solid $gray;
    @include transition;

    &:last-child {
      border-bottom: 1px solid $gray;
    }

    span {
      display: block;

      &:first-child {
        width: 40px;

        img {
          width: 100%;
        }
      }
    }

    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-weight: normal;
      font-size: 1.6rem;
      color: $orange;
      @include centering(false, true);
      right: 16px;
    }

    &[target="_blank"]::after {
      content: "\e900";
    }

    @include media-sp {
      font-size: 1.4rem;
      padding: 16px 24px 16px 16px;

      &::after {
        right: 8px;
      }
    }

    @media (hover: hover) {
      &:hover {
        background: $white;
      }
    }
  }

  &__btn {
    display: flex;
    margin-top: 32px;

    @include media-sp {
      justify-content: center;
    }
  }
}

.top-products {
  background: url(/assets/img/bg_products-pc.png) no-repeat center left / auto 100%;

  @include media-pc-tb {
    margin-bottom: 16px;

    &__inner {
      justify-content: flex-end;
    }

    &__contents {
      margin-left: 115px;

      @include media-pc-s {
        margin-left: 72px;
      }

      @include media-tb {
        margin-left: 48px;
      }
    }
  }

  @include media-sp {
    margin-bottom: 40px;
    background: url(/assets/img/bg_products-sp.png) no-repeat top left / auto 100%;

    &__inner {
      flex-direction: column;
    }

    &__img {
      margin-right: -20px;
    }
  }

  &__heading {
    img {
      width: 326px;

      @include media-sp {
        width: 191px;
      }
    }
  }
}

.top-solution {
  background: url(/assets/img/bg_solution-pc.png) no-repeat center right / auto 100%;

  @include media-pc-tb {

    &__contents {
      margin-right: 115px;

      @include media-pc-s {
        margin-right: 72px;
      }

      @include media-tb {
        margin-right: 48px;
      }
    }
  }

  @include media-sp {
    background-image: url(/assets/img/bg_solution-sp.png);

    &__inner {
      flex-direction: column-reverse;
    }

    &__img {
      margin-left: -20px;
    }
  }

  &__inner {
    margin-bottom: 40px;
  }

  &__heading {
    img {
      width: 319px;

      @include media-sp {
        width: 187px;
      }
    }
  }

  &__field {
    max-width: 1750px;
    margin: 0 auto;
    padding: 0 115px;

    @include media-pc-s {
      padding: 0 72px;
    }

    @include media-tb {
      padding: 0 48px;
    }

    @include media-sp {
      padding: 0 20px;
    }
  }

  &__field-heading {
    font-size: 2.4rem;
    font-weight: 700;
    color: $navy;
    margin-bottom: 15px;

    @include media-sp {
      font-size: 2rem;
    }
  }

  &__field-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 15px;

    @include media-sp {
      grid-template-columns: repeat(2, 1fr);
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid $gray;
      border-radius: 4px;
      background: $white;
      min-height: 120px;
      font-size: 1.4rem;
      padding: 12px 8px;
      position: relative;
      overflow: hidden;

      @include media-sp {
        min-height: 100px;
        padding: 8px;
      }

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        border: 8px solid transparent;
        position: absolute;
        top: 0;
        left: 0;
        @include transition;
      }

      span {
        display: block;

        &:first-child {
          width: 40px;
          margin-bottom: 8px;

          img {
            width: 100%;
          }
        }

        &:last-child {
          font-weight: 700;

          &::after {
            content: "\e90a";
            font-family: 'icomoon';
            color: $orange;
            font-weight: normal;
            margin-left: 4px;
          }
        }
      }

      @media (hover: hover) {
        &:hover::after {
          border-color: $gray;
        }
      }
    }
  }
}

.top-section {
  padding: 80px 115px;

  @include media-pc-s {
    padding: 80px 72px;
  }

  @include media-tb {
    padding: 80px 48px;
  }

  @include media-sp {
    padding: 60px 20px;
  }

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
  }

  &__heading {
    text-align: center;
    margin-bottom: 15px;

    img {
      height: 38px;

      @include media-sp {
        height: 27px;
      }
    }

    span {
      display: block;
      font-size: 1.4rem;
      letter-spacing: 0.06em;
      color: #48606A;
      margin-top: 16px;

      @include media-sp {
        margin-top: 8px;
      }
    }
  }
}

.top-topics {
  background: url(/assets/img/bg_topics-pc.jpg) no-repeat bottom center / 100% calc(100% - 320px);

  @include media-sp {
    background-image: url(/assets/img/bg_topics-sp.jpg);
  }
}

.top-topics-slider {
  margin: 0 -14px;
  position: relative;

  @include media-sp {
    margin: 0 -10px;
  }

  &__inner {
    display: none;

    &.slick-initialized {
      display: block;
    }

    .slick-slide>div {
      height: 100%;
    }

    .slick-list {
      overflow: inherit;
    }
  }

  &__item {
    height: 100%;
    overflow: hidden;
    padding: 0 14px;

    @include media-sp {
      padding: 0 10px;
    }
  }

  &__arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin-top: 40px;
    padding: 0 20px;

    @include media-sp {
      justify-content: space-between;
      gap: 20px;
      margin-top: 16px;
      padding: 0 10px;
    }
  }

  &__prev,
  &__next {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: $white;
    border-radius: 100%;
    cursor: pointer;

    &::before {
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $navy;
    }

    &.slick-disabled {
      cursor: default;

      &::before {
        color: $navy_40;
      }
    }
  }

  &__prev {

    &::before {
      content: "\e907";
    }
  }

  &__next {

    &::before {
      content: "\e906";
    }
  }

  &__dots {

    .slick-dots {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px 16px;

      @include media-sp {
        gap: 8px 0;
      }

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;

        button {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: $white;
          position: relative;
          text-indent: -9999px;
        }

        &.slick-active {
          button {
            outline: none;
            background: $orange;
          }
        }
      }

    }
  }
}

.top-topics-card {
  height: 100%;
  display: block;
  background: $white;
  border: 1px solid $navy_20;

  &__img {
    border-bottom: 1px solid $navy_20;
    overflow: hidden;
    aspect-ratio: 3 / 2;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include transition;
    }
  }

  &__inner {
    padding: 20px;
    position: relative;
  }

  &__date {
    color: $navy_80;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__ttl {
    font-weight: 700;
    margin-bottom: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }

  &__txt {
    font-size: 1.4rem;
  }

  &[target="_blank"] .top-topics-card__inner::after {
    content: "\e900";
    font-family: 'icomoon';
    font-weight: normal;
    line-height: 1;
    position: absolute;
    top: 15px;
    right: 15px;
    color: $orange;
  }

  @media (hover: hover) {
    &:hover {
      .top-topics-card__img {
        img {
          transform: scale(1.1, 1.1);
        }
      }

      .top-topics-card__ttl span {
        text-decoration: underline;
      }
    }
  }
}

.top-knowledge {
  background: url(/assets/img/bg_knowledge-pc.jpg) no-repeat bottom center / 100% calc(100% - 340px);

  @include media-sp {
    background-image: url(/assets/img/bg_knowledge-sp.jpg);
  }
}

.top-knowledge-nav {
  @include media-pc-tb {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 28px;
  }

  &__item {
    background: #FAFCFD;

    @include media-pc-tb {
      text-align: center;
      padding: 40px 0;
    }

    @include media-sp {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 148px;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  &__ttl {

    @include media-pc-tb {
      text-align: center;

      img {
        height: 25px;
      }
    }

    @include media-sp {
      padding-left: 24px;
      grid-area: 1 / 1 / 2 / 2;

      img {
        width: auto;
        height: 22px;
      }
    }

    span {
      display: block;
      font-size: 1.4rem;
      letter-spacing: 0.06em;
      color: #48606A;
      margin-top: 4px;
    }
  }

  &__img {
    padding-bottom: 32px;

    @include media-sp {
      padding: 16px 24px;
      grid-area: 1 / 2 / 2 / 3;

      img {
        width: 100%;
        border-radius: 100%;
      }
    }
  }

  &__txt {
    text-align: center;
    font-size: 1.4rem;
    padding: 16px 20px 24px;

    @include media-sp {
      border-top: 1px solid #C6CDD6;
      grid-area: 2 / 1 / 3 / 3;
      padding: 16px 24px;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    padding: 0 16px;

    @include media-sp {
      grid-area: 3 / 1 / 4 / 3;
      padding: 0 24px 24px;
    }
  }
}

.top-case-studies {
  background: url(/assets/img/bg_case-studies-pc.jpg) no-repeat center center / cover;

  @include media-sp {
    background-image: url(/assets/img/bg_case-studies-sp.jpg);
  }
}

.top-case-studies-slider {
  margin: 0 -8px 40px;
  position: relative;

  @include media-sp {
    margin: 0 -10px 30px;
  }

  &__inner {
    display: none;

    &.slick-initialized {
      display: block;
    }

    .slick-slide>div {
      height: 100%;
    }

    @include media-sp {
      width: 80%;

      .slick-list {
        overflow: inherit;
      }
    }
  }

  &__item {
    height: 100%;
    padding: 0 8px;

    @include media-sp {
      padding: 0 8px;
    }
  }

  &__arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin-top: 40px;
    padding: 0 20px;

    @include media-sp {
      justify-content: space-between;
      gap: 16px;
      margin-top: 16px;
      padding: 0 10px;
    }
  }

  &__prev,
  &__next {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: $white;
    border-radius: 100%;
    cursor: pointer;

    &::before {
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $navy;
    }

    &.slick-disabled {
      cursor: default;

      &::before {
        color: $navy_40;
      }
    }
  }

  &__prev {

    &::before {
      content: "\e907";
    }
  }

  &__next {

    &::before {
      content: "\e906";
    }
  }

  &__dots {

    .slick-dots {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px 16px;

      @include media-sp {
        gap: 4px 0;
      }

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;

        button {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: $gray_60;
          position: relative;
          text-indent: -9999px;
        }

        &.slick-active {
          button {
            outline: none;
            background: $orange;
          }
        }
      }

    }
  }
}

.top-slideshow {
  background: #0F1B41;
  padding: 50px 100px 30px;

  @include media-sp {
    padding: 40px 0;
  }

  &__inner {
    max-width: 1448px;
    margin: 0 auto;
  }

  &__slider {

    .slick-list {
      overflow: inherit;
    }
  }
}

.top-slideshow-slider {
  margin: 0 -8px;
  position: relative;
  line-height: 0;

  @include media-sp {
    margin: 0 -10px;
    padding: 0 12px;
  }

  &__inner {
    display: none;

    &.slick-initialized {
      display: block;
    }

    .slick-slide>div {
      height: 100%;
    }

    .slick-list {
      overflow: inherit;
    }
  }

  &__item {
    height: 100%;
    padding: 0 8px;

    img {
      width: 100%;
    }

    @media (hover: hover) {
      a:hover {
        opacity: 0.7;
      }
    }

    @include media-sp {
      padding: 0 10px;
    }
  }

  &__arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin-top: 30px;
    padding: 0 20px;

    @include media-sp {
      justify-content: space-between;
      gap: 20px;
      margin-top: 24px;
      padding: 0 10px;
    }
  }

  &__prev,
  &__next {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: $white;
    border-radius: 100%;
    cursor: pointer;

    &::before {
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $navy;
    }

    &.slick-disabled {
      cursor: default;

      &::before {
        color: $navy_40;
      }
    }
  }

  &__prev {

    &::before {
      content: "\e907";
    }
  }

  &__next {

    &::before {
      content: "\e906";
    }
  }

  &__dots {

    .slick-dots {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px 16px;

      @include media-sp {
        gap: 8px 0;
      }

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;

        button {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: $white;
          position: relative;
          text-indent: -9999px;
        }

        &.slick-active {
          button {
            outline: none;
            background: $orange;
          }
        }
      }

    }
  }
}
