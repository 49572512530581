@use '../base/setting' as *;

.c-table {
  width: 100%;
  border-right: 1px solid $navy_20;
  border-bottom: 1px solid $navy_20;

  th,
  td {
    border-top: 1px solid $navy_20;
    border-left: 1px solid $navy_20;
    padding: 24px;

    @for $grid-col from 1 through 100 {
      &.-w#{$grid-col}p {
        width: calc($grid-col * 1%);
      }
    }

    @include media-sp {
      padding: 16px;
    }
  }

  th {
    font-weight: 700;
    background: #E7EBED;
    text-align: center;
    vertical-align: middle;
  }

  &.-beige {
    th {
      background: $beige_20;
    }
  }

  td {
    background: $white;
  }

  tr:first-child>*:first-child:empty {
    border: none !important;
    background: transparent !important;
  }

  @include media-sp {
    &.-sp-column {

      thead {
        display: none;
      }

      th,
      td {
        display: block;
        width: 100%;
        border-left: 1px solid $navy_20;
      }

      td[data-title]::before {
        content: attr(data-title);
        display: block;
        font-weight: 700;
        margin-bottom: 10px;
      }
    }
  }
}

.c-table-wrap {
  &__heading {
    width: 100%;
    padding: 24px;
    background: $navy_60;
    font-size: 1.8rem;
    font-weight: 700;
    color: $white;
    text-align: center;
    border-top: 1px solid $navy_20;
    border-right: 1px solid $navy_20;
    border-left: 1px solid $navy_20;

    @include media-sp {
      padding: 16px;
      font-size: 1.6rem;
    }

    .-beige & {
      background: $beige;
      color: $base-font-color;
    }
  }

  &__inner {

    @include media-sp {
      width: 100%;
      overflow-x: auto;

      .c-table {
        width: 1000px;
      }
    }
  }
}

.c-tabel-form {
  width: 100%;
  max-width: $narrow-content-width;
  margin: 0 auto;
  border-bottom: 1px solid $navy_20;

  &__head,
  &__body {
    padding: 32px 24px;
    border-top: 1px solid $navy_20;

    @include media-sp {
      width: 100%;
      padding: 16px;
      display: block;
    }
  }

  &__head {
    width: 28%;
    padding: 32px 80px 32px 24px;
    font-weight: 700;
    background: #E7EBED;
    position: relative;

    @include media-sp {
      width: 100%;
      padding: 16px 56px 16px 16px;
    }

    &.-required {
      &::after {
        content: "必須";
        display: block;
        padding: 6px 8px;
        background: $orange;
        color: $white;
        font-size: 1.2rem;
        line-height: 1;
        position: absolute;
        top: 32px;
        right: 30px;

        @include media-sp {
          font-size: 1.1rem;
          top: 16px;
          right: 16px;
        }
      }
    }
  }

  &__body {
    @include media-sp {
      padding: 20px 0;
    }

    @include media-sp {
      .c-checkbox-wrap {
        padding: 0 16px;
      }
    }
  }

  &__err {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    .error {
      font-size: 1.5rem;
      font-weight: 700;
      margin-top: 10px;
      color: $red;
      padding-left: 1.5em;
      position: relative;

      &::before {
        content: "\e916";
        font-size: 2rem;
        line-height: 1;
        font-family: 'icomoon';
        font-weight: normal;
        color: #E75B6E;
        position: absolute;
        top: 3px;
        left: 0;
      }

      &+.error {
        margin-top: 0;
      }
    }
  }

  &__name {
    display: flex;
    gap: 20px 45px;

    @include media-sp {
      flex-direction: column;
    }

    >div {
      @include media-pc-tb {
        width: 50%;
        max-width: 300px;
      }

      >div:not([class]) {
        display: flex;
        align-items: center;
        gap: 15px;

        label:not([class]) {
          font-weight: 700;
          width: 2em;
          flex-shrink: 0;
        }
      }
    }
  }

  &__address {

    >div {
      &:first-child {
        margin-bottom: 24px;

        >div:not([class]) {
          display: flex;
          align-items: center;
          gap: 15px;
        }
      }

      &:nth-child(2) {
        margin-bottom: 24px;
      }

      &:last-child {
        >label:not([class]) {
          @include media-pc-tb {
            margin-right: 1em;
          }
        }

        >input {
          margin-top: 15px;
        }
      }

      label:not([class]) {
        font-weight: 700;
        flex-shrink: 0;
      }
    }
  }

  &__unit {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 40px;

    @include media-sp {
      gap: 20px 24px;
    }

    >div {
      display: flex;
      align-items: center;
      gap: 15px;

      label {
        font-weight: 700;
        width: 1em;
        flex-shrink: 0;
      }
    }
  }
}
