@use '../base/setting' as *;

.resources-documents-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &:only-child {
    height: 100%;
  }

  &__img {
    border: 1px solid $navy_20;
    overflow: hidden;

    img {
      width: 100%;
      @include transition;
    }

    a:hover img {
      transform: scale(1.1, 1.1);
    }
  }

  &__inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 1px solid $navy_20;
    padding: 20px 25px 30px;
  }

  &__ttl {
    font-weight: 700;
  }

  &__sub-ttl {
    color: #747474;
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 5px;

    @include media-sp {
      font-size: 1.2rem;
    }
  }

  &__txt {
    margin: 15px 0 30px;
    margin-bottom: 30px;
  }

  &__btn {
    width: 100%;
    max-width: 350px;
    margin: auto auto 0;
  }
}

.resources-column-card {
  display: block;
  position: relative;

  &:only-child {
    height: 100%;
  }

  &__img {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    border-radius: 16px;
    border: 1px solid $navy_20;

    @include media-sp {
      border-radius: 8px;
    }

    img {
      width: 100%;
      @include transition;
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
  }

  &__tag {
    display: flex;
    width: inherit;
    margin: 0 16px 10px 0;

    @include media-sp {
      margin-bottom: 8px;
    }

  }

  &__date {
    width: inherit;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.4rem;
    font-weight: 700;
    color: $navy_80;
    margin-bottom: 10px;

    @include media-sp {
      font-size: 1.2rem;
      margin-bottom: 8px;
    }
  }

  &__ttl {
    width: 100%;
    line-height: 1.5;
    font-weight: 700;
    position: relative;

    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-size: 1.6rem;
      font-weight: normal;
      color: $orange;
    }
  }

  @media (hover: hover) {
    &:hover {
      .resources-column-card__img {
        img {
          transform: scale(1.1, 1.1);
        }
      }

      .resources-column-card__ttl span {
        text-decoration: underline;
      }
    }
  }
}
