@use '../base/setting' as *;

.c-stepbar {
  max-width: 750px;
  margin: 0 auto;

  @include media-sp {}

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    &::after {
      content: "";
      width: 70%;
      height: 2px;
      background: $gray_60;
      position: absolute;
      top: 30px;
      left: 15%;
      z-index: 1;

      @include media-sp {
        top: 25px;
      }
    }
  }

  &__item {
    text-align: center;
    width: 25%;
    color: rgba($navy, 0.5);
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.4;
    counter-increment: steps;
    position: relative;
    z-index: 2;

    @include media-sp {
      width: 30%;
      font-size: 1.4rem;
    }

    &::before {
      content: counter(steps);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin: 0 auto 8px;
      font-size: 2.2rem;
      text-align: center;
      background: $gray_20;
      color: rgba($navy, 0.5);

      @include media-sp {
        width: 50px;
        height: 50px;
        font-size: 2rem;
      }
    }

    &.-complete {
      color: $navy;

      &::before {
        background: $navy;
        color: $white;
      }
    }
  }
}