@use '../base/setting' as *;

.c-box01 {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px;
  position: relative;

  &:only-child {
    height: 100%;
  }

  &.-white {
    background: $white;
  }

  &.-gray {
    background: $gray_20;
  }

  &.-beige {
    background: $beige_20;
  }

  &.-orange {
    background: #FFF6F5;
  }

  &.-warning {
    background: $bg-warning;
  }

  &.-danger {
    background: $bg-danger;
  }

  &.-border {
    border: 1px solid $navy_20;
  }

  &.-round {
    border-radius: 16px;

    @include media-sp {
      border-radius: 8px;
    }
  }

  &__ttl {
    margin-bottom: 20px;

    @include media-sp {
      margin-bottom: 15px;
    }
  }

  &__txt {
    &:not(:last-child) {
      margin-bottom: 30px;

      @include media-sp {
        margin-bottom: 25px;
      }
    }
  }

  &__btn {
    font-size: 1.4rem;
    margin: auto auto 0;
  }

  &__link {
    font-size: 1.4rem;
    margin-top: 30px;
    text-align: center;

    @include media-sp {
      margin-top: 25px;
    }
  }

  @include media-sp {
    padding: 20px;
  }
}

.c-box02 {
  &__heading {
    position: relative;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100% - 80px);
      min-height: 90px;
      background: $navy;
      padding: 10px 15px;
      font-size: 2.2rem;
      font-weight: 700;
      color: $white;
      margin: 0 auto;
      position: relative;
      z-index: 3;

      @include media-sp {
        width: 100%;
        font-size: 2.1rem;
        min-height: 56px;
      }
    }

    @include media-pc-tb {

      &::before,
      &::after {
        content: "";
        display: block;
        width: 50%;
        height: 50%;
        border-top: 1px solid $navy_20;
        position: absolute;
        top: 50%;
        z-index: 2;
      }

      &::before {
        border-left: 1px solid $navy_20;
        left: 0;
      }

      &::after {
        border-right: 1px solid $navy_20;
        right: 0;
      }
    }
  }

  &__inner {
    padding: 40px;
    border: 1px solid $navy_20;
    border-top: 0;

    @include media-sp {
      padding: 20px;
    }

    @include media-pc-tb {
      &.-row {
        display: flex;
        align-items: center;
        gap: 40px;

        .c-box02__contents {
          flex: 1;
        }

        .c-box02__btn {
          width: 30%;
          min-width: 350px;
          margin-top: 0;
        }
      }
    }
  }

  &__ttl {
    margin-bottom: 20px;

    @include media-sp {
      margin-bottom: 15px;
    }
  }

  &__txt {}

  &__btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    @include media-sp {
      margin-top: 25px;
    }
  }
}

.c-line {
  border-top: 1px solid $navy_20;
}

.c-strong {
  font-weight: 700;
  border-bottom: 1px solid $orange;
}
