@use '../base/setting' as *;

.c-accordion {
  border-top: 1px solid $navy_20;
  border-bottom: 1px solid $navy_20;
  @include transition;

  &+& {
    border-top: 0;
  }

  &__ttl {
    font-size: 2rem;
    font-weight: 700;
    padding: 20px 64px 20px 20px;
    position: relative;
    cursor: pointer;
    @include transition;

    @include media-sp {
      font-size: 1.6rem;
      padding: 20px 64px 20px 15px;
    }

    >div {
      &:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 1px solid $navy_20;
        border-radius: 100%;
        background: $white;
        @include centering(false, true);
        right: 20px;

        @include media-sp {
          right: 15px;
        }

        &::after {
          content: "\e918";
          font-family: 'icomoon';
          font-weight: normal;
          line-height: 1;
          color: $orange;
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        background: #F6F8F9;
      }

      .-beige &:hover {
        background: $beige_20;
      }
    }

    &.-icon01,
    &.-icon02,
    &.-icon03,
    &.-icon04,
    &.-icon05,
    &.-icon06,
    &.-icon07,
    &.-icon08,
    &.-icon09,
    &.-icon10 {
      >div:first-child {
        position: relative;
        padding-left: 55px;

        @include media-sp {
          padding-left: 50px;
        }

        &::before {
          content: "";
          width: 40px;
          height: 40px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          @include centering(false, true);
          left: 5px;

          @include media-sp {
            top: 0;
            left: 0;
            transform: translate(0, 0);
          }
        }
      }
    }

    &.-icon01>div:first-child::before {
      background-image: url(/company/common/img/icon_material01.svg);
    }

    &.-icon02>div:first-child::before {
      background-image: url(/company/common/img/icon_material02.svg);
    }

    &.-icon03>div:first-child::before {
      background-image: url(/company/common/img/icon_material03.svg);
    }

    &.-icon04>div:first-child::before {
      background-image: url(/company/common/img/icon_material04.svg);
    }

    &.-icon05>div:first-child::before {
      background-image: url(/company/common/img/icon_material05.svg);
    }

    &.-icon06>div:first-child::before {
      background-image: url(/company/common/img/icon_material06.svg);
    }

    &.-icon07>div:first-child::before {
      background-image: url(/company/common/img/icon_material07.svg);
    }

    &.-icon08>div:first-child::before {
      background-image: url(/company/common/img/icon_material08.svg);
    }

    &.-icon09>div:first-child::before {
      background-image: url(/company/common/img/icon_material09.svg);
    }

    &.-icon10>div:first-child::before {
      background-image: url(/company/common/img/icon_material10.svg);
    }
  }

  &.-active {
    background: #F6F8F9;

    .c-accordion__ttl>div:last-child::after {
      content: "\e917";
    }

    &.-beige {
      background: $beige_20;
    }
  }

  &__contents {
    display: none;
    padding: 10px 20px 40px;

    @include media-sp {
      padding: 0px 15px 30px;
    }
  }

  &.-faq {

    .c-accordion__ttl,
    .c-accordion__contents {
      >div:first-child {
        padding-left: 32px;
        position: relative;

        &::before {
          font-size: 2.4rem;
          font-weight: 700;
          position: absolute;
          top: -0.2em;
          left: 0;

          @include media-sp {
            font-size: 2rem;
          }
        }
      }
    }

    .c-accordion__ttl>div:first-child::before {
      content: "Q";
      color: $navy;
    }

    .c-accordion__contents>div:first-child::before {
      content: "A";
      color: $orange;
    }
  }

  &.-single {
    border-top: 0;
    background: transparent !important;

    .c-accordion__ttl {
      font-size: 1.8rem;
      color: $navy;
      padding: 24px 40px 24px 0;

      @include media-sp {
        font-size: 1.6rem;
      }

      >div:last-child {
        right: 0;
      }
    }

    .c-accordion__contents {
      border-top: 1px solid $navy_20;
      padding: 40px 0;

      @include media-sp {
        padding: 30px 0;
      }
    }

    &.-no-bottom-border {

      &.-active {
        border-bottom-color: transparent;
      }

      .c-accordion__contents {
        padding-bottom: 0;
      }
    }

  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 40px auto 0;
    width: 220px;
    height: 56px;
    border-radius: 50px;
    background: $white;
    border: 1px solid $navy_20;
    color: $base-font-color;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    @include transition;

    @include media-sp {
      margin-top: 30px;
    }

    &::after {
      content: "\e917";
      font-size: 2rem;
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      @include centering(false, true);
      right: 16px;
      color: $orange;
    }

    @media (hover: hover) {
      &:hover {
        color: $white;
        background: $orange;
        border-color: $orange;

        &::after {
          color: $white;
        }
      }
    }
  }
}

.c-more {
  padding-bottom: 40px;
  border-bottom: 1px solid $navy_20;

  @include media-sp {
    padding-bottom: 32px;
  }

  &__contents {
    display: none;
    padding-top: 40px;

    @include media-sp {
      padding-top: 32px;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 32px auto 0;
    width: 220px;
    height: 56px;
    border-radius: 50px;
    background: $white;
    border: 1px solid $navy_20;
    color: $base-font-color;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    @include transition;

    @include media-sp {
      margin-top: 30px;
    }

    &::before {
      content: "もっと見る";
    }

    &::after {
      content: "\e918";
      font-size: 2rem;
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      @include centering(false, true);
      right: 16px;
      color: $orange;
    }

    &.-active {
      &::before {
        content: "閉じる";
      }

      &::after {
        content: "\e917";
      }
    }

    @media (hover: hover) {
      &:hover {
        color: $white;
        background: $orange;
        border-color: $orange;

        &::after {
          color: $white;
        }
      }
    }
  }
}