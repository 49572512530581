@use '../base/setting' as *;

.l-gnav {
  width: 100%;
  background: $white;

  &__list {
    display: flex;
    margin: 0 auto;
  }

  &__item {
    flex: auto;
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 12px;
    color: $base-font-color;
    font-weight: 400;

    @media (hover: hover) {
      &:hover {
        color: $navy;
        text-decoration: none;
      }
    }

    &.-current {}
  }

  @include media-pc-tb {
    margin-right: 16px;

    &__close-btn {
      display: none;
    }
  }

  @include media-pc-tb {
    &__link {
      height: 80px;
    }
  }

  @include media-sp {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 15;

    &__list {
      flex-direction: column;
      margin-bottom: 24px;
      border-bottom: 1px solid $navy_20;
    }

    &__item {
      border-top: 1px solid $navy_20;
    }

    &__link {
      padding: 16px;
    }

    &__close-btn {
      display: block;
      width: 100%;
      margin-top: 24px;
      padding: 16px;
      cursor: pointer;
    }
  }
}