@use '../base/setting' as *;

.l-localnav {
  &__list {
    border-top: 4px solid $orange;

    li {
      border-bottom: 1px solid $navy_20;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 50px;
    padding: 10px 25px 10px 15px;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;
    position: relative;

    &.-top {
      min-height: 70px;
      font-size: 1.8rem;
      font-weight: 700;
    }

    &.-second {
      font-size: 1.3rem;
      padding-left: 25px;
    }

    &.-active {
      background: #F2F5F7;
    }

    &.-current {
      background: rgba($orange_20, 0.4);
      color: $orange;
      pointer-events: none;
    }

    @media (hover: hover) {
      &:hover {
        .l-localnav__icon {

          &::before {
            transform: translateX(0);
            opacity: 1;
          }

          &::after {
            transform: translateX(0);
            opacity: 0;
          }
        }
      }
    }
  }

  &__icon {
    display: flex;
    width: 1em;
    height: 1em;
    font-size: 1.5rem;
    line-height: 1;
    overflow: hidden;
    @include centering(false, true);
    right: 5px;

    &::before,
    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-weight: normal;
      color: $orange;
      display: block;
      width: 1em;
      height: 1em;
      @include transition;
    }

    &::before {
      transform: translateX(-100%);
      opacity: 0;
    }

    &::after {
      transform: translateX(-100%);
      opacity: 1;
    }
  }
}