@use '../base/setting' as *;

.solution-theme-link {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &__item {

    @for $grid-col from 1 through 12 {
      &.-col-#{$grid-col} {
        width: calc(100% * $grid-col / 12);
      }
    }

    @include media-sp {
      @for $grid-col from 1 through 12 {
        &.-sp-col-#{$grid-col} {
          width: calc(100% * $grid-col / 12);
        }
      }
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: 420px;
    position: relative;

    @include media-sp {
      height: 240px;
    }

    @media (hover: hover) {
      &:hover {
        .solution-theme-link__block {
          color: $navy;
          background: $gray_60;
        }

        .solution-theme-link__ttl::after {
          color: $orange;
        }
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 50%;
      background: linear-gradient(0deg, rgba($black, 0.8), transparent);
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &__ttl {
    font-size: 2.8rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 16px;

    @include media-sp {
      font-size: 2.6rem;
      margin-bottom: 8px;
    }

    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-size: 2rem;
      font-weight: normal;
      margin-left: 8px;

      @include media-sp {
        font-size: 1.6rem;
      }
    }
  }

  &__block {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 20px;
    width: 100%;
    height: 50%;
    @include centering(true, false);
    bottom: 0;
    @include transition;
    color: $white;

    @include media-sp {
      height: fit-content;
    }
  }

  &__inner {
    max-width: 450px;
  }
}

.solution-field-link {
  display: flex;
  flex-wrap: wrap;
  margin: -32px -14px 0;

  @include media-sp {
    margin: -15px -7px 0;
  }

  &__item {
    margin: 32px 14px 0;
    width: calc(25% - 28px);
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $gray;
    border-radius: 8px;
    position: relative;
    overflow: hidden;

    @include media-sp {
      margin: 15px 7px 0;
      width: calc(50% - 14px);
      padding: 24px 16px;
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border: 8px solid transparent;
      position: absolute;
      top: 0;
      left: 0;
      @include transition;
    }

    span {
      display: block;

      &:first-child {
        width: 80px;
        margin-bottom: 16px;

        @include media-sp {
          width: 70px;
          margin-bottom: 24px;
        }

        img {
          width: 100%;
        }
      }

      &:last-child {
        font-weight: 500;

        &::after {
          content: "\e90a";
          font-family: 'icomoon';
          color: $orange;
          font-weight: normal;
          margin-left: 4px;
        }
      }
    }

    &[target="_blank"] span:last-child::after {
      content: "\e900";
    }

    @media (hover: hover) {
      &:hover::after {
        border-color: $gray;
      }
    }
  }
}

.solution-title {
  position: relative;

  &__heading {
    width: 95%;
    max-width: 946px;
    padding: 22px 20px 22px 200px;
    background: url(/solution/assets/img/bg_title-pc.png) no-repeat center center / cover;
    position: absolute;
    bottom: 0;
    left: 0;

    h1 {
      font-size: 3.4rem;
      font-weight: 700;
      line-height: 1.4;
      color: $white;
    }

    @include media-sp {
      padding: 20px;
      background-image: url(/solution/assets/img/bg_title-sp.png);

      h1 {
        font-size: 2.8rem;
      }
    }
  }
}

.solution-check-list {
  li {
    font-size: 1.8rem;
    position: relative;
    padding-left: 40px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    @include media-sp {
      font-size: 1.6rem;
    }

    &::before {
      content: "";
      display: block;
      width: 28px;
      height: 28px;
      background: url(/solution/assets/img/icon_check.svg) no-repeat center center / contain;
      position: absolute;
      top: 4px;
      left: 0;

      @include media-sp {
        width: 26px;
        height: 26px;
      }
    }
  }
}

.solution-box {
  height: 100%;
  border: 1px solid $navy_20;

  &__ttl {
    background: $gray_20;
    border-bottom: 1px solid $navy_20;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5;
    gap: 20px;
    padding: 12px;

    @include media-sp {
      font-size: 1.8rem;
      padding: 8px 12px;
      gap: 8px;
    }

    span {
      display: block;
    }
  }

  &__inner {
    padding: 30px;

    @include media-sp {
      padding: 24px 16px;
    }

  }

  &.-type02 {
    background: $gray_20;

    >.solution-box__ttl {
      background: $gray_40;
    }
  }

  &.-large {
    >.solution-box__ttl {
      font-size: 2.8rem;
      padding: 16px;

      @include media-sp {
        font-size: 2.1rem;
        padding: 12px;
      }
    }

    >.solution-box__inner {
      padding: 32px 40px;

      @include media-sp {
        padding: 24px 16px;
      }
    }
  }
}