@use '../../base/setting' as *;

.consumer-top-mv {
  background: url(/consumer/assets/img/bg_mv-pc.jpg) no-repeat center center / cover;
  padding: 60px 0 24px;

  @include media-sp {
    background-image: url(/consumer/assets/img/bg_mv-sp.jpg);
    padding-top: 15px;
  }

  &__slider {
    opacity: 0;
    transition: opacity .3s;
    line-height: 0;

    &.slick-initialized {
      opacity: 1;
    }
  }

  &__item {
    padding: 0 12px;

    @include media-sp {
      padding: 0 15px;
    }

    @media (hover: hover) {
      a:hover {
        opacity: 0.7;
      }
    }
  }

  &__bottom {
    width: 100%;
    max-width: 1073px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    @include media-tb {
      max-width: 960px;
    }

    @include media-sp {
      flex-direction: column;
      margin-top: 12px;
    }
  }

  &__dots {
    >ul {
      display: flex;
      gap: 8px 24px;

      @include media-sp {
        gap: 0 10px;
      }

      button {
        position: relative;
        width: 120px;
        height: 4px;
        background: $white;
        font-size: 0;

        @include media-tb {
          width: 100px;
        }

        @include media-sp {
          width: 50px;
          height: 2px;
        }
      }
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-num {
    color: $white;
    font-size: 2rem;

    @include media-pc-tb {
      order: 1;
      margin-right: 16px;
    }

    @include media-sp {
      font-size: 1.6rem;
    }

    .now-count {
      font-weight: 700;
    }
  }

  &__arrows {
    display: flex;
    align-items: center;

    @include media-pc-tb {
      justify-content: flex-end;
      gap: 16px;
    }

    @include media-sp {
      order: 2;
      justify-content: center;
      gap: 32px;
      margin-top: 12px;
    }
  }

  &__prev,
  &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: $gray_20;
    border-radius: 100%;
    cursor: pointer;

    @include media-sp {
      width: 32px;
      height: 32px;
    }

    &::before {
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
    }

    &.slick-disabled {
      cursor: default;

      &::before {
        color: $orange_40;
      }
    }
  }

  &__prev {
    &::before {
      content: "\e907";
    }

    @include media-pc-tb {
      order: 2;
    }
  }

  &__next {
    &::before {
      content: "\e906";
    }

    @include media-pc-tb {
      order: 3;
    }
  }
}

.consumer-top-important-news {
  margin-top: 60px;
  padding: 0 115px;

  @include media-pc-s {
    padding: 0 72px;
  }

  @include media-tb {
    padding: 0 48px;
  }

  @include media-sp {
    margin-top: 40px;
    padding: 0 20px;
  }

  li {
    color: #B11919;
  }

  a {
    color: #B11919;
    font-weight: 500;

    &[target="_blank"]::after {
      content: "\e900";
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      margin-left: 0.25em;
    }

    &[href*=".pdf"] {
      margin-right: 0.25em;

      &::after {
        content: "\e904";
      }
    }
  }

  &__block {
    max-width: $base-content-width;
    margin: 0 auto;
    background: #FFF6F5;
    padding: 30px;

    @include media-pc-tb {
      display: flex;
      gap: 4vw;
    }

    @include media-sp {
      padding: 16px;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 700;

    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    @include media-sp {
      margin-bottom: 24px;
    }
  }

  &__list {
    @include media-pc-tb {
      flex: 1;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 20px;

        @include media-sp {
          margin-bottom: 12px;
        }
      }

      a {
        span {
          text-decoration: underline;
        }

        @media (hover: hover) {
          &:hover span {
            text-decoration: none;
          }
        }
      }
    }
  }

  &__link {

    @include media-sp {
      text-align: right;
    }

    a {
      @media (hover: hover) {
        &:hover span {
          text-decoration: underline;
        }
      }
    }

  }
}

.consumer-top-intro {
  padding: 60px 40px;

  @include media-sp {
    padding: 40px 20px;
  }

  &__inner {}

  &__lead {
    font-size: 4rem;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 90px;

    @include media-sp {
      font-size: 3.2rem;
      text-align: left;
      margin-bottom: 40px;
    }
  }
}

.consumer-top-intro-item {

  &:not(:last-child) {
    margin-bottom: 60px;

    @include media-sp {
      margin-bottom: 90px;
    }
  }

  @include media-pc-tb {
    display: flex;
    gap: 28px;
    justify-content: space-between;

    &.-pc-reverse {
      flex-direction: row-reverse;
    }

    >* {
      width: calc(50% - 14px);
    }
  }

  &__img {

    @include media-sp {
      margin-bottom: 30px;
    }

    img {
      width: 100%;
      border-radius: 16px;

      @include media-sp {
        border-radius: 8px;
      }
    }
  }

  &__txt {
    @include media-pc-tb {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .consumer-top-intro-item.-pc-reverse & {
        justify-content: flex-end;
      }
    }

    >div {
      width: 100%;
      max-width: 740px;

      @include media-pc-tb {
        margin-right: 75px;

        @include media-pc-s {
          margin-right: 32px;
        }

        @include media-tb {
          margin-right: 8px;
        }

        .consumer-top-intro-item.-pc-reverse & {
          margin-right: 0;
          margin-left: 75px;

          @include media-pc-s {
            margin-left: 32px;
          }

          @include media-tb {
            margin-left: 8px;
          }
        }
      }

      p {
        font-size: 1.8rem;
        line-height: 2.4;
        font-weight: 500;

        @include media-sp {
          font-size: 1.6rem;
        }
      }
    }
  }

  &__heading {
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: 0.1em;
    margin-bottom: 30px;

    @include media-sp {
      margin-bottom: 20px;
    }

    span {
      display: block;

      &:first-child {
        font-size: 1.7rem;
        color: #AAA597;
        margin-bottom: 10px;

        @include media-sp {
          font-size: 1.2rem;
          margin-bottom: 5px;
        }
      }

      &:last-child {
        font-size: 3.4rem;

        @include media-sp {
          font-size: 2.6rem;
        }
      }
    }
  }
}

.consumer-top-loop {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: -150px -40px -100px;
  position: relative;
  z-index: -1;

  @include media-sp {
    margin: -75px -20px -45px;
  }

  &.-to-left .consumer-top-loop__img {
    animation: loopToLeft infinite linear 60s both;
  }

  &.-to-right .consumer-top-loop__img {
    animation: loopToRight infinite linear 60s both;
  }

  &__img {
    display: flex;
    align-items: center;

    li {
      width: 5523px;

      @include media-sp {
        width: 2534px;
      }
    }
  }
}

.consumer-top-heading {
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  padding-left: 16px;
  margin-bottom: 16px;

  &.-ajust {
    margin-bottom: -60px;
  }

  &.-showroom span:first-child img {
    height: 36px;

    @include media-sp {
      height: 60px;
    }
  }

  span {
    display: block;

    &:first-child {
      padding-top: 5px;
      margin-bottom: 10px;

      img {
        width: auto;
        height: 28px;

        @include media-sp {
          height: 22px;
        }
      }
    }

    &:last-child {
      font-size: 1.2rem;

      @include media-sp {
        font-size: 1rem;
      }
    }
  }

  &::before {
    content: "";
    width: 3px;
    height: 100%;
    background: $orange;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.consumer-top-special {
  @include media-pc-tb {
    margin-bottom: -43px;
  }
}

.consumer-top-products {
  padding-bottom: 0 !important;

  .l-section__inner {
    padding-bottom: 0 !important;
  }
}

.consumer-top-column-list {
  margin: 0 -14px;
  position: relative;

  .slick-list {
    overflow: visible;
  }

  .slick-num {
    @include media-sp {
      font-size: 1.4rem;
      font-weight: 500;
    }

    .all-count {
      color: $gray;
    }
  }

  .slick-slide>div {
    height: 100%;
  }

  &__item {
    padding: 0 14px;
    height: 100%;
  }

  &__arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-top: 16px;

    @include media-pc-tb {
      display: none;
    }
  }

  &__prev,
  &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: $gray_20;
    border-radius: 100%;
    cursor: pointer;

    &::before {
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
    }

    &.slick-disabled {
      cursor: default;

      &::before {
        color: $orange_40;
      }
    }
  }

  &__prev {
    &::before {
      content: "\e907";
    }
  }

  &__next {
    &::before {
      content: "\e906";
    }
  }
}

.consumer-top-column-card {
  display: block;
  position: relative;
  overflow: hidden;
  background: $beige_20;
  border-radius: 16px;

  &:only-child {
    height: 100%;
  }

  @include media-sp {
    border-radius: 8px;
  }

  &__img {
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      @include transition;
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 20px 25px;

    @include media-sp {
      padding: 10px 10px 15px;
    }
  }

  &__tag {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 10px;

    @include media-sp {
      margin-bottom: 8px;
    }
  }

  &__date {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.4rem;
    font-weight: 700;
    color: $navy_80;
    margin-bottom: 10px;

    @include media-sp {
      font-size: 1.2rem;
      margin-bottom: 8px;
    }
  }

  &__ttl {
    width: 100%;
    line-height: 1.5;
    font-weight: 700;
    position: relative;
    margin-bottom: 10px;

    &::after {
      content: "\e90a";
      font-size: 1.6rem;
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
      margin: 0 0.25em;
    }

    &:only-child {
      margin-bottom: 0;
    }
  }
}

@media (hover: hover) {
  a.consumer-top-column-card:hover {
    .consumer-top-column-card__img {
      img {
        transform: scale(1.1, 1.1);
      }
    }

    .consumer-top-column-card__ttl span {
      text-decoration: underline;
    }
  }
}

.consumer-top-showroom {
  background: url(/consumer/assets/img/bg_showroom-pc.jpg) no-repeat center center / cover;

  @include media-sp {
    background-image: url(/consumer/assets/img/bg_showroom-sp.jpg);

    .l-section__inner {
      padding-bottom: 100vw !important;
    }
  }

  &__box {
    width: 100%;
    max-width: 740px;
    background: rgba($white, 0.95);
    border-radius: 16px;
    padding: 60px 40px;

    @include media-sp {
      max-width: inherit;
      padding: 30px 15px;
    }
  }
}
