@use '../base/setting' as *;

.l-footer {
  padding: 0 48px;
  border-top: 1px solid $navy_20;

  @include media-sp {
    padding: 0 20px;
  }

  &__pagetop {
    display: none;
    position: fixed;
    bottom: 56px;
    right: 2.5vw;
    z-index: 9997;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 56px;
      height: 56px;
      border-radius: 100%;
      border: 1px solid $navy_20;
      background: rgba($white, 0.9);

      span {
        width: 1em;
        height: 1em;
        line-height: 1;
        overflow: hidden;

        &::before,
        &::after {
          content: "\e90d";
          font-family: 'icomoon';
          font-weight: normal;
          color: $navy;
          display: block;
          width: 1em;
          height: 1em;
          @include transition;
        }

        &::before {
          transform: translateY(0);
          opacity: 1;
        }

        &::after {
          transform: translateY(0);
          opacity: 0;
        }
      }

      @include media-pc-tb {
        &:hover {

          span {

            &::before {
              transform: translateY(-100%);
              opacity: 0;
            }

            &::after {
              transform: translateY(-100%);
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    max-width: $narrow-content-width;
    padding: 80px 0;
    margin: 0 auto;

    @include media-sp {
      flex-wrap: wrap;
      padding: 50px 0 60px;
    }
  }

  &__head {
    max-width: 182px;
    margin-right: 64px;

    @include media-sp {
      width: 100%;
      max-width: inherit;
      margin-right: 0;
    }
  }

  &__nav-pc {
    display: flex;

    @media (min-width: 1024px) and (max-width: 1365px) {
      justify-content: flex-end;
      flex: 1;
    }

    @include media-sp {
      display: none;
    }
  }

  &__nav-sp {
    width: 100%;

    @include media-pc-tb {
      display: none;
    }
  }

  &__logo {
    width: 156px;
    margin-bottom: 40px;

    @include media-sp {
      width: 150px;
      margin-right: auto;
      margin-left: auto;
    }

    img {
      width: 100%;
    }

    span {
      display: block;
      margin-top: 16px;
      font-size: 1.4rem;
      letter-spacing: 0.2em;
      line-height: 1;
      white-space: nowrap;

      @include media-sp {
        font-size: 1.3rem;
        text-align: center;
      }
    }

    @media (hover: hover) {
      a:hover {
        opacity: 0.7;
      }
    }
  }

  &__sns {
    display: flex;
    gap: 12px;

    @include media-sp {
      gap: 20px;
      justify-content: center;
      margin-bottom: 20px;
    }

    &.-gap-s {
      gap: 8px;

      @include media-sp {
        gap: 16px;
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-self: center;
      width: 30px;
      height: 30px;
      @include transition;

      @include media-sp {
        width: 40px;
        height: 40px;
      }

      img {
        width: 100%;
      }

      @media (hover: hover) {
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &__relation {

    li {
      line-height: 1;
    }

    @include media-sp {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-bottom: 25px !important;
      gap: 0 16px;

      li {
        flex: calc(50% - 8px);
        padding: 20px 0;
        text-align: center;
        border-bottom: 1px solid $gray_80;

        &:nth-child(1),
        &:nth-child(2) {
          border-top: 1px solid $gray_80;
        }
      }
    }

    @include media-pc-tb {
      max-width: 156px;
      margin-bottom: 40px !important;

      li {
        &:not(:nth-child(4)) {
          border-top: 1px solid $gray_80;
        }

        &:first-child,
        &:nth-child(2) {
          padding: 16px 0;
        }

        &:nth-child(3) {
          padding-top: 30px;
        }

        &:nth-child(4) {
          padding-top: 12px;
        }
      }
    }

    a {
      font-size: 1.2rem;
      font-weight: 700;

      &[target="_blank"]::after {
        content: "\e900";
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        color: $navy;
        margin-left: 0.25em;
      }

      @media (hover: hover) {
        &:hover span {
          text-decoration: underline;
        }
      }
    }
  }

  &__bottom {
    background: $base-font-color;
    color: $white;
    padding: 40px 48px;
    font-weight: 400;
    margin: 0 -48px;

    @include media-sp {
      margin: 0 -20px;
      padding: 50px 16px;

      .l-footer__inner+& {
        padding: 40px 16px 16px;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 12px 32px;
      font-size: 1.2rem;
      margin-bottom: 40px;

      @media (hover: hover) {
        li a:hover>span {
          text-decoration: underline;
        }
      }

      @include media-sp {
        justify-content: flex-start;
      }
    }

    p {
      font-size: 1.4rem;
      text-align: center;
      color: #C0C0C0;
    }
  }
}

.c-footer-list {
  line-height: 1.5;

  li:not(:last-child) {

    @include media-tb {
      margin-bottom: 4px;
    }

    @include media-sp {
      margin-bottom: 8px;
    }
  }
}

.c-footer-link {
  font-weight: 700;

  @include media-pc {
    span {
      white-space: nowrap;
    }
  }

  &.-small {
    font-size: 1.4rem;
  }

  &.-gray {
    font-size: 1.4rem;
    font-weight: 400;
    color: #7A878D;

    &[target="_blank"]::after {
      color: #7A878D;
    }
  }

  &[target="_blank"]::after {
    content: "\e900";
    font-family: 'icomoon';
    font-weight: normal;
    line-height: 1;
    color: $navy;
    margin-left: 0.25em;
  }

  @media (hover: hover) {
    &:hover span {
      text-decoration: underline;
    }
  }
}

.c-footer-pc-item {

  &.-corporate {
    &:first-child {
      margin-right: 40px;

      .c-footer-pc-item__block {
        display: flex;
        gap: 0 26px;
      }

      @include media-tb {
        margin-right: 48px;
        width: calc(55% - 48px);

        .c-footer-pc-item__block {
          gap: 0 32px;
        }
      }
    }

    &:nth-child(2) {
      margin-right: 40px;

      @include media-tb {
        margin-right: 48px;
        width: calc(23% - 48px);
      }
    }

    &:last-child {
      @include media-tb {
        width: 22%;
      }
    }
  }

  &.-company {
    &:not(:last-child) {
      margin-right: 80px;

      @include media-tb {
        margin-right: calc(20vw - 175px);
      }
    }
  }

  &.-consumer {
    &:first-child {
      margin-right: 130px;

      @include media-tb {
        margin-right: calc(24vw - 175px);
      }
    }

    .c-footer-pc-item__block {
      display: flex;
      gap: 0 120px;

      @include media-tb {
        gap: 0 calc(24vw - 187px);
        justify-content: space-between;
      }
    }
  }

  &__link {
    &.-full {
      width: 100%;
    }

    >a {
      font-weight: 700;

      &[target="_blank"]::after {
        content: "\e900";
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        color: $navy;
        margin-left: 0.25em;
      }

      &:hover span {
        text-decoration: underline;
      }
    }
  }
}

.c-footer-sp-item {
  &__btn {
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    font-size: 1.6rem;
    font-weight: 700;
    padding: 8px;
    border-bottom: 1px solid $gray_80;
    position: relative;

    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $navy;
      @include centering(false, true);
      right: 8px;
    }

    &.-down {
      &::after {
        content: "\e902";
      }

      &.-active {
        &::after {
          content: "\e90d";
        }
      }
    }
  }

  &__list {
    display: none;
    padding: 20px 10px;
  }

  &__link {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    a {
      font-size: 1.6rem;
      font-weight: 700;
    }
  }
}
