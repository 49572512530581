@use '../base/setting' as *;

.c-news {
  border-top: 1px solid $navy_20;

  &__item {
    display: block;
    flex-wrap: wrap;
    border-bottom: 1px solid $navy_20;
    padding: 24px 16px;

    @include media-sp {
      padding: 16px;
    }

    &.-image {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-template-rows: max-content 1fr;
      align-items: flex-start;
    }
  }

  &__img {
    width: 254px;
    grid-area: 1 / 1 / 3 / 2;
    margin-right: 40px;
    overflow: hidden;

    img {
      width: 100%;
      @include transition;
    }

    @include media-sp {
      width: 144px;
      grid-area: 2 / 1 / 3 / 2;
      margin-right: 15px;
    }
  }

  &__head {
    display: flex;
    margin-bottom: 10px;

    @include media-pc-tb {
      align-items: center;
    }

    @include media-sp {
      flex-direction: column;
    }

    .-image & {
      grid-area: 1 / 2 / 2 / 3;

      @include media-sp {
        grid-area: 1 / 1 / 2 / 3;
      }
    }
  }

  &__date {
    font-size: 1.6rem;
    font-weight: 700;
    color: $navy_80;
    margin-right: 24px;

    @include media-sp {
      font-size: 1.6rem;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  &__tag {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    @include media-sp {
      margin-bottom: 8px;
    }
  }

  &__ttl {
    width: 100%;

    .-image & {
      grid-area: 2 / 2 / 3 / 3;
    }

    >div {
      display: inline;

      &::after {
        content: "\e90a";
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        color: $orange;
        margin-left: 0.25em;
      }
    }
  }

  &__item {
    &[target="_blank"] .c-news__ttl>div::after {
      content: "\e900";
    }

    &[href*=".pdf"] .c-news__ttl>div::after {
      content: "\e904";
      color: $orange;
      margin-right: 0.25em;
    }

    @media (hover: hover) {
      &:hover {
        .c-news__img img {
          transform: scale(1.1, 1.1);
        }

        .c-news__ttl>div>span {
          text-decoration: underline;
        }
      }
    }
  }
}