@use '../base/setting' as *;
@use "sass:math";

.l-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -40px -14px 0;

  @include media-pc-tb {
    &.-gap-s {
      margin: -20px -8px 0;
    }
  }

  &__item {
    margin: 40px 14px 0;

    @for $grid-col from 1 through 12 {
      &.-col-#{$grid-col} {
        width: calc(calc(100% * $grid-col / 12) - 28px);
      }
    }

    @include media-pc-tb {
      .l-grid.-gap-s & {
        margin: 20px 8px 0;

        @for $grid-col from 1 through 12 {
          &.-col-#{$grid-col} {
            width: calc(calc(100% * $grid-col / 12) - 16px);
          }
        }
      }
    }
  }

  @include media-sp {
    &.-sp-reverse {
      flex-direction: column-reverse;
    }

    margin: -32px -8px 0;

    &__item {
      margin: 32px 8px 0;

      @for $grid-col from 1 through 12 {
        &.-col-#{$grid-col} {
          width: calc(calc(100% * $grid-col / 12) - 16px);
        }
      }

      @for $grid-col from 1 through 12 {
        &.-sp-col-#{$grid-col} {
          width: calc(calc(100% * $grid-col / 12) - 16px);
        }
      }

      .l-grid.-sp-gap-s & {
        margin: 16px 8px 0;
      }
    }

    &.-sp-gap-none {
      .l-grid__item:not(:first-child) {
        margin: 0.5em 8px 0;
      }
    }

    &.-sp-gap-s {
      margin: -16px -8px 0;
    }

    &.-sp-contents {
      display: contents;

      .l-grid__item {
        display: contents;
      }
    }
  }
}