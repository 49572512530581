@use '../base/setting' as *;

.c-slider {
  margin: 0 -14px;
  position: relative;

  @include media-sp {
    margin: 0 -10px;
  }

  &.-max750 {
    max-width: 790px;

    @include media-pc-tb {
      margin: 0 auto;
    }
  }

  &__inner {
    display: none;

    &.slick-initialized {
      display: block;
    }

    .slick-slide>div {
      height: 100%;
    }
  }

  &__item {
    height: 100%;
    padding: 0 14px;

    @include media-sp {
      padding: 0 10px;
    }
  }

  &__arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin-top: 40px;
    padding: 0 20px;

    @include media-sp {
      gap: 20px;
      margin-top: 16px;
    }
  }

  &__prev,
  &__next {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: $gray_20;
    border-radius: 100%;
    cursor: pointer;

    &::before {
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $navy;
    }

    &.slick-disabled {
      cursor: default;

      &::before {
        color: $navy_40;
      }
    }
  }

  &__prev {

    &::before {
      content: "\e907";
    }
  }

  &__next {

    &::before {
      content: "\e906";
    }
  }

  &__dots {

    .slick-dots {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px 16px;

      @include media-sp {
        gap: 8px 0;
      }

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;

        button {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: $gray_60;
          position: relative;
          text-indent: -9999px;
        }

        &.slick-active {
          button {
            outline: none;
            background: $orange;
          }
        }
      }
    }
  }

  &.-bg-dark {

    .c-slider__prev,
    .c-slider__next {
      background: $white;
    }
  }
}

.c-slider-product {
  margin: 0 -14px;
  position: relative;
  display: flex;
  flex-direction: column;

  .slick-list {
    overflow: visible;
  }

  .slick-num {
    font-size: 2rem;
    font-weight: 500;

    @include media-pc-tb {
      order: 1;
      margin-right: 16px;
    }

    @include media-sp {
      font-size: 1.4rem;
    }

    .all-count {
      color: $gray;
    }
  }

  @include media-pc-tb {
    &.-heading-ajust {
      margin-top: -86px;
    }
  }

  &__inner {
    display: none;

    &.slick-initialized {
      display: block;
    }

    @include media-pc-tb {
      width: 45%;

      &.-pc-col-3 {
        width: 25%;
      }
    }

    @include media-sp {
      width: 100%;
      order: 1;
    }
  }

  &__item {
    padding: 0 14px;
  }

  &__arrows {
    display: flex;
    align-items: center;

    @include media-pc-tb {
      justify-content: flex-end;
      gap: 16px;
      margin: 0 20px 40px;
    }

    @include media-sp {
      order: 2;
      justify-content: center;
      gap: 32px;
      margin-top: 16px;
    }
  }

  &__prev,
  &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: $gray_20;
    border-radius: 100%;
    cursor: pointer;

    @include media-sp {
      width: 32px;
      height: 32px;
    }

    &::before {
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
    }

    &.slick-disabled {
      cursor: default;

      &::before {
        color: $orange_40;
      }
    }
  }

  &__prev {
    &::before {
      content: "\e907";
    }

    @include media-pc-tb {
      order: 2;
    }
  }

  &__next {
    &::before {
      content: "\e906";
    }

    @include media-pc-tb {
      order: 3;
    }
  }
}
