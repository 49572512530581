@use '../base/setting' as *;

.case-filter {

  @include media-pc-tb {
    &__ttl {
      margin-bottom: 24px;
    }
  }

  @include media-sp {
    border: 1px solid $navy_20;

    &__ttl {
      padding: 20px;
      position: relative;

      &::before {
        content: "";
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 1px solid $navy_20;
        border-radius: 100%;
        background: $white;
        @include centering(false, true);
        right: 20px;

        @include media-sp {
          right: 15px;
        }
      }

      &::after {
        content: "\e918";
        font-size: 2rem;
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        color: $orange;
        @include centering(false, true);
        right: 20px;
      }
    }

    &__contents {
      padding: 0 20px 25px;

      .c-box01 {
        display: contents;
      }
    }

    &.-active .case-filter__ttl::after {
      content: "\e917";
    }
  }
}

.case-result-num {
  font-size: 1.8rem;
  font-weight: 700;

  span {
    font-size: 2.4rem;
  }

  @include media-sp {
    font-size: 1.6rem;

    span {
      font-size: 2.1rem;
    }
  }
}

.case-detail {
  display: flex;
  flex-wrap: wrap;

  &__award {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 40px;

    span {
      &:first-child {
        width: 30px;
        height: 30px;

        img {
          width: 100%;
        }
      }

      &:last-child {
        font-size: 1.4rem;
      }
    }

    @include media-sp {
      width: 100%;
      margin: 0 0 20px 0;
    }
  }

  &__tag {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  &__date {
    display: flex;
    align-items: center;

    span {
      font-size: 1.4rem;
      font-weight: 700;
      color: $navy_80;
    }

    @include media-sp {
      span {
        font-size: 1.2rem;
      }
    }
  }

  &__heading {
    width: 100%;
    margin: 24px 0 20px;

    h1 {
      font-size: 4rem;
      line-height: 1.5;
      font-weight: 700;
    }

    @include media-sp {
      margin: 25px 0;

      h1 {
        font-size: 3rem;
      }
    }
  }

  &__sns {
    width: 100%;
    margin-bottom: 60px;

    @include media-sp {
      margin-bottom: 35px;
    }
  }

  &__img {
    width: 65%;
    margin-right: 40px;

    @include media-sp {
      width: 100vw;
      margin: 0 -20px 40px;
    }
  }

  &__info {
    font-size: 1.4rem;
    width: calc(35% - 40px);

    @include media-sp {
      width: 100%;
    }
  }
}

.case-contact {
  background: url(/case-studies/detail/assets/img/bg_contact-pc.jpg) no-repeat center center / cover;
  color: $white;
  padding: 108px 20px;

  @include media-sp {
    background-image: url(/case-studies/detail/assets/img/bg_contact-sp.jpg);
    padding: 38px 20px 56px;
    margin: 0 -20px;
  }
}

.js-case-detail-item {

  &__txt {
    display: none;
  }

  &__img {
    aspect-ratio: 16 / 9;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.case-detail-modal-item {
  width: 100%;
  max-width: 740px;
  margin: 0 auto;

  &__img {
    text-align: center;
    margin-bottom: 30px;

    @include media-sp {
      margin-bottom: 20px;
    }
  }

  &__ttl {
    font-size: 1.8rem;
    font-weight: 700;

    @include media-sp {
      font-size: 1.6rem;
    }
  }

  &__txt {
    margin-top: 16px;

    &.-hidden {
      display: none;
    }
  }
}
