@use '../base/setting' as *;

.c-map {
  width: 100%;
  max-width: 752px;
  height: 487px;

  @include media-sp {
    max-width: inherit;
    height: 52vw;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}