@use '../base/setting' as *;

.c-link {
  font-weight: 700;

  &::after,
  &::before {
    font-family: 'icomoon';
    font-weight: normal;
    line-height: 1;
    color: $orange;
  }

  &::before {
    display: none;
    margin-right: 0.25em;
  }

  &::after {
    content: "\e90a";
    margin-left: 0.25em;
  }

  &.-small {
    font-weight: 400;
    font-size: 1.4rem;
  }

  &.-navy {
    &::after {
      color: $navy;
    }
  }

  &.-white {
    &::after {
      color: $white;
    }
  }

  &[target="_blank"]::after {
    content: "\e900";
  }

  &.js-modal-open::after {
    content: "\e911";
  }

  &[href*=".pdf"]::after {
    content: "\e904";
    color: $orange;
    margin-right: 0.25em;
  }

  &.-prev {
    &::before {
      display: inline-block;
      content: "\e912";
    }

    &::after {
      display: none;
    }
  }

  &.-next::after {
    content: "\e90a" !important;
  }

  &.-share {
    &::before {
      display: inline-block;
      content: "\e913";
    }

    &::after {
      display: none;
    }
  }

  @media (hover: hover) {
    &:hover span {
      text-decoration: underline;
    }
  }
}

.c-link-text {
  color: $navy_60;
  font-weight: 700;

  &.-small {
    font-weight: 400;
    font-size: 1.4rem;

    @include media-sp {
      font-size: 1.2rem;
    }
  }

  &::after {
    content: "\e90a";
    font-family: 'icomoon';
    font-weight: normal;
    line-height: 1;
    color: $orange;
    margin: 0 0.25em;
  }

  &[target="_blank"]::after {
    content: "\e900";
  }

  &[href*=".pdf"]::after {
    content: "\e904";
    color: $orange;
  }

  @media (hover: hover) {
    &:hover span {
      text-decoration: underline;
    }
  }
}

.c-link-list {
  display: flex;
  flex-wrap: wrap;
  margin: -20px -32px 0 0 !important;

  @include media-sp {
    margin: -20px 0 0 0 !important;
  }

  li {
    margin: 20px 32px 0 0;
    width: calc(25% - 32px);

    @include media-sp {
      margin: 20px 0 0 0;
      width: 100%;
    }
  }
}

.c-link-image {
  display: block;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    width: 100%;
    height: 74px;
    opacity: 0.6;
    background: linear-gradient(0deg, rgba($black, 1) 0%, rgba($black, 0) 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 4;
  }

  img {
    width: 100%;
    @include transition;
  }

  >span {
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 700;
    position: absolute;
    left: 15px;
    bottom: 15px;
    color: $white;
    z-index: 5;

    @include media-sp {
      font-size: 1.6rem;
      left: 10px;
      bottom: 12px;
    }

    &::after {
      content: "\e90a";
      font-size: 1.6rem;
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      margin-left: 6px;
    }
  }

  @media (hover: hover) {
    &:hover {
      img {
        transform: scale(1.1, 1.1);
      }
    }
  }
}