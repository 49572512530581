@use '../base/setting' as *;

.mf_finder_searchBox {
  margin: 0 0 32px;

  @include media-sp {
    margin-bottom: 24px;
  }
}

//検索バー
.mf_finder_searchBox_items_wrapper {
  margin-bottom: 16px;

  .mf_finder_searchBox_items {
    display: block;
    padding: 0;
    border: 1px solid $navy_20;
    border-radius: 3px;
    position: relative;
  }

  .mf_finder_searchBox_query_input {
    height: 56px;
    font-size: 1.6rem;
    border: 0;
    padding: 8px 56px 8px 16px;

    &::placeholder {
      color: rgba($base-font-color , 0.5);
    }
  }

  .mf_finder_searchBox_submit {
    width: 56px;
    height: 56px;
    background: transparent;
    color: $navy;
    @include centering(false, true);
    right: 0;

    &::before {
      content: "\e905";
      font-size: 2.4rem;
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      @include centering(true, true);
      margin: 0;
    }
  }
}

.mf_finder_searchBox_useToggle {
  .mf_finder_searchBox_selects {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;

    @include media-sp {
      gap: 12px 16px;
    }

    label {
      margin: 0;

      &::before {
        display: block;
        width: inherit;
        height: inherit;
        font-family: inherit;
        font-size: 1.4rem;
        margin: 0;

        @include media-sp {
          font-size: 1.2rem;
        }
      }

      &::after {
        content: "\e902";
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        color: $orange;
        @include centering(false, true);
        right: 16px;
        font-size: 1.6rem;
        pointer-events: none;
      }
    }

    select {
      height: 44px;
      border: 1px solid $navy_20;
      background: $white;
      border-radius: 3px;
      padding: 4px 48px 4px 12px;
      font-size: 1.6rem;

      @include media-sp {
        height: 40px;
      }
    }

    .mf_finder_searchBox_category,
    .mf_finder_searchBox_doctype,
    .mf_finder_searchBox_sort,
    .mf_finder_searchBox_pagemax {
      display: inline-flex;
      align-items: center;
      gap: 4px;
    }

    .mf_finder_searchBox_imgsize {
      display: none !important;
    }

    .mf_finder_searchBox_category {
      width: calc(100% - 181px);

      @include media-sp {
        width: calc(100% - 48px);
      }

      &::before {
        width: fit-content;
        content: "検索対象：";
      }

      &::after {
        right: 16px;
      }

      .mf_finder_searchBox_category_select {
        flex: 1;
      }
    }

    .mf_finder_searchBox_toggle {
      float: none;
      width: 165px;
      position: relative;

      @include media-sp {
        width: 32px;
      }

      a {
        width: inherit;
        height: inherit;
        margin: 0;
        color: $base-font-color;

        &::before {
          position: static;
          font-size: 1.6rem;
        }

        &::after {
          @include media-pc-tb {
            content: "検索表示条件の設定";
          }

          position: static;
          font-size: 1.4rem;
          margin-left: 4px;
        }
      }

      &::after {
        @include media-pc-tb {
          content: "\f0d7";
          font-family: FontAwesome;
          @include centering(false, true);
          right: 0;
          font-size: 1.4rem;
        }
      }
    }

    .mf_finder_searchBox_doctype {
      &::before {
        width: fit-content;
        content: "ファイル形式：";
      }

      select {
        width: 160px;
      }

      @include media-sp {
        margin-bottom: 12px;
      }
    }

    .mf_finder_searchBox_sort {
      &::before {
        width: fit-content;
        content: "表示順：";
      }
    }

    .mf_finder_searchBox_pagemax {
      &::before {
        width: fit-content;
        content: "結果件数：";
      }
    }

    .mf_finder_searchBox_misc {
      width: 100%;
      padding: 0;

      label {

        @include media-pc-tb {

          &:first-child,
          &:nth-child(2) {
            margin-right: 24px;
          }
        }

        @include media-sp {
          &:nth-child(2) {
            margin-right: 16px;
          }
        }
      }
    }
  }

  &.mf_finder_searchBox_useToggle_show .mf_finder_searchBox_selects .mf_finder_searchBox_toggle {
    @include media-pc-tb {
      &::after {
        content: "\f0d8";
      }

      a::after {
        content: "検索表示条件の設定";
      }
    }
  }
}

//　もしかして〜
.mf_finder_spellcheck {
  margin: 0 0 16px;

  @include media-sp {
    margin-bottom: 12px;
  }

  .mf_finder_spellcheck_item {
    font-size: 1.4rem;

    a {
      margin: 0 4px;
      font-size: 1.6rem;
      color: $navy_60;

      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &::before,
    &::after {
      color: #3D3D3D;
    }

    @include media-sp {
      font-size: 1.2rem;

      a {
        font-size: 1.4rem;
      }
    }
  }
}

//カスタムheader
.mf_finder_organic_header_wrapper {
  .my-header_zerohitarea {
    display: none;
  }

  &.mf_finder_organic_zerohit {
    .my-header_organic_nums {
      display: none !important;
    }

    .my-header_zerohitarea {
      display: block !important;
      margin-top: 32px;

      @include media-sp {
        margin-top: 24px;
      }
    }
  }

  .my-header {
    .my-header_query {
      font-size: 1.4rem;
      color: #727272;
      margin-bottom: 16px;

      @include media-sp {
        font-size: 1.2rem;
        margin-bottom: 10px;
      }

      span {
        font-size: 1.8rem;
        font-weight: 700;
        color: #3D3D3D;

        &::before {
          content: "「";
        }

        &::after {
          content: "」";
        }
      }
    }

    .my-header_organic_nums {
      display: flex;
      gap: 16px;
    }

    .my-header_organic_total {
      font-size: 1.4rem;
      color: #808080;

      @include media-sp {
        font-size: 1.2rem;
      }

      span {
        font-size: 1.8rem;
        font-weight: 700;
        color: #3D3D3D;
        margin-right: 4px;

        @include media-sp {
          font-size: 1.6rem;
        }
      }
    }

    .my-header_organic_range {
      font-size: 1.4rem;
      color: #808080;

      @include media-sp {
        font-size: 1.2rem;
      }

      span {
        font-size: 1.8rem;
        font-weight: 700;
        color: #3D3D3D;

        @include media-sp {
          font-size: 1.6rem;
        }

        &:first-of-type {
          margin-right: 4px;
        }

        &:last-of-type {
          margin: 0 4px;
        }
      }
    }
  }
}

//---------- カスタム非対応　ここから ----------//
//検索結果
.mf_finder_organic_header_wrapper {
  margin: 0 0 24px;

  //0件のとき
  .mf_finder_organic_no_results {
    &::before {
      font-weight: 700;
    }
  }
}

//検索結果（全言語）
.mf_finder_organic_header_wrapper:lang(ja),
.mf_finder_organic_header_wrapper:lang(ja-JP),
.mf_finder_organic_header_wrapper:lang(kr),
.mf_finder_organic_header_wrapper:lang(tr),
.mf_finder_organic_header_wrapper:lang(zh),
.mf_finder_organic_header_wrapper:lang(zh-CN),
.mf_finder_organic_header_wrapper:lang(zh-tw) {
  .mf_finder_organic_header {
    flex-direction: column-reverse;
  }

  //0件以外の時
  .mf_finder_organic_nums {
    gap: 16px;

    .mf_finder_organic_total {
      font-size: 1.8rem;
      color: #3D3D3D;

      &::before,
      &::after {
        font-size: 1.4rem;
        color: #808080;

        @include media-sp {
          font-size: 1.2rem;
        }
      }

      &::before {
        margin: 0;
      }

      &::after {
        margin: 0 0 0 4px;
      }
    }

    .mf_finder_organic_range {

      .mf_finder_organic_range_from,
      .mf_finder_organic_range_to {
        font-size: 1.8rem;
        color: #3D3D3D;

        @include media-sp {
          font-size: 1.6rem;
        }

        &::before,
        &::after {
          font-size: 1.4rem;
          color: #808080;

          @include media-sp {
            font-size: 1.2rem;
          }
        }

        &::after {
          margin-left: 4px;
        }
      }

      .mf_finder_organic_range_from {
        margin-right: 4px;
      }
    }
  }
}

//検索結果（JP）
.mf_finder_organic_header_wrapper:lang(ja),
.mf_finder_organic_header_wrapper:lang(ja-JP) {

  //0件のとき
  .mf_finder_organic_no_results {
    &::before {
      content: "該当する検索結果が見つかりませんでした。";
    }
  }

  //0件以外の時

  .mf_finder_query {
    font-size: 1.8rem;
    margin: 0 0 16px;

    @include media-sp {
      margin-bottom: 12px;
    }

    &::before {
      display: none;
    }

    &::after {
      content: "の検索結果";
      font-size: 1.4rem;
      color: #727272;
    }

    span {
      color: #3D3D3D;

      &::before {
        content: "「";
      }

      &::after {
        content: "」";
      }
    }
  }
}

//---------- カスタム非対応　ここまで ----------//


//ロゴ
.mf_finder_organic .mf_finder_logo {
  margin: 40px 0 0;

  a {
    display: block;
    margin: 0 auto;
  }
}

.mf_finder_organic_docs {
  margin: 0;

  >div:first-child {
    border-top: 1px solid $navy_20;
  }

  .mf_finder_organic_doc {
    padding: 24px 0;
    border-bottom: 1px solid $navy_20;

    @include media-sp {
      padding: 16px 0;
    }

    .mf_finder_organic_doc_img_wrapper {
      width: 202px;
      margin-right: 24px;

      @include media-sp {
        width: 160px;
        margin-right: 16px;
      }

      .mf_finder_organic_doc_img {
        width: 100%;
        min-width: inherit;
        margin-bottom: 0;
        border: 1px solid $navy_20;
      }
    }

    .mf_finder_organic_doc_contents_wrapper {
      flex: 1;
    }

    .mf_finder_organic_doc_contents {}

    .mf_finder_organic_doc_title_wrapper {
      margin-bottom: 16px;

      @include media-sp {
        margin-bottom: 8px;
      }

      &::after {
        content: "\e90a";
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        color: $orange;
        margin: 0 0.25em;
      }

      &:visited {
        color: $base-font-color;
      }

      &:hover {
        text-decoration: none;

        >span {
          text-decoration: underline;
        }
      }
    }

    .mf_finder_organic_doc_body_wrapper {
      @include media-pc-tb {
        margin-bottom: 8px;
      }

      .mf_finder_organic_doc_body {
        font-size: 1.4rem;
        line-height: 1.8;
        color: $base-font-color;
        height: 5.04rem;

        @include media-sp {
          font-size: 1.2rem;
          height: 6.48rem;
        }
      }
    }

    .mf_finder_organic_doc_url_wrapper {

      .mf_finder_organic_doc_url {
        font-size: 1.2rem;
        color: $navy_60;
        display: inline;

        &::before,
        &::after {
          display: none;
        }

        >span {
          display: inline;
        }
      }
    }
  }
}

//ページネーション
.mf_finder_organic_pager_wrapper {
  margin-top: 32px;

  .mf_finder_pager_items {
    li {
      margin: 0 8px;

      @include media-sp {
        margin: 0 6px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      >a,
      >span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        padding: 0;

        @include media-sp {
          width: 32px;
          height: 32px;
        }
      }
    }

    .mf_finder_pager_item_first {
      display: none !important;
    }

    .mf_finder_pager_item_prev,
    .mf_finder_pager_item_next {
      a {
        border-radius: 100%;
        background: $gray_20;

        span {
          display: none;
        }

        &::before {
          font-family: 'icomoon';
          font-weight: normal;
          line-height: 1;
          color: $orange;
        }
      }
    }

    .mf_finder_pager_item_prev {
      margin-right: 16px;
      margin-left: 0;

      @include media-sp {
        margin-right: 10px;
      }

      a::before {
        content: "\e907";
      }
    }

    .mf_finder_pager_item_next {
      margin-left: 16px;
      margin-right: 0;

      @include media-sp {
        margin-left: 10px;
      }

      a::before {
        content: "\e906";
      }
    }

    .mf_finder_pager_item_before_pages,
    .mf_finder_pager_item_current,
    .mf_finder_pager_item_after_pages {
      padding: 0;
      border: 1px solid $navy_20;
      border-radius: 4px;
    }

    .mf_finder_pager_item_before_pages,
    .mf_finder_pager_item_after_pages {

      >a {
        background: $white;
        color: $base-font-color;

        @media (hover: hover) {
          &:hover {
            background: $orange;
            color: $white;
          }
        }
      }
    }

    .mf_finder_pager_item_current {
      background: $navy;
      color: $white;
    }
  }
}

.mf_zerohitText {
  display: none !important;
}

.mf_finder_organic_zerohit+.mf_zerohitText {
  display: block !important;
}
