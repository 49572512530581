@use '../base/setting' as *;

.c-btn-wrap {
  display: flex;
  justify-content: center;
  gap: 15px 20px;

  @include media-sp {
    gap: 15px;
  }

  &.-left {
    justify-content: flex-start;

    &.-column {
      align-items: flex-start;
    }
  }

  &.-right {
    justify-content: flex-end;

    &.-column {
      align-items: flex-end;
    }
  }

  &.-column {
    flex-direction: column;
    align-items: center;
  }

  @include media-pc-tb {
    &.-pc-left {
      justify-content: flex-start;

      &.-column {
        align-items: flex-start;
      }
    }

    &.-pc-right {
      justify-content: flex-end;

      &.-column {
        align-items: flex-end;
      }
    }
  }

  @include media-sp {
    &.-sp-column {
      flex-direction: column;
      align-items: center;
    }
  }
}

.c-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 340px;
  min-height: 56px;
  padding: 0 40px;
  border-radius: 100px;
  background: $white;
  border: 1px solid $navy_20;
  color: $base-font-color;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.4;
  position: relative;
  @include transition;

  &::after {
    content: "\e90a";
    font-family: 'icomoon';
    font-weight: normal;
    line-height: 1;
    @include centering(false, true);
    right: 16px;
    color: $orange;
  }

  &[target="_blank"]::after,
  &[href^="mailto:"]::after {
    content: "\e900";
  }

  &[href*=".pdf"]::after {
    content: "\e904";
  }

  &.js-modal-open::after {
    content: "\e911";
  }

  &.-small {
    max-width: 280px;
  }

  &.-large {
    max-width: 480px;
  }

  &.-full {
    width: 100%;
    max-width: inherit;
  }

  &.-variable {
    width: inherit;
    max-width: inherit;
  }

  &.-disabled,
  &:disabled {
    pointer-events: none;
    color: $navy_20;

    &::after {
      color: $navy_20;
    }
  }

  @include media-sp {
    font-size: 1.4rem;
    padding: 0 28px;

    &::after {
      right: 12px;
    }
  }

  @include media-sp-s {
    max-width: inherit;
    width: 100%;

    &.-small {
      width: 304px;
    }

    &.-large {
      max-width: inherit;
    }

    &.-variable {
      width: 100%;
    }
  }

  &.-square {
    max-width: inherit;
    height: 80px;
    border-radius: 16px;
    font-size: 1.4rem;

    &.-immutable {
      max-width: 364px;
    }

    @include media-sp {
      text-align: left;
      padding: 0 30px 0 10px;
      border-radius: 8px;
    }

    >span {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-sp {
        justify-content: flex-start;
      }

      >span:last-child {
        flex: 1;
      }
    }
  }

  &.-navy {
    &::after {
      color: $navy;
    }
  }

  &.-more {
    &::after {
      content: "\e902";
    }
  }

  &.-download {
    &::after {
      content: "\e919";
    }
  }

  &.-prev {
    &::after {
      content: "\e912";
      left: 16px;
      right: inherit;
    }
  }

  &.-strong {
    color: $white;
    background: $navy;
    border-color: $navy;

    &::after {
      color: $white;
    }
  }

  &.-transparent {
    color: $white;
    background: transparent;
    text-shadow: rgba($black, 0.7) 0 0 10px;
    border-color: $white;

    &::after {
      color: $white;
    }
  }

  &.-cart,
  &.-task,
  &.-contact {
    padding: 0 35px 0 50px;

    &::before {
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      font-size: 2.4rem;
      @include centering(false, true);
      left: 24px;
      color: $orange;
    }

    @include media-sp {
      padding: 0 28px 0 30px;

      &::before {
        font-size: 2rem;
        left: 12px;
      }
    }

    &.-strong,
    &.-transparent {
      &::before {
        color: $white;
      }
    }
  }

  &.-cart::before {
    content: "\e90f";
  }

  &.-task::before {
    content: "\e914";
  }

  &.-contact::before {
    content: "\e901";
  }

  &.-corporate,
  &.-consumer {
    &::before {
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      font-size: 2.4rem;
      @include centering(false, true);
      left: 24px;
      color: $navy;
    }

    @include media-sp {
      &::before {
        font-size: 2rem;
        left: 20px;
      }
    }

    &.-strong,
    &.-transparent {
      &::before {
        color: $white;
      }
    }
  }

  &.-corporate::before {
    content: "\e90c";
  }

  &.-consumer::before {
    content: "\e90b";
  }

  &.-num {
    >span {
      gap: 12px;

      @include media-sp {
        gap: 8px;
      }

      &::before {
        content: attr(data-num);
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        line-height: 1;
        background: #EE9B93;
        color: $white;

        @include media-sp {
          width: 16px;
          height: 16px;
          font-size: 1.2rem;
        }
      }
    }

    &.-first span::before {
      background: #C8AF5C;
    }

    &.-second span::before {
      background: #9A9DA8;
    }

    &.-third span::before {
      background: #BD9573;
    }
  }

  &.-icon {

    &::before {
      flex-shrink: 0;
      font-size: 2.4rem;
      font-family: 'icomoon';
      font-weight: normal;
      color: $navy;
      @include centering(false, true);
      left: 24px;
    }

    &.-company::before {
      content: "\e90e";
      color: $orange;
    }

    &.-consumer::before {
      content: "\e90b";
    }

    &.-corporate::before {
      content: "\e90c";
    }
  }

  @media (hover: hover) {
    &:hover {
      color: $white;
      background: $orange;
      border-color: $orange;
      text-shadow: none;

      &::before,
      &::after {
        color: $white !important;
      }

      span {

        &::before,
        &::after {
          color: $white;
        }
      }
    }
  }
}

.c-btn-zip {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 120px;
  height: 44px;
  padding: 0 12px 0 4px;
  border-radius: 100px;
  background: $white;
  border: 1px solid $navy_20;
  color: $base-font-color;
  font-size: 1.4rem;
  font-weight: 700;
  position: relative;
  @include transition;

  @include media-sp {
    width: 100px;
  }

  &::after {
    content: "\e90a";
    font-family: 'icomoon';
    font-weight: normal;
    line-height: 1;
    @include centering(false, true);
    right: 8px;
    color: $orange;
  }

  @media (hover: hover) {
    &:hover {
      color: $white;
      background: $orange;
      border-color: $orange;

      &::after {
        color: $white;
      }
    }
  }
}

.c-btn-bnr {
  display: block;
  width: 100%;
  border: 1px solid $navy_20;
  border-radius: 16px;
  overflow: hidden;

  @include media-sp {
    border-radius: 8px;
  }

  img {
    width: 100%;
  }

  &.-no-border {
    border: none;
  }

  @media (hover: hover) {

    &:hover {
      opacity: 0.7;
    }
  }
}

.c-btn-shop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 56px;
  padding: 12px 48px;
  background: $white;
  border: 1px solid $navy_20;
  border-radius: 8px;
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 700;
  position: relative;

  span {
    text-align: center;
  }

  @include media-sp {
    font-size: 1.3rem;
  }

  &::before,
  &::after {
    font-family: 'icomoon';
    font-weight: normal;
    line-height: 1;
    color: $orange;
    @include centering(false, true);
  }

  &::before {
    content: "\e90f";
    font-size: 2.2rem;
    left: 16px;
  }

  &::after {
    content: "\e900";
    font-size: 1.6rem;
    right: 16px;
  }

  @media (hover: hover) {
    &:hover {
      background: $orange;
      border-color: $orange;
      color: $white;

      &::before,
      &::after {
        color: $white;
      }
    }
  }
}
