@use '../base/setting' as *;

.field-museum-display {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 28px;

  &__item {
    width: calc(50% - 14px);
    background: $gray_20;
    padding: 24px;

    @include media-sp {
      width: 100%;
    }
  }

  &__ttl {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;

    >div {
      width: 80px;
    }

    >h4 {
      flex: 1;

      span {
        display: block;
        font-weight: 700;
        line-height: 1.5;

        &:first-child {
          font-size: 2rem;
          margin-bottom: 4px;
        }

        &:last-child {
          font-size: 1.4rem;
        }
      }
    }
  }
}

.field-museum-card {

  &__ttl {
    padding: 16px;
    background: $gray_20;
    font-weight: 700;
    line-height: 1.5;

    @include media-pc-tb {
      text-align: center;
    }
  }

  @include media-sp {
    display: flex;

    &__img {
      width: 160px;
    }

    &__ttl {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
}

.field-distribution-flow {
  &__item {
    display: flex;
    gap: 16px 28px;
    align-items: center;
    background: $gray_20;
    position: relative;
    padding: 30px;

    @include media-sp {
      flex-direction: column;
      padding: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 62px;

      &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 30px 45px 0 45px;
        border-color: $navy_20 transparent transparent transparent;
        @include centering(true, false);
        bottom: -46px;
      }
    }

    &.-last {
      background: $gray_40;
      border: 1px solid $navy_20;
      padding: 20px;
      font-size: 2.4rem;
      font-weight: 700;
      text-align: center;

      @include media-sp {
        font-size: 2.1rem;
        padding: 4px 16px;
      }
    }
  }

  &__inner {
    flex: 1;
  }

  &__ttl {
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: 16px;

    span {
      &:first-child {
        display: inline-block;
        background: $gray_80;
        font-size: 1.8rem;
        padding: 4px 12px;
        margin-right: 12px;
        vertical-align: bottom;

        @include media-sp {
          display: block;
          width: fit-content;
          margin-right: 0;
          margin-bottom: 8px;
          font-size: 1.6rem;
        }
      }

      &:last-child {
        font-size: 2.4rem;

        @include media-sp {
          font-size: 2.1rem;
        }
      }
    }
  }

  &__img {
    @include media-pc-tb {
      width: 40%;
    }
  }
}

.field-distribution-card {
  display: block;

  &__img {
    width: 100%;
    overflow: hidden;
    aspect-ratio: 180 / 116;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include transition;
    }
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 14px;
    text-align: center;
    background: $white;
  }

  &__ttl {
    width: 100%;
    font-weight: 700;
    line-height: 1.5;

    small {
      font-size: 1.6rem;
    }
  }

  @include media-sp {
    display: flex;

    &__img {
      max-width: 160px;
    }

    &__inner {
      flex: 1;
      text-align: left;
    }

    &__ttl {

      small {
        font-size: 1.2rem;
      }
    }
  }
}

a.field-distribution-card {
  .field-distribution-card__ttl::after {
    content: "\e90a";
    font-family: 'icomoon';
    font-weight: normal;
    line-height: 1;
    margin-left: 0.25em;
    color: $orange;
  }

  &[target="_blank"] .field-distribution-card__ttl::after {
    content: "\e900";
  }

  @media (hover: hover) {
    &:hover {
      .field-distribution-card__img {
        img {
          transform: scale(1.1, 1.1);
        }
      }

      .field-distribution-card__ttl span {
        text-decoration: underline;
      }
    }
  }
}