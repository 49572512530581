@charset "utf-8";

/* Base
----------------------------------------------------------- */
@use "base/destyle";
@use "base/setting";
@use "base/base";
@use "base/utility";
@use "base/fonts";

/* Layout
----------------------------------------------------------- */
@use "layout/footer";
@use "layout/gnav";
@use "layout/header";
@use "layout/localnav";
@use "layout/layout";
@use "layout/grid";
@use "layout/section";
@use "layout/block";
@use "layout/contact";

/* Component
----------------------------------------------------------- */
@use "component/heading";
@use "component/btn";
@use "component/box";
@use "component/card";
@use "component/table";
@use "component/slider";
@use "component/link";
@use "component/title";
@use "component/breadcrumb";
@use "component/modal";
@use "component/list";
@use "component/news" as component;
@use "component/pagelink";
@use "component/figure";
@use "component/accordion";
@use "component/tab";
@use "component/tag";
@use "component/form";
@use "component/stepbar";
@use "component/movie";
@use "component/map";
@use "component/calendar";
@use "component/dealer";
@use "component/getreader";
@use "component/report";
@use "component/cta";

/* Pages
----------------------------------------------------------- */
@use "pages/top";
@use "pages/search";
@use "pages/case-studies";
@use "pages/cs";
@use "pages/seminar";
@use "pages/resources";
@use "pages/solution";
@use "pages/office";
@use "pages/theme";
@use "pages/consulting";
@use "pages/field";
@use "pages/product";
@use "pages/column" as corporate-column;

@use "pages/special/xork";

@use "pages/company/top" as company;
@use "pages/company/ir";
@use "pages/company/about";
@use "pages/company/brand";
@use "pages/company/sustainability";
@use "pages/company/news" as company-news;

@use "pages/consumer/top" as consumer;
@use "pages/consumer/showroom" as consumer-showroom;
@use "pages/consumer/column" as consumer-column;
@use "pages/consumer/products" as consumer-products;
