@use '../base/setting' as *;

.c-movie {
  max-width: 750px;
  margin: 0 auto;

  &__thumb {
    display: block;
    position: relative;
    margin-bottom: 16px;
    overflow: hidden;

    img {
      width: 100%;
      filter: brightness(80%);
      @include transition;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 10%;
      min-width: 55px;
      min-height: 55px;
      padding-top: 10%;
      background: url(/common/img/icon_play01.svg) no-repeat center center / contain;
      border-radius: 100%;
      @include centering(true, true);
      z-index: 5;
      @include transition;

      @include media-sp {
        width: 15%;
        min-width: 40px;
        min-height: 40px;
        padding-top: 15%;
      }
    }

    &::after {
      background-image: url(/common/img/icon_play02.svg);
      opacity: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.-beige {
      &::before {
        background-image: url(/common/img/icon_play03.svg);
      }

      &::after {
        background-image: url(/common/img/icon_play04.svg);
      }
    }

    &.-round {
      border-radius: 16px;

      @include media-sp {
        border-radius: 8px;
      }
    }

    @media (hover: hover) {
      &:hover {
        img {
          transform: scale(1.1, 1.1);
        }

        &::after {
          opacity: 1;
        }
      }
    }
  }

  &__ttl {
    font-weight: 700;
    position: relative;
  }
}