@use '../../base/setting' as *;

.brand-mv {
  max-width: 1920px;
  margin: 0 auto;
  padding-bottom: 96px;
  position: relative;
  overflow: hidden;

  &__inner {
    @include media-pc-tb {
      height: 680px;
    }

    @include media-sp {
      height: 140vw;
      position: relative;
    }
  }

  &__mask {
    width: 100%;
    height: 100%;
    position: relative;
    mask: url(/company/brand/assets/img/img_mv_text-pc.png) no-repeat center center / contain;

    video {
      aspect-ratio: 16 / 9;
    }

    @include media-sp {
      mask-image: url(/company/brand/assets/img/img_mv_text-sp.png);

      video {
        height: 140vw;
      }
    }
  }

  &__chair01,
  &__chair02 {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 150px;
    aspect-ratio: 16 / 21;

    @media (max-width: 1600px) {
      width: 10vw;
    }

    @include media-sp {
      width: 13vw;
    }
  }

  &__chair01 {
    @include media-pc-tb {
      top: 230px;
      left: calc(50% - 390px);

      @media (max-width: 1600px) {
        top: calc(50% - 10.5vw);
        left: 26vw;
      }
    }

    @include media-sp {
      top: 41%;
      left: calc(50% - 3vw);
    }
  }

  &__chair02 {
    @include media-pc-tb {
      bottom: 30px;
      right: calc(50% - 385px);

      @media (max-width: 1600px) {
        bottom: calc(50% - 22.5vw);
        right: 25.5vw;
      }
    }

    @include media-sp {
      top: 59.5%;
      left: calc(50% - 2.5vw);
    }
  }

  &__img {
    width: 100%;
    aspect-ratio: 16 / 21;
    @include centering(true, true);
    animation: 20s 5s linear infinite;

    &:not(:first-child) {
      opacity: 0;
    }

    &.-item01 {
      animation-name: fadeItem01;
    }

    &.-item02 {
      animation-name: fadeItem02;
    }

    &.-item03 {
      animation-name: fadeItem03;
    }

    &.-item04 {
      animation-name: fadeItem04;
    }
  }

  &__slider {
    @include centering(true, true);
    z-index: 5;
    background: $white;

    .slick-slide {
      opacity: 1 !important;
    }

    .slick-first .brand-mv__slider-item {
      opacity: 1 !important;
      transition: opacity 0s !important;
    }

    .slick-active .brand-mv__slider-item {
      opacity: 1;
      transition: opacity ease 7.0s;
    }

    .slick-first .brand-mv__slider-bg,
    .slick-active .brand-mv__slider-bg {
      transform: scale(1.2, 1.2);
      transition: transform ease 15.0s;
    }

    .slick-continue .brand-mv__slider-item {
      opacity: 0;
      transition: opacity ease 7.0s;
    }

    .slick-continue .brand-mv__slider-bg {
      transform: scale(1.3, 1.3);
      transition: transform ease 15.0s;
    }

    &-item {
      height: 100%;
      position: relative;
      z-index: 10;
      overflow: hidden;
      vertical-align: bottom;
      opacity: 0;
      transition: opacity 0s;

      @include media-sp {
        height: 100%;
      }
    }

    &-bg {
      width: 100%;
      height: 100%;
      background: no-repeat center center / cover;
      z-index: 10;
      overflow: hidden;
      margin: 0;
      transform: scale(1, 1);
      transition: transform 0s;

      &.-bg01 {
        background-image: url(/company/brand/assets/img/img_mv_slide01.jpg);
      }

      &.-bg02 {
        background-image: url(/company/brand/assets/img/img_mv_slide02.jpg);
      }

      &.-bg03 {
        background-image: url(/company/brand/assets/img/img_mv_slide03.jpg);
      }

      &.-bg04 {
        background-image: url(/company/brand/assets/img/img_mv_slide04.jpg);
      }

      &.-bg05 {
        background-image: url(/company/brand/assets/img/img_mv_slide05.jpg);
      }

      &.-bg06 {
        background-image: url(/company/brand/assets/img/img_mv_slide06.jpg);
      }
    }
  }

  &__video01,
  &__video02 {
    position: absolute;
    transform: translate(-50%, -50%);

    div {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background: $navy;
      position: absolute;
      top: 16px;
      z-index: 4;

      @include media-sp {
        top: 8px;
      }
    }
  }

  &__video01 {
    width: 540px;
    height: auto;
    aspect-ratio: 122 / 59;

    @include media-pc-tb {
      top: 170px;
      left: calc(50% + 380px);

      @media (max-width: 1600px) {
        width: 33.7vw;
        top: calc(50% - 13vw);
        left: inherit;
        right: -8vw;
      }
    }

    @include media-sp {
      width: 50vw;
      top: 16%;
      right: -17%;
    }

    video {
      width: 100%;
      height: auto;
    }

    &::before {
      left: 16px;

      @include media-sp {
        left: 8px;
      }
    }
  }

  &__video02 {
    width: 300px;
    height: auto;
    aspect-ratio: 70 / 53;

    @include media-pc-tb {
      bottom: 10px;
      right: calc(50% + 200px);

      @media (max-width: 1600px) {
        width: 18.7vw;
        bottom: calc(50% - 23vw);
        right: inherit;
        left: 19vw;
      }
    }

    @include media-sp {
      width: 40vw;
      bottom: -7%;
      left: 26%;
    }

    video {
      width: auto;
      height: 100%;
    }

    &::before {
      right: 16px;

      @include media-sp {
        right: 8px;
      }
    }
  }

  &__scroll {
    font-size: 1.4rem;
    font-weight: 500;
    color: $orange;
    @include centering(true, false);
    bottom: 0;

    @include media-sp {
      font-size: 1.2rem;
    }

    span {
      display: block;

      &:first-child {
        margin-bottom: 5px;
      }

      &:last-child {
        margin: 0 auto;
        width: 1px;
        height: 53px;
        background: $orange_20;
        position: relative;

        &::after {
          content: "";
          width: 1px;
          height: 53px;
          opacity: 1;
          background: $orange;
          position: absolute;
          bottom: 0;
          left: 0;
          animation: brandScroll 2s ease infinite;
        }
      }
    }
  }
}

@keyframes fadeItem01 {
  0% {
    opacity: 1;
  }

  4% {
    opacity: 1;
  }

  6% {
    opacity: 0;
  }

  79% {
    opacity: 0;
  }

  81% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeItem02 {
  0% {
    opacity: 0;
  }

  4% {
    opacity: 0;
  }

  6% {
    opacity: 1;
  }

  29% {
    opacity: 1;
  }

  31% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeItem03 {
  0% {
    opacity: 0;
  }

  29% {
    opacity: 0;
  }

  31% {
    opacity: 1;
  }

  54% {
    opacity: 1;
  }

  56% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeItem04 {
  0% {
    opacity: 0;
  }

  54% {
    opacity: 0;
  }

  56% {
    opacity: 1;
  }

  79% {
    opacity: 1;
  }

  81% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes brandScroll {
  0% {
    opacity: 0;
    height: 53px;
  }

  10% {
    opacity: 1;
  }

  48% {
    height: 0;
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.brand-heading01 {
  margin: 0 -10px;

  >span {
    display: block;
    line-height: 1.5;
    text-align: center;

    &:first-child {
      color: $orange;
      font-size: 3rem;
      font-weight: 500;
      margin-bottom: 20px;

      @include media-sp {
        font-size: 1.8rem;
        margin-bottom: 8px;
      }
    }

    &:nth-child(2) {
      font-size: 4.4rem;
      font-weight: 700;
      margin-bottom: 5px;

      @include media-sp {
        font-size: 3rem;
      }
    }

    &:last-child {
      font-size: 1.8rem;
      font-weight: 500;
      filter: opacity(30%);

      @include media-sp {
        font-size: 1.6rem;
      }
    }
  }
}

.brand-heading02 {
  >span {
    display: block;
    line-height: 1.5;
    text-align: center;

    &:first-child {
      margin-bottom: 50px;

      @include media-sp {
        margin-bottom: 30px;
      }

      img {
        max-width: 100%;
      }
    }

    &:nth-child(2) {
      font-size: 2.2rem;
      font-weight: 700;
      color: $orange;
      margin-bottom: 5px;

      @include media-sp {
        font-size: 1.8rem;
      }
    }

    &:last-child {
      font-size: 4.4rem;
      font-weight: 700;

      @include media-sp {
        font-size: 3rem;
      }

      >span {

        &:first-child {
          margin: 0 -0.35em;
        }

        &:last-child {
          margin-right: -0.3em;
        }
      }
    }
  }

  @include media-sp {
    &.-about img:first-child {
      max-width: 252px;
    }

    &.-business img:first-child {
      max-width: 305px;
    }

    &.-sociery img:first-child {
      max-width: 310px;
    }
  }
}

.brand-loop {
  display: flex;
  align-items: center;
  overflow: hidden;

  &.-to-left .brand-loop__img {
    animation: loopToLeft infinite linear 60s both;

    li:nth-child(2n+1) {
      width: 1784px;

      @include media-sp {
        width: 1326px;
      }
    }
  }

  &.-to-right .brand-loop__img {
    animation: loopToRight infinite linear 60s both;

    li:nth-child(2n+1) {
      width: 2616px;

      @include media-sp {
        width: 1945px;
      }
    }
  }

  &__img {
    display: flex;
    align-items: center;

    li {
      margin: 0 30px;

      &:nth-child(2n) {
        width: 286px;
        height: 165px;
        position: relative;

        @include media-sp {
          width: 201px;
          height: 116px;
        }

        img {
          position: absolute;
          top: calc(50% - 82px);
          left: 0;

          @include media-sp {
            top: calc(50% - 58px);
          }

          &:first-child {
            opacity: 1;
          }

          &:last-child {
            opacity: 0;
          }
        }
      }
    }
  }


  &__anime {

    &.-omote {
      animation: rotate-omote 30s linear infinite;
    }

    &.-ura {
      animation: rotate-ura 30s linear infinite;
    }

    &.-delay-5 {
      animation-delay: 5s;
    }

    @include media-sp {
      &.-omote {
        animation: rotate-omote-sp 15s linear infinite;
      }

      &.-ura {
        animation: rotate-ura-sp 15s linear infinite;
      }
    }
  }
}

@keyframes loopToLeft {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes loopToRight {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.brand-about {

  &__main {
    line-height: 0;
    position: relative;
  }

  &__item {
    padding: 0 20px 68px;
    position: relative;

    @include media-sp {
      padding: 0 10px 68px;
    }

    >span {
      color: $white;
      display: block;
      line-height: 1.5;
      font-weight: 700;
      position: absolute;
      z-index: 10;
      white-space: nowrap;
      text-shadow: 0 0 8px rgba($black, 0.6);

      &:first-of-type {
        border: 2px solid $white;
        border-radius: 4px;
        font-size: 1.4rem;
        padding: 2px 10px;
        top: 12px;
        left: 35px;

        @include media-sp {
          top: 10px;
          left: 20px;
          font-size: 1.2rem;
        }
      }

      &:nth-of-type(2) {
        font-size: 4rem;
        bottom: 128px;
        left: 50%;
        transform: translateX(-50%);

        @include media-sp {
          bottom: 118px;
          font-size: 1.8rem;
        }
      }

      &:last-of-type {
        width: 100%;
        max-width: 420px;
        text-align: center;
        font-size: 1.8rem;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);

        @include media-sp {
          bottom: 80px;
          font-size: 1.2rem;
        }
      }
    }

    &::before {
      content: "";
      width: calc(100% - 40px);
      height: 1px;
      background: $navy_20;
      position: absolute;
      bottom: 14px;
      left: 20px;

      @include media-sp {
        width: 100vw;
        bottom: 32px;
      }
    }

    &::after {
      content: "";
      width: 19px;
      height: 19px;
      border-top: 1px solid $navy_20;
      border-right: 1px solid $navy_20;
      transform: rotate(45deg);
      position: absolute;
      bottom: 5px;
      right: 23px;

      @include media-sp {
        display: none;
      }
    }
  }

  &__prev,
  &__next {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: $gray_20;
    border-radius: 100%;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 62px);

    @include media-sp {
      width: 32px;
      height: 32px;
      top: calc(50% - 45px);
    }

    &::before {
      font-family: 'icomoon';
      font-weight: normal;
      font-size: 2.4rem;
      line-height: 1;
      color: $orange;

      @include media-sp {
        font-size: 1.4rem;
      }
    }

    &.slick-disabled {
      cursor: default;

      &::before {
        color: $orange_40;
      }
    }
  }

  &__prev {
    left: 23%;

    @include media-sp {
      left: 17%;
    }

    @include media-sp {
      left: 9%;
    }

    &::before {
      content: "\e907";
    }
  }

  &__next {
    right: 23%;

    @include media-sp {
      right: 17%;
    }

    @include media-sp {
      right: 9%;
    }

    &::before {
      content: "\e906";
    }
  }

  &__dots {
    margin: -68px auto 0;
    width: 48%;
    padding: 0 20px;

    .slick-list {
      overflow-y: visible;
    }

    @include media-sp {
      width: 64%;
    }

    @include media-sp {
      width: 80%;
    }
  }

  &__year {
    font-size: 1.8rem;
    font-weight: 500;
    color: $navy_60;
    text-align: center;
    position: relative;
    padding-top: 64px;
    cursor: pointer;

    @include media-sp {
      padding-top: 46px;
      font-size: 1.1rem;
    }

    >span {
      display: block;
      width: 9px;
      height: 9px;
      border-radius: 100%;
      background: $navy_20;
      position: absolute;
      top: 49px;
      left: 50%;
      transform: translateX(-50%);

      @include media-sp {
        top: 31px;
      }
    }

    &::before {
      content: "";
    }

    .slick-current & {
      color: $orange;

      >span {
        width: 14px;
        height: 14px;
        background: $orange;
        top: 46px;

        @include media-sp {
          top: 28px;
        }

        &::before {
          content: "";
          display: block;
          width: 22px;
          height: 22px;
          border-radius: 100%;
          border: 1px solid $orange;
          position: absolute;
          top: -4px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &::after {
        content: "";
        display: block;
        width: 2px;
        height: 42px;
        background: $orange;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        @include media-sp {
          height: 24px;
        }
      }
    }
  }
}

.brand-logo {

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100% !important;
    height: 50dvh !important;
    min-height: 580px;

    @include media-sp {
      min-height: 480px;
    }

    video {
      opacity: 0;
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__block {
    opacity: 0;
    position: absolute;
    max-width: 780px;
    margin-inline: auto;
    padding: 20px;
    color: $white;
  }

  &__img {
    text-align: center;
    margin-bottom: 48px;

    img {
      width: 272px;
    }

    @include media-sp {
      margin-bottom: 32px;

      img {
        width: 225px;
      }
    }
  }

  &__ttl {
    font-size: 3.4rem;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 32px;

    @include media-sp {
      font-size: 2.6rem;
      margin-bottom: 20px;
    }
  }

  &__txt {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2;

    @include media-sp {
      font-size: 1.4rem;
    }
  }
}

.brand-logo2 {
  position: relative;

  &__layer1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    height: 50dvh !important;
    min-height: 580px;
    background: $white;
    opacity: 1;
    transition: opacity 1s;

    img {
      width: 272px;
    }

    @include media-sp {
      min-height: 480px;

      img {
        width: 225px;
      }
    }
  }

  &__layer2 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100% !important;
    height: 50dvh !important;
    min-height: 580px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s;

    @include media-sp {
      min-height: 480px;
    }

    video {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    svg {
      mix-blend-mode: color-dodge;
    }
  }

  &__block {
    position: absolute;
    max-width: 780px;
    margin-inline: auto;
    padding: 20px;
    color: $white;
  }

  &__img {
    text-align: center;
    margin-bottom: 48px;

    img {
      width: 272px;
    }

    @include media-sp {
      margin-bottom: 32px;

      img {
        width: 225px;
      }
    }
  }

  &__ttl {
    font-size: 3.4rem;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 32px;

    @include media-sp {
      font-size: 2.6rem;
      margin-bottom: 20px;
    }
  }

  &__txt {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2;

    @include media-sp {
      font-size: 1.4rem;
    }
  }

  &.-animate {
    .brand-logo2__layer1 {
      opacity: 0;
    }

    .brand-logo2__layer2 {
      opacity: 1;
    }
  }
}

.brand-business {
  position: relative;

  &__list {

    @include media-pc-tb {
      display: flex;
      min-height: 550px;

      li {
        width: 50%;
      }
    }
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    color: $white;
    background: no-repeat center center / cover;
    padding: 50px;
    position: relative;
    cursor: pointer;

    p {
      font-weight: 700;
      position: relative;
      z-index: 5;

      @include media-pc-tb {
        text-align: center;
      }
    }

    &.-bg01 {
      background-image: url(/company/brand/assets/img/bg_business0101-pc.jpg);
    }

    &.-bg02 {
      background-image: url(/company/brand/assets/img/bg_business0201-pc.jpg);
    }

    @include media-sp {
      padding: 50px 20px;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: no-repeat center center / cover;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        @include transition;
      }

      &.-bg01 {
        background-image: url(/company/brand/assets/img/bg_business0102-sp.jpg);

        &::before {
          background-image: url(/company/brand/assets/img/bg_business0101-sp.jpg);
        }
      }

      &.-bg02 {
        background-image: url(/company/brand/assets/img/bg_business0202-sp.jpg);

        &::before {
          background-image: url(/company/brand/assets/img/bg_business0201-sp.jpg);
        }
      }
    }

    &.-active::before {
      opacity: 0;
    }
  }

  &__sp {
    display: none;

    @include media-pc-tb {
      display: none !important;
    }
  }

  &__pc {
    display: none;
    color: $white;
    width: 100%;
    height: 100%;
    background: no-repeat center center / cover;
    padding: 50px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    @include media-sp {
      display: none !important;
    }

    &.-bg01 {
      background-image: url(/company/brand/assets/img/bg_business0102-pc.jpg);
    }

    &.-bg02 {
      background-image: url(/company/brand/assets/img/bg_business0202-pc.jpg);
    }

    .brand-business__inner p {
      width: 100%;
      text-align: left;
    }
  }

  &__inner {
    max-width: $narrow-content-width;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-weight: 700;
    }
  }

  &__ttl {
    font-size: 3.4rem;
    line-height: 1.5;
    font-weight: 700;
    text-align: center;
    margin-bottom: 16px;

    @include media-sp {
      font-size: 2.2rem;
    }

    >span {
      display: inline-block;
      padding-right: 66px;
      position: relative;

      @include media-sp {
        padding-right: 38px;
      }

      &::before {
        content: "";
        width: 46px;
        height: 46px;
        border: 1px solid $gray_40;
        background: $white;
        border-radius: 100%;
        @include centering(false, true);
        right: 0;

        @include media-sp {
          width: 30px;
          height: 30px;
        }
      }

      &::after {
        content: "\e918";
        font-family: 'icomoon';
        font-weight: normal;
        font-size: 3rem;
        line-height: 1;
        color: $orange;
        @include centering(false, true);
        right: 8px;

        @include media-sp {
          font-size: 2rem;
          right: 5px;
        }
      }
    }

    .brand-business__pc & span::after {
      content: "\e917";
    }

    @include media-sp {
      .brand-business__item.-active & span::after {
        content: "\e917";
      }
    }
  }
}

.brand-sociery {
  position: relative;

  @include media-pc-tb {
    padding-right: 50%;
  }

  &__item {
    border-top: 1px solid $navy_20;
    @include transition;

    &:last-child {
      border-bottom: 1px solid $navy_20;
    }

    figure {

      @include media-pc-tb {
        margin-top: 20px;
        position: absolute;
        top: 0;
        right: 0;
        width: calc(50% - 60px);
        opacity: 0;
        transition: opacity 0.3s;
        pointer-events: none;
      }

      @include media-sp {
        width: calc(100% - 8px);
        margin-top: 32px;
        position: relative;
      }

      img {
        position: relative;
        z-index: 10;
      }

      &::before {
        content: "";
        width: 100%;
        height: calc(100% + 40px);
        background: $navy;
        position: absolute;
        top: -20px;
        right: -20px;
        z-index: 9;

        @include media-sp {
          height: calc(100% + 16px);
          top: -8px;
          right: -8px;
        }
      }
    }

    >div {
      position: relative;

      >span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        border: 1px solid $gray_40;
        background: $white;
        border-radius: 100%;
        pointer-events: none;

        @include media-pc-tb {
          @include centering(false, true);
          right: 0;
        }

        @include media-sp {
          width: 30px;
          height: 30px;
          position: absolute;
          top: 25px;
          right: 20px;
        }

        &::after {
          content: "\e918";
          font-family: 'icomoon';
          font-weight: normal;
          font-size: 3rem;
          line-height: 1;
          color: $orange;

          @include media-sp {
            font-size: 2rem;
          }
        }
      }
    }

    &.-active {

      .brand-sociery__ttl {
        cursor: default;
      }

      figure {
        opacity: 1;
      }

      >div>span::after {
        content: "\e917";
      }
    }

    @media (hover: hover) {
      &:not(.-active):hover {
        background: #F6F8F9;
      }
    }
  }

  &__ttl {
    font-size: 3.2rem;
    font-weight: 700;
    padding: 40px 50px 40px 0;
    cursor: pointer;

    @include media-sp {
      font-size: 2.2rem;
      padding: 20px 40px 20px 20px;

    }
  }

  &__txt {
    display: none;
    margin-top: -30px;
    padding: 0 50px 40px 0;

    @include media-sp {
      margin-top: -10px;
      padding: 0 20px 32px;
    }

    p {
      font-size: 1.8rem;

      @include media-sp {
        font-size: 1.4rem;
      }
    }
  }
}

.brand-title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 29vw;
  padding: 0 48px;
  position: relative;

  @include media-sp {
    padding: 0 20px;
    height: 53vw;
  }

  &__inner {
    z-index: 2;

    @include media-pc-tb {
      width: 100%;
      max-width: $narrow-content-width;
      margin: 0 auto;
      position: relative;
    }

    @include media-sp {
      position: absolute;
      left: 15px;
      bottom: -27px;
    }
  }

  &__heading {
    h1 {
      color: $white;
      font-size: 4rem;
      font-weight: 700;

      span {
        padding: 5px 15px;
        background: #888;
      }

      @include media-sp {
        font-size: 3rem;
      }
    }

    &.-yellow h1 span {
      background: linear-gradient(to right, #FAC03D, #F39F80);
    }

    &.-green h1 span {
      background: linear-gradient(to right, #6ACBCC, #9FC949);
    }

    &.-blue h1 span {
      background: linear-gradient(to right, #5F6DC3, #76CBC4);
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include media-sp {
      width: 100%;
    }
  }
}

.c-heading {
  &.-yellow {
    color: #E39300;
  }

  &.-green {
    color: #2A9B9D;
  }

  &.-blue {
    color: #606EBF;
  }
}

.brand-profile {
  display: grid;
  grid-template-columns: 254px auto;
  grid-template-rows: max-content max-content 1fr;
  gap: 0 30px;

  @include media-sp {
    grid-template-columns: 139px auto;
    grid-template-rows: max-content 1fr max-content;
    gap: 0 15px;

  }

  &+& {
    margin-top: 45px;

    @include media-sp {
      margin-top: 30px;
    }
  }

  &__img {
    grid-area: 1 / 1 / 4 / 2;

    @include media-sp {
      grid-area: 1 / 1 / 3 / 2;
    }
  }

  &__name {
    grid-area: 1 / 2 / 2 / 3;
    margin-bottom: 8px;
    font-size: 1.8rem;
    font-weight: 700;

    @include media-sp {
      font-size: 1.6rem;
      grid-area: 1 / 2 / 2 / 3;
    }
  }

  &__post {
    grid-area: 2 / 2 / 3 / 3;
    font-size: 1.4rem;
    color: $navy_80;

    @include media-sp {
      font-size: 1.2rem;
      grid-area: 2 / 2 / 3 / 3;
    }
  }

  &__txt {
    grid-area: 3 / 2 / 4 / 3;
    margin-top: 16px;

    @include media-sp {
      grid-area: 3 / 1 / 4 / 3;
    }
  }
}

.brand-interview {
  &__item {
    @include media-pc-tb {
      display: flex;
      gap: 20px;
    }

    &+& {
      margin-top: 35px;

      @include media-sp {
        margin-top: 25px;
      }
    }

    @include media-pc-tb {
      dt {
        width: 78px;
      }

      dd {
        flex: 1;
      }
    }

    @include media-sp {
      dt {
        margin-bottom: 5px;
      }
    }
  }
}

.brand-cta {
  @include media-pc-tb {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }

  @include media-sp {
    padding: 0 16px;
  }

  &__block {
    background: no-repeat center center / cover;
    width: calc(50% - 10px);
    padding: 110px 20px;

    @include media-sp {
      width: 100%;
      padding: 50px 20px;

      &:first-child {
        margin-bottom: 16px;
      }
    }

    &.-corporate {
      background-image: url(/company/brand/assets/img/bg_cta01-pc.jpg);

      @include media-sp {
        background-image: url(/company/brand/assets/img/bg_cta01-sp.jpg);
      }
    }

    &.-consumer {
      background-image: url(/company/brand/assets/img/bg_cta02-sp.jpg);

      @include media-sp {
        background-image: url(/company/brand/assets/img/bg_cta02-sp.jpg);
      }
    }
  }

  &__logo {
    width: 161px;
    text-align: center;
    margin: 0 auto 50px;

    @include media-sp {
      width: 114px;
      margin-bottom: 24px;
    }

    img {
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }

    span {
      display: block;
      font-size: 1.9rem;
      letter-spacing: 0.2em;
      font-weight: 400;
      color: $white;

      @include media-sp {
        font-size: 1.3rem;
      }
    }
  }

  &__btn {
    .c-btn {
      background: rgba($white, 0.9);

      @media (hover: hover) {
        &:hover {
          background: $orange;
        }
      }
    }
  }
}