@use '../base/setting' as *;

.product-line {
  border: 0;
  border-top: 1px solid $navy_20;
}

.product-catalog-list {
  display: flex;
  gap: 32px 28px;
}

.product-catalog-item {
  display: grid;
  gap: 0 28px;
  padding-bottom: 32px;
  border-bottom: 1px solid $gray_60;
  height: 100%;

  @include media-pc-tb {
    grid-template-columns: 32% 1fr;
    grid-template-rows: auto 1fr;

    &.-vertical {
      grid-template-columns: 32% 1fr;
    }

    &.-horizontal {
      grid-template-columns: 45% 1fr;
    }
  }

  @include media-sp {
    padding-bottom: 24px;
  }

  &__img {
    grid-area: 1 / 1 / 3 / 2;

    @include media-sp {
      grid-area: 2 / 1 / 3 / 2;
      text-align: center;
      margin-bottom: 16px;

      .product-catalog-item.-vertical & img {
        width: 144px;
      }

      .product-catalog-item.-horizontal & img {
        width: 205px;
      }
    }
  }

  &__ttl {
    grid-area: 1 / 2 / 2 / 3;
    margin-bottom: 40px;
    line-height: 1.5;

    @include media-sp {
      grid-area: 1 / 1 / 2 / 2;
      margin-bottom: 16px;
    }

    >div {
      &:first-child {
        font-size: 1.6rem;
        margin-bottom: 10px;

        @include media-sp {
          margin-bottom: 5px;
        }
      }

      &:last-child {
        font-size: 2.1rem;
        font-weight: 700;

        @include media-sp {
          font-size: 1.8rem;
        }
      }
    }
  }

  &__btns {
    grid-area: 2 / 2 / 3 / 3;

    @include media-sp {
      grid-area: 3 / 1 / 4 / 2;
    }
  }

  &__link {
    font-size: 1.4rem;
    margin-top: 16px;

    @include media-sp {
      text-align: center;
    }
  }
}