@use '../base/setting' as *;

.theme-ict-about {
  background: $gray_20;
  padding: 40px;

  @include media-sp {
    padding: 20px 16px;
  }

  &__multiply {
    display: flex;
    justify-content: center;
    gap: 160px;
    position: relative;
    margin-bottom: 74px;

    @include media-sp {
      gap: 60px;
      margin-bottom: 50px;
    }

    >div:not([class]) {
      width: 100px;
      height: 100px;
      @include centering(true, true);

      @include media-sp {
        width: 50px;
        height: 50px;
      }

      &::before,
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background: $navy_80;
        @include centering(true, true);
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    &::after {
      content: "";
      display: block;
      background: $gray;
      width: 163px;
      height: 26px;
      clip-path: polygon(0 0, 100% 0, 50% 100%);
      @include centering(true, false);
      bottom: -50px;

      @include media-sp {
        width: 75px;
        height: 12px;
        bottom: -30px;
      }
    }
  }

  &__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 260px;
    height: 160px;
    background: $white;
    padding: 20px;

    >* {
      display: block;
    }

    img {
      width: 64px;
    }

    span {
      font-weight: 700;
      line-height: 1.5;
      text-align: center;
    }

    @include media-sp {
      width: 100%;
      max-width: 120px;
      height: 115px;
      gap: 8px;
      padding: 10px;

      img {
        width: 40px;
      }

      span {
        font-size: 1.2rem;
      }
    }
  }

  &__txt {
    font-weight: 700;

    @include media-pc-tb {
      text-align: center;
    }

    >span {

      &:first-child {
        font-size: 2.4rem;

        @include media-sp {
          font-size: 1.9rem;
          line-height: 1.5;
        }
      }

      &:last-child {
        font-size: 2.8rem;
        line-height: 2;
        padding-bottom: 5px;
        background: linear-gradient($orange, $orange) 0 100%/100% 2px no-repeat;

        @include media-sp {
          font-size: 2.4rem;
        }
      }

    }
  }
}

.theme-ict-example {
  display: flex;
  gap: 16px 28px;

  @include media-sp {
    flex-direction: column;

    >div {

      &:first-child {
        order: 2;
      }

      &:nth-child(2) {
        order: 1;
      }

      &:last-child {
        order: 3;
      }
    }
  }

  >div:not([class]) {
    flex: 1;
  }

  &__item {

    &+& {
      margin-top: 16px;
    }

    dd,
    dt {
      padding-left: 2em;
    }

    dt {
      font-size: 1.6rem;
      line-height: 1.5;
      margin-bottom: 2px;
      position: relative;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 24px;
        height: 24px;
        background: $navy;
        border-radius: 100%;
        color: $white;
        font-size: 1.3rem;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    dd {
      font-size: 1.4rem;
    }
  }

  &__img {
    width: 50%;

    @include media-sp {
      width: 100%;
    }
  }
}

.theme-ict-flow {
  &__item {
    border: 1px solid $navy_20;

    dt {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: $gray_40;
      font-weight: 700;
      line-height: 1.25;
      padding: 24px 20px;
    }

    dd {
      background: $gray_20;
    }

    &.-dt-wide {
      dt {
        width: 100%;
      }

      dd {
        display: none;
      }
    }

    &.-dt-gray dt {
      background: $gray_20;
    }

    @include media-pc-tb {
      display: flex;

      dt {
        font-size: 2.2rem;
        width: 195px;
      }

      dd {
        flex: 1;
        border-left: 1px solid $navy_20;
        padding: 24px;
      }
    }

    @include media-sp {
      dt {
        font-size: 2.1rem;
        padding: 10px 20px;
      }

      dd {
        border-top: 1px solid $navy_20;
        padding: 20px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 62px;
      position: relative;

      &::after {
        content: "";
        display: block;
        background: $gray;
        width: 90px;
        height: 30px;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
        @include centering(true, false);
        bottom: -46px;
      }
    }
  }
}

.theme-ict-points {
  @include media-pc-tb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px 28px;
  }

  &__item {
    padding: 24px 24px 30px;
    border: 1px solid $navy_20;

    @include media-pc-tb {
      width: calc((100% - 56px)/ 3);
    }

    @include media-sp {
      padding: 20px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &__ttl {
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.5;
    color: $navy;
    text-align: center;
    margin-bottom: 16px;

    @include media-sp {
      font-size: 2.1rem;
    }
  }
}

.theme-paper-problem {
  background: $gray_20;
  padding: 40px;

  @include media-sp {
    padding: 24px 16px;
  }

  &__lead {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.5;
    margin-top: 32px !important;

    @include media-sp {
      font-size: 2.4rem;
    }
  }
}

.theme-paper-flow {
  &__item {
    background: $gray_20;
    padding: 30px;
    position: relative;

    @include media-pc-tb {
      display: flex;
      flex-wrap: wrap;
    }

    @include media-sp {
      padding: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 62px;
      position: relative;

      &::after {
        content: "";
        display: block;
        background: $gray;
        width: 90px;
        height: 30px;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
        @include centering(true, false);
        bottom: -46px;
      }
    }
  }

  &__ttl {
    width: 100%;
    font-weight: 700;
    padding-bottom: 16px;
    border-bottom: 1px solid $navy_20;
    margin-bottom: 24px;

    @include media-pc-tb {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    @include media-sp {
      padding-bottom: 8px;
      margin-bottom: 16px;
    }

    span {
      display: block;

      &:first-child {
        background: $gray_80;
        font-size: 1.8rem;
        padding: 2px 12px;

        @include media-sp {
          font-size: 1.6rem;
          width: fit-content;
          margin-bottom: 4px;
        }
      }

      &:last-child {
        font-size: 2.4rem;

        @include media-sp {
          font-size: 1.9rem;
        }
      }
    }
  }

  &__img {
    @include media-pc-tb {
      width: 40%;
      margin-right: 3%;
    }

    @include media-sp {
      margin-bottom: 16px;
    }
  }

  &__txt {
    flex: 1;

    p:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }

  &__check {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5;
    padding-left: 32px;
    margin-bottom: 16px;
    position: relative;

    @include media-sp {
      font-size: 1.8rem;
      margin-bottom: 12px;
    }

    &::before {
      content: "";
      display: block;
      width: 28px;
      height: 28px;
      background: url(/solution/assets/img/icon_check.svg) no-repeat center center / contain;
      position: absolute;
      top: 0.1em;
      left: 0;

      @include media-sp {
        width: 26px;
        height: 26px;
      }
    }
  }
}

.theme-paper-features {
  display: flex;
  gap: 24px 28px;

  @include media-sp {
    flex-direction: column;
  }

  &__item {
    flex: 1;
    background: $white;
    padding: 40px;

    @include media-sp {
      padding: 30px 16px;
    }
  }

  &__img {
    margin-bottom: 24px;
  }

  &__ttl {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 20px;

    img {
      width: 46px;
    }

    span {
      flex: 1;
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 1.5;
    }

    @include media-sp {
      gap: 18px;

      img {
        width: 40px;
      }

      span {
        font-size: 1.9rem;
      }
    }
  }

  &__list {
    >li {
      padding-left: 1.25em;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 4px;
        height: 4px;
        background: $navy;
        border-radius: 100%;
        position: absolute;
        top: 1em;
        left: 4px;
      }

      &:not(:last-child) {
        margin-bottom: 0.5em;
      }

      span {
        font-size: 2rem;
        font-weight: 700;
        padding-bottom: 3px;
        background: linear-gradient($orange, $orange) 0 100%/100% 1px no-repeat;

        @include media-sp {
          font-size: 1.8rem;
        }
      }
    }
  }
}

.theme-health-support-link {
  display: flex;
  justify-content: center;
  gap: 104px;

  @include media-sp {
    gap: 32px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    line-height: 1.5;

    span {
      &:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 100%;
        background: $navy;
        font-size: 2rem;
        font-weight: 700;
        color: $white;
        @include centering(true, false);
        top: 0;

        @include media-sp {
          width: 40px;
          height: 40px;
          font-size: 1.6rem;
        }
      }

      &:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 104px;
        height: 104px;
        border-radius: 100%;
        background: $gray_20;
        margin: 24px 0 16px;

        @include media-sp {
          width: 80px;
          height: 80px;
          padding-top: 5px;
        }
      }

      &:last-child {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        text-align: center;
        position: relative;
        padding-bottom: 1.25em;

        @include media-sp {
          font-size: 1.2rem;
        }

        &::after {
          content: "\e902";
          font-family: "icomoon";
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 1;
          color: $orange;
          @include centering(true, false);
          bottom: 0;
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        span:last-child {
          text-decoration: underline;
        }
      }
    }
  }
}

.theme-health-support-item {
  border: 1px solid $navy_20;

  &+& {
    margin-top: 32px;
  }

  &__heading {
    display: flex;
    border-bottom: 1px solid $navy_20;

    >div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      background: $gray_60;
      border-right: 1px solid $navy_20;

      img {
        width: 40px;
        height: 40px;
      }

      @include media-sp {
        width: 40px;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    >h3 {
      flex: 1;
      background: $gray_20;
      font-size: 2.2rem;
      font-weight: 700;
      line-height: 1.5;
      padding: 16px 24px;

      @include media-sp {
        font-size: 2.1rem;
        padding: 16px;
      }

      >span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: $navy;
        font-size: 2rem;
        font-weight: 700;
        color: $white;

        @include media-sp {
          margin-right: 4px;
          width: 24px;
          height: 24px;
          font-size: 1.2rem;
          position: relative;
          top: -3px;
        }
      }
    }
  }

  &__contents {
    padding: 30px 40px 40px;

    @include media-sp {
      padding: 24px 16px;
    }
  }
}

.theme-health-effect {
  height: 100%;
  background: $gray_20;
  padding: 32px;

  &.-white {
    background: $white;
  }

  @include media-sp {
    padding: 24px 16px;
  }

  &__ttl {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 24px;
    text-align: center;

    @include media-sp {
      font-size: 1.8rem;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background: $white;
    border-radius: 100%;
    margin: 0 auto 24px;
  }
}

.theme-security-card {
  display: block;

  &__img {
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      @include transition;
    }
  }

  &__ttl {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    background: $navy;
    color: $white;
    font-size: 1.8rem;
    font-weight: 700;

    @include media-sp {
      font-size: 1.6rem;
    }
  }
}

a.theme-security-card {
  .theme-security-card__ttl::after {
    content: "\e90a";
    font-family: 'icomoon';
    font-weight: normal;
    line-height: 1;
    margin-left: 0.25em;
  }

  &[target="_blank"] .theme-security-card__ttl::after {
    content: "\e900";
  }

  @media (hover: hover) {
    &:hover {
      .theme-security-card__img {
        img {
          transform: scale(1.1, 1.1);
        }
      }

      .theme-security-card__ttl span {
        text-decoration: underline;
      }
    }
  }
}

.theme-security-list {
  font-size: 1.4rem;

  li {
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    display: flex;
    gap: 8px;

    span {

      &:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background: $navy;
        color: $white;
        font-size: 1.3rem;
        font-weight: 700;
        border-radius: 100%;
      }

      &:last-child {
        flex: 1;
        display: block;
      }
    }
  }
}