@use '../../base/setting' as *;

.ir-info {
  padding: 20px;
  background: $white;
  border: 1px solid $navy_20;

  @include media-pc-tb {
    width: 352px;
    @include centering(false, true);
    right: 0;
  }

  @include media-sp {
    margin: 30px 20px 40px;
  }

  &__heading {
    font-size: 1.8rem;
    font-weight: 700;
    border-bottom: 1px solid $navy_20;
    padding-bottom: 10px;
    margin-bottom: 15px;

    @include media-sp {
      font-size: 1.6rem;
    }
  }
}

.ir-menu {
  position: relative;
  padding-bottom: 40px;

  @include media-pc-tb {
    display: flex;
    justify-content: space-between;
  }

  @include media-sp {
    padding-bottom: 30px;
  }

  &::before {
    content: "";
    width: 100vw;
    height: calc(100% - 40px);
    background: #F2F5F7;
    position: absolute;
    bottom: 0;
    left: 80px;

    @include media-sp {
      left: 20px;
      height: calc(100% - 20px);
    }
  }

  >div {
    position: relative;
    z-index: 5;
  }

  &__img {
    width: calc(50% - 40px);

    @include media-sp {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  &__links {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-sp {
      width: 100%;
      gap: 15px;
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100px;
    background: $white;
    border: 1px solid $navy_20;
    border-radius: 16px;
    position: relative;
    padding: 10px 40px 10px 30px;
    @include transition;

    @include media-sp {
      height: 80px;
      border-radius: 8px;
      padding-left: 15px;
    }

    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-weight: normal;
      font-size: 2rem;
      line-height: 1;
      @include centering(false, true);
      right: 20px;
      color: $orange;

      @include media-sp {
        right: 15px;
      }
    }

    &[target="_blank"]::after {
      content: "\e900";
    }

    &[href*=".pdf"]::after {
      content: "\e904";
    }

    &.-download::after {
      content: "\e919";
    }

    >span {
      &:first-child {
        font-size: 1.8rem;
        font-weight: 700;

        @include media-sp {
          font-size: 1.4rem;
        }
      }

      &:last-child {
        @include media-sp {
          font-size: 1.2rem;
        }
      }
    }

    &.-img {
      >span {
        &:last-child {
          position: relative;
          padding-left: 156px;

          @include media-sp {
            padding-left: 144px;
          }

          &::before,
          &::after {
            content: "";
            display: block;
            width: 156px;
            height: 1.8em;
            background: no-repeat center center / contain;
            @include centering(false, true);
            left: 0;
            @include transition;

            @include media-sp {
              width: 144px;
            }
          }

          &::before {
            background-image: url(/company/ir/assets/img/txt_riseitoki2023.png);
            opacity: 1;
          }

          &::after {
            background-image: url(/company/ir/assets/img/txt_riseitoki2023-hover.png);
            opacity: 0;
          }
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        border-color: $orange;
        background: $orange;
        color: $white;

        &::after {
          color: $white;
        }
      }

      &.-img:hover {
        >span:last-child {
          &::before {
            opacity: 0;
          }

          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.ir-contents {
  display: flex;
  flex-wrap: wrap;
  margin: -48px -4px 0 0;

  @include media-sp {
    margin: -36px 0 0 0;
  }

  &__item {
    width: calc(33.333% - 4px);
    margin: 48px 4px 0 0;

    @include media-sp {
      width: 100%;
      margin: 36px 0 0 0;
    }
  }
}

.ir-about {
  background: url(/company/ir/assets/img/bg_about-pc.jpg) no-repeat center center / cover;
  padding-top: 80px;
  padding-bottom: 80px;
  color: $white;
  font-weight: 500;

  @include media-sp {
    background-image: url(/company/ir/assets/img/bg_about-sp.jpg);
    padding-top: 48px;
    padding-bottom: 56px;
  }
}

.ir-figure {
  border: 16px solid #F2F5F7;
  background: $white;
  padding: 35px;

  @include media-sp {
    border-width: 10px;
    padding: 20px 20px 30px;
  }

  &__heading {
    display: flex;
    align-items: center;
    gap: 32px;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray_60;
    margin-bottom: 35px;

    @include media-sp {
      gap: 20px;
    }

    img {
      width: 32px;
      height: 40px;
    }

    >span {
      display: flex;
      align-items: center;
      gap: 0 10px;
      font-size: 2.4rem;
      font-weight: 700;

      >span {
        font-size: 1.4rem;
      }

      @include media-sp {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-size: 2.1rem;

        >span {
          font-size: 1.2rem;
        }
      }
    }
  }

  &+& {
    border-top: 0;
  }

  @media (min-width: 768px) {
    img {
      &.pc\:view {
        display: block !important;
      }

      &.sp\:view {
        display: none !important;
      }
    }
  }

  @include media-sp-s {
    img {
      &.pc\:view {
        display: none !important;
      }

      &.sp\:view {
        display: block !important;
      }
    }
  }
}

html[lang=en] .ir_controllBox {
  .eir_controller_year::before {
    content: "Year:";
  }

  .eir_controller_newstype::before {
    content: "Category:";
  }
}