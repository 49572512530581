@use '../../base/setting' as *;

.consumer-products-tab {

  @include media-sp {
    margin: 0 -20px;
  }

  &__index {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__contents {
    background: $beige_40;
    margin: 0 calc(50% - 50vw);
    padding: 0 calc(50vw - 50%) 24px;

    @include media-sp {
      padding: 0 20px 16px;
    }
  }

  &__contents-inner {
    max-width: 1520px;
    margin: 0 auto;
    padding: 60px 0 80px;

    @include media-sp {
      padding: 30px 0 60px;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 2rem;
    min-height: 60px;
    padding: 12px 4px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    @include media-pc-tb {
      flex: 1;
    }

    @include media-sp {
      width: calc(100% / 3);
      font-size: 1.8rem;
    }

    >span {
      display: flex;
      align-items: center;
      position: relative;

      @include media-sp {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        line-height: 1.3;
      }

      &::before {
        display: block;
        width: 40px;
        height: 40px;
        color: $navy;
        position: absolute;
        top: 0;
        left: -46px;
        opacity: 0.8;

        @include media-sp {
          font-weight: normal;
          position: relative;
          left: inherit;
          top: inherit;
          transform: none;
          margin-bottom: 4px;
        }
      }

      &.-learn::before {
        content: url("/consumer/products/assets/img/icon-learn.svg");
      }

      &.-work::before {
        content: url("/consumer/products/assets/img/icon-work.svg");
      }

      &.-play::before {
        content: url("/consumer/products/assets/img/icon-play.svg");
      }
    }

    &.-active {
      font-weight: 700;
      background: $beige_40;

      &::after {
        content: "";
        display: block;
        background: $orange;
        @include centering(true, false);
        bottom: 0;
        width: 100%;
        height: 3px;
        opacity: 1;
      }
    }
  }
}

.products-title {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 48px;
  position: relative;

  @include media-sp {
    padding: 0 20px;

    &__block--logo {
      width: 70%;
    }
  }

  &.-series {
    height: 45vw;
    max-height: 720px;

    @include media-sp {
      height: 400px;
    }
  }

  &.-feature {
    background: #ECEAE4;
    height: 42vw;
    max-height: 640px;

    @include media-sp {
      height: inherit;

      .products-title__img {
        height: 210px;
      }
    }

    &.-dark {
      background: #333333;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    @include media-sp {
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include media-sp {}
    }
  }

  &__block {
    padding: 40px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 16px;
    width: 100%;
    max-width: 482px;
    position: absolute;
    z-index: 5;
    bottom: 24px;

    @include media-sp {
      padding: 24px;
      position: relative;
      bottom: inherit;
      background: #FFFFFF;
      margin: 160px auto 20px;
    }
  }

  &__block--txt {
    margin-top: 16px;

    @include media-sp {
      margin-top: 8px;
    }
  }

  &__block--btn {
    margin-top: 24px;

    @include media-sp {
      margin-top: 16px;
    }
  }
}

.products-brief {
  background: #ECEAE4;
  padding: 80px 48px;
}

.products-brief__inner {
  max-width: 1520px;
  margin: 0 auto;
}

.products-brief h2 {
  font-size: 3.4rem;
  line-height: 1.5;
  font-weight: bold;
}

.products-brief.-dark {
  background: #333333;
}

.products-brief.-dark h2 {
  color: #FFFFFF;
}

.products-slogan {
  margin: 52px 0;
  padding: 0 48px;
}

.products-slogan__inner {
  max-width: 1520px;
  margin: 0 auto;
}

.products-slogan h2 {
  font-size: 5.2rem;
  line-height: 1.5;
  color: #003349;
}

.products-heading {
  color: #001D35;
  font-size: 3.4rem;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 40px;
  text-align: center;
}

.products-heading.-left {
  text-align: left;
}

.products-heading.-medium {
  font-size: 2.8rem;
  text-align: left;
  margin-bottom: 24px;
}

.products-game {
  background: #333333;
  color: #FFFFFF;
  margin: 0;
}

.products-game__inner {
  padding: 100px 0;
}

.products-card__engttl {
  font-weight: bold;
  color: #001D35;
  font-size: 1.5rem;
}

.products-card__ttl.-num {
  display: flex;
  align-items: center;
}

.products-card__num {
  margin-right: 16px;
  color: #001D35;
  font-size: 3.4rem;
  font-weight: 400;
}

.products-card__img {
  border: 1px solid #D3DEE4;
}

@media (min-width: 1024px) {
  .products-tab__btn {
    flex: 1;
  }
}

@media (max-width: 1023px) {
  .products-brief {
    padding: 50px 20px 80px;
  }

  .products-brief h2 {
    font-size: 2rem;
  }

  .products-slogan {
    margin: 40px 0;
    padding: 0 20px;
  }

  .products-slogan h2 {
    font-size: 3.2rem;
  }

  .products-heading {
    font-size: 2.2rem;
    margin-bottom: 20px;
  }

  .products-heading.-medium {
    font-size: 2rem;
    margin-bottom: 16px;
  }

  .products-game__inner {
    padding: 50px 0;
  }

  .products-card__num {
    margin-right: 8px;
    font-size: 2.4rem;
  }
}
