@use '../base/setting' as *;

.c-figure {
  display: table;
  margin: 0 auto;

  &.-round {
    img {
      border-radius: 16px;

      @include media-sp {
        border-radius: 8px;
      }
    }
  }

  &.-border {
    img {
      border: 1px solid $navy_20;
    }
  }

  &__caption {
    margin-top: 15px;
    display: table-caption;
    caption-side: bottom;

    &.-small {
      font-size: 1.2rem;
    }

    @include media-sp {
      .-sp-full & {
        padding: 0 20px;
      }
    }
  }

  @include media-sp {
    &.-sp-full {
      margin: 0 -20px;
    }
  }
}

.c-figure-block01 {
  display: flex;
  align-items: center;
  gap: 25px 40px;

  @include media-sp {
    margin: 0 -20px;
    flex-direction: column;

    &.-sp-reverse {
      flex-direction: column-reverse;
    }
  }

  >div {
    width: calc(50% - 20px);

    @include media-sp {
      width: 100%;
    }
  }

  &__img {}

  &__contents {
    @include media-sp {
      padding: 0 20px;
    }
  }

  &__tag {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 15px;
  }

  &__heading {
    span {
      display: block;

      &:first-child {
        font-size: 2rem;

        @include media-sp {
          font-size: 1.8rem;
        }
      }

      &:last-child {
        font-size: 2.8rem;

        @include media-sp {
          font-size: 2.4rem;
        }
      }

      &:not(:only-child) {
        &:first-child {
          margin-bottom: 10px;

          @include media-sp {
            margin-bottom: 4px;
          }
        }
      }

    }
  }

  &__txt {
    p+p {
      margin-top: 1em;
    }
  }

  &__btn {
    display: flex;
    gap: 30px;
    margin-top: 40px;

    @include media-sp {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin: 30px auto 0;
    }
  }

  &__link {
    margin-top: 35px;

    p:not(:last-child) {
      margin-bottom: 10px;
    }

    @include media-sp {
      margin-top: 30px;
    }
  }
}

.c-figure-block02 {
  display: flex;
  align-items: center;
  gap: 25px 40px;

  @include media-sp {
    margin: 0 -20px;
    flex-direction: column;
  }

  &__img {
    width: calc(100%/3 - 30px);

    @include media-sp {
      width: 100%;
    }
  }

  &__contents {
    flex: 1;

    @include media-sp {
      padding: 0 20px;
    }
  }

  &__heading {
    margin-bottom: 30px;

    @include media-sp {
      margin-bottom: 25px;
    }
  }

  &__txt {
    p+p {
      margin-top: 1em;
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 40px;

    @include media-sp {
      align-items: center;
      margin: 30px auto 0;

      .c-btn:not(:last-child) {
        gap: 20px;
      }
    }
  }
}

.c-figure-block03 {
  display: flex;
  align-items: center;
  gap: 25px 40px;

  @include media-sp {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 20px;
  }

  &__img {
    width: calc(25% - 30px);

    @include media-sp {
      width: 100%;
      grid-area: 1 / 1 / 2 / 2;
    }
  }

  &__contents {
    flex: 1;

    @include media-sp {
      display: contents;
    }
  }

  &__heading {
    margin-bottom: 30px;

    @include media-sp {
      grid-area: 1 / 2 / 2 / 3;
      margin-bottom: 0;
    }
  }

  &__txt {

    p+p {
      margin-top: 1em;
    }

    @include media-sp {
      margin-top: 20px;
      grid-area: 2 / 1 / 3 / 3;
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 40px;

    @include media-sp {
      align-items: center;
      width: 100%;
      grid-area: 3 / 1 / 4 / 3;
      margin: 30px auto 0;

      .c-btn:not(:last-child) {
        gap: 20px;
      }
    }
  }
}

.c-figure-block04 {
  @include media-pc-tb {
    display: flex;
    align-items: center;
    gap: 40px;
    width: 100%;

    &.-img-left {
      justify-content: flex-start;
    }

    &.-img-right {
      justify-content: flex-end;
    }

    &__img {
      width: calc((50% - 760px) + (1520px * 2 / 3) - 40px);

      .c-figure-block04.-narrow & {
        width: calc((50% - 568px) + ($narrow-content-width * 2 / 3) - 40px);
      }
    }

    &__contents {
      width: calc(1520px / 3);
      display: flex;

      .c-figure-block04.-narrow & {
        width: calc($narrow-content-width / 3);
      }

      .c-figure-block04.-img-left & {
        margin-right: 48px;
      }

      .c-figure-block04.-img-right & {
        margin-left: 48px;
      }
    }
  }

  @include media-sp {
    display: flex;
    flex-direction: column;

    &.-img-right {
      flex-direction: column-reverse;
    }

    &__img {

      .c-figure-block04.-img-left & {
        margin-left: -20px;
      }

      .c-figure-block04.-img-right & {
        margin-right: -20px;
      }
    }

    &__contents {
      margin-top: 32px;
      padding: 0 20px;
    }
  }

  &__heading {}

  &__txt {
    p+p {
      margin-top: 1em;
    }
  }

  &__btn {
    display: flex;
    margin-top: 40px;

    @include media-sp {
      margin-top: 30px;
      justify-content: center;
    }
  }
}

.c-figure-block05 {
  display: flex;
  gap: 30px 40px;

  @include media-pc-tb {
    align-items: center;

    &__contents {
      width: 31%;
    }

    &__img {
      flex: 1;
    }
  }

  @include media-sp {
    flex-direction: column;

    &__contents {
      order: 2;
    }

    &__img {
      order: 1;
      margin: 0 -20px;
    }
  }
}

.c-figure-block06 {
  display: flex;
  gap: 30px 40px;

  @include media-pc-tb {
    align-items: center;

    &__contents {
      flex: 1;
    }

    &__img {
      width: calc(60% - 40px);
    }
  }

  @include media-sp {
    flex-direction: column;

    &__contents {
      order: 2;
    }

    &__img {
      order: 1;
      margin: 0 -20px;
    }
  }
}
