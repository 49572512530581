@use '../base/setting' as *;

.cs-faq-link {
  display: flex;
  gap: 16px 28px;

  @include media-sp {
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: calc(25% - 21px);
    padding: 28px 20px;
    border: 1px solid $gray;
    border-radius: 8px;
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border: 8px solid transparent;
      position: absolute;
      top: 0;
      left: 0;
      @include transition;
    }

    @include media-pc-tb {
      flex-direction: column;
    }

    @include media-sp {
      width: 100%;
      padding: 24px 16px;
    }

    img {
      width: 120px;

      @include media-sp {
        width: 100px;
      }
    }

    span {
      display: block;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 1.5;

      &::after {
        content: "\e90a";
        font-family: 'icomoon';
        color: $orange;
        font-weight: normal;
        margin-left: 4px;
      }

      @include media-sp {
        font-size: 1.4rem;
      }
    }

    @media (hover: hover) {
      &:hover::after {
        border-color: $gray;
      }
    }
  }
}
