@use '../base/setting' as *;

.office-pm-features {
  background: $white;
  padding: 32px 40px;

  &+& {
    margin-top: 32px;
  }

  &__ttl {
    display: flex;
    align-items: center;
    gap: 24px;

    span {
      display: block;

      &:first-child {
        img {
          width: 100%;
        }
      }

      &:last-child {
        flex: 1;
        font-weight: 700;
        line-height: 1.4;
      }
    }
  }

  @include media-pc-tb {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 24px;

    &__img {
      grid-area: 1 / 1 / 3 / 2;
    }

    &__ttl {
      grid-area: 1 / 2 / 2 / 3;

      span {
        &:first-child {
          width: 64px;
        }

        &:last-child {
          font-size: 2.4rem;
        }
      }
    }

    &__txt {
      grid-area: 2 / 2 / 3 / 3;
    }

    &__area {
      grid-area: 3 / 1 / 4 / 3;
    }
  }

  @include media-sp {
    padding: 20px 16px;

    &+& {
      margin-top: 24px;
    }

    &__img {
      margin-bottom: 16px;
    }

    &__ttl {
      gap: 20px;
      margin-bottom: 12px;

      span {
        &:first-child {
          width: 32px;
        }

        &:last-child {
          font-size: 2.1rem;
        }
      }
    }
  }
}

.office-pm-todo {
  height: 100%;
  border: 1px solid $navy_20;

  &__ttl {
    background: $gray_20;
    border-bottom: 1px solid $navy_20;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 700;
    gap: 10px;
    padding: 12px;

    @include media-sp {
      font-size: 1.8rem;
    }

    span {
      display: block;

      img {
        display: block;
      }
    }
  }

  &__txt {
    padding: 20px 30px;

    @include media-sp {
      padding: 24px 16px;
    }
  }
}

.office-pm-step {
  background: $gray_20;
  padding: 30px;
  position: relative;
  margin-bottom: 62px;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 45px 0 45px;
    border-color: $navy_20 transparent transparent transparent;
    @include centering(true, false);
    bottom: -46px;
  }

  @include media-pc-tb {
    display: flex;
    flex-wrap: wrap;
  }

  @include media-sp {
    padding: 20px;
  }

  &__ttl {
    width: 100%;
    font-weight: 700;
    padding-bottom: 16px;
    border-bottom: 1px solid $navy_20;
    margin-bottom: 24px;

    @include media-pc-tb {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    @include media-sp {
      padding-bottom: 8px;
      margin-bottom: 16px;
    }

    span {
      display: block;

      &:first-child {
        background: $gray_80;
        font-size: 1.8rem;
        padding: 2px 12px;

        @include media-sp {
          font-size: 1.6rem;
          width: fit-content;
          margin-bottom: 4px;
        }
      }

      &:last-child {
        font-size: 2.4rem;

        @include media-sp {
          font-size: 2.1rem;
        }
      }
    }
  }

  &__img {
    span {
      display: block;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 700;
      margin-top: 8px;
    }

    @include media-pc-tb {
      width: 40%;
      margin-right: 3%;
    }

    @include media-sp {
      margin-bottom: 16px;
    }
  }

  &__consumer {
    @include media-pc-tb {
      margin-right: 2%;
    }

    @include media-sp {
      margin-bottom: 16px;
    }
  }


  &__consumer,
  &__company {
    background: $white;
    padding: 16px 20px;

    @include media-sp {
      padding: 16px;
    }

    >div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      padding-bottom: 10px;
      border-bottom: 1px solid $navy_20;
      font-size: 1.8rem;
      font-weight: 700;
      margin-bottom: 16px;

      @include media-sp {
        font-size: 1.6rem;
      }

      span:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;

        img {
          display: block;
        }
      }

    }

    @include media-pc-tb {
      width: 27.5%;
    }
  }
}

.office-layout-effect {
  @include media-pc-tb {
    display: grid;
    gap: 32px 28px;
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    padding: 24px 24px 30px;
    border: 1px solid $navy_20;

    @include media-sp {
      padding: 20px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &__ttl {
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.5;
    color: $navy;
    text-align: center;
    margin-bottom: 16px;

    @include media-sp {
      font-size: 2.1rem;
    }
  }
}

.office-layout-best-card {
  background: $white;
  height: 100%;

  &__inner {
    padding: 16px 24px 24px;

    @include media-sp {
      padding: 16px 20px 20px;
    }
  }

  &__ttl {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 10px;

    @include media-sp {
      font-size: 1.8rem;
    }
  }
}

.office-layout-example-slider {
  margin: 0 -14px;
  position: relative;

  @include media-sp {
    margin: 0 -10px;
  }

  &__inner {
    display: none;

    &.slick-initialized {
      display: block;
    }

    .slick-slide>div {
      height: 100%;
    }
  }

  &__item {
    height: 100%;
    padding: 0 14px;

    @include media-sp {
      padding: 0 10px;
    }
  }

  &__arrows {
    @include media-pc-tb {
      display: none;
    }

    @include media-sp {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-top: 16px;
      padding: 0 20px;
    }
  }

  &__prev,
  &__next {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: $gray_20;
    border-radius: 100%;
    cursor: pointer;

    &::before {
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $navy;
    }

    &.slick-disabled {
      cursor: default;

      &::before {
        color: $navy_40;
      }
    }
  }

  &__prev {

    &::before {
      content: "\e907";
    }
  }

  &__next {

    &::before {
      content: "\e906";
    }
  }

  &__dots {

    .slick-dots {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px 0;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;

        button {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background: $gray_60;
          position: relative;
          text-indent: -9999px;
        }

        &.slick-active {
          button {
            outline: none;
            background: $orange;
          }
        }
      }
    }
  }

  &__card {
    height: 100%;

    &.-stylish {
      background: #E2E9ED;
    }

    &.-natural {
      background: #DFEDE4;
    }

    &.-colorful {
      background: #F4E2E2;
    }

    &.-simple {
      background: #F5F4F1;
    }
  }

  &__ttl {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    padding: 16px;

    @include media-sp {
      font-size: 1.8rem;
      padding: 6px 16px;
    }
  }

  &__txt {
    padding: 16px;

    @include media-sp {
      padding: 20px;
    }
  }
}


.office-layout-flow {
  background: $gray_20;
  position: relative;
  margin-bottom: 62px;

  &:not(:nth-of-type(4)) {
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 30px 45px 0 45px;
      border-color: $navy_20 transparent transparent transparent;
      @include centering(true, false);
      bottom: -46px;
    }
  }

  @include media-pc-tb {
    padding: 30px;
  }

  &__ttl {
    background: $gray_80;
    line-height: 1.5;
    font-weight: 700;

    @include media-pc-tb {
      display: inline-block;
      padding: 4px 12px;
      font-size: 2.4rem;
      margin-bottom: 16px;
    }

    @include media-sp {
      font-size: 1.9rem;
      padding: 6px 20px;
    }
  }

  &__inner {

    @include media-sp {
      padding: 20px;
    }
  }

  &__img {
    @include media-pc-tb {
      display: flex;
      gap: 28px;

      >div {
        flex: 1;
      }

      >img {
        width: 40%;
      }
    }

    @include media-sp {

      >img {
        padding: 0 20px 20px;
      }
    }
  }
}

.office-renewal-example {
  display: flex;
  gap: 68px 100px;
  padding: 40px 40px 48px;
  background: $gray_20;

  @include media-sp {
    flex-direction: column;
    padding: 16px;
  }

  &__item {
    width: calc(50% - 50px);
    position: relative;

    @include media-sp {
      width: 100%;
    }

    &:first-child {
      @include media-pc-tb {
        .office-renewal-example__img::after {
          content: "";
          display: block;
          background: $navy_20;
          width: 40px;
          height: 120px;
          clip-path: polygon(0 0, 0 100%, 100% 50%);
          @include centering(false, true);
          right: -70px;
        }
      }

      @include media-sp {
        &::after {
          content: "";
          display: block;
          background: $navy_20;
          width: 60px;
          height: 20px;
          clip-path: polygon(0 0, 100% 0, 50% 100%);
          @include centering(true, false);
          bottom: -44px;
        }
      }
    }
  }

  &__img {
    position: relative;

    >span {
      display: block;
      background: $navy_80;
      padding: 2px 12px;
      font-size: 1.8rem;
      font-weight: 700;
      color: $white;
      position: absolute;
      top: 0;
      left: 0;

      @include media-sp {
        font-size: 1.6rem;
      }
    }
  }

  &__txt {
    margin-top: 16px;
  }

}


.office-renewal-feature {
  line-height: 1.5;

  @include media-sp {
    margin-top: 24px;
  }

  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;

    li {
      width: calc((100% - 90px)/7);

      @include media-tb {
        width: calc((100% - 45px)/4);
      }

      @include media-sp {
        width: calc((100% - 15px)/2);
      }
    }
  }

  &__item {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $gray_40;
    padding: 15px 5px;
    border-radius: 2px;

    img {
      width: 64px;
      height: 64px;
      margin-bottom: 8px;
    }

    span {
      font-size: 1.3rem;
      font-weight: 700;
      text-align: center;

      @include media-sp {
        font-size: 1.2rem;
      }
    }
  }

  &__total {
    padding: 20px 0;
    margin-top: 15px;
    position: relative;

    >div {
      width: 100%;
      height: 1px;
      background: $navy_40;

      &::before,
      &::after {
        content: "";
        width: 1px;
        height: 20px;
        background: $navy_40;
        position: absolute;
        top: 0;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    &::after {
      content: "";
      width: 1px;
      height: 20px;
      background: $navy_40;
      @include centering(true, false);
      bottom: 0;
    }
  }

  &__result {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $gray_40;
    padding: 15px 10px;
    border-radius: 4px;
    margin: 0 auto;

    img {
      width: 80px;
      height: 80px;
      margin-bottom: 8px;
    }

    >span {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 1.8rem;
      font-weight: 700;
      text-align: center;

      &::after {
        content: "\e90a";
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        color: $orange;
      }

      @include media-sp {
        font-size: 1.6rem;
      }
    }

    @media (hover: hover) {
      &:hover>span>span {
        text-decoration: underline;
      }
    }
  }

}

a.office-renewal-feature__item {
  >span {
    display: flex;
    align-items: center;
    gap: 2px;

    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
    }
  }

  @media (hover: hover) {
    &:hover>span>span {
      text-decoration: underline;
    }
  }
}