@use '../base/setting' as *;

.l-block-link {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &__item {

    @for $grid-col from 1 through 12 {
      &.-col-#{$grid-col} {
        width: calc(100% * $grid-col / 12);
      }
    }

    @include media-sp {
      @for $grid-col from 1 through 12 {
        &.-sp-col-#{$grid-col} {
          width: calc(100% * $grid-col / 12);
        }
      }
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: 420px;
    position: relative;
    color: $white;

    @include media-sp {
      height: 240px;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 50%;
      background: linear-gradient(0deg, rgba($black, 0.8), transparent);
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &__title {
    font-size: 2.8rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 16px;

    @include media-sp {
      font-size: 1.8rem;
      margin-bottom: 8px;
    }

    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-size: 2rem;
      font-weight: normal;
      margin-left: 8px;

      @include media-sp {
        font-size: 1.6rem;
      }
    }
  }

  &__inner {
    width: 90%;
    max-width: 450px;
    @include centering(true, false);
    bottom: 50px;
    z-index: 10;

    @include media-sp {
      max-width: 295px;
      bottom: 24px;
    }
  }
}

.l-block-image {
  padding-right: 60%;
  position: relative;

  &.-img-left {
    padding-right: 0;
    padding-left: 60%;
  }

  @include media-sp {
    padding: 0;

    &.-img-left {
      padding: 0;
    }
  }

  &__heading {
    margin-bottom: 20px;

    @include media-sp {
      margin-bottom: 15px;
    }
  }

  &__list {
    border-top: 1px solid $navy_20;

    &:not(:first-child) {
      margin-top: 32px !important;
    }

    &:not(:last-child) {
      margin-bottom: 32px !important;
    }

    li {
      border-bottom: 1px solid $navy_20;
    }
  }

  &__link {

    div {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      font-weight: 700;
      line-height: 1.5;
      position: relative;
      padding: 16px 32px 16px 24px;
      @include transition;

      span {
        display: block;

        &:first-child {
          width: 40px;

          img {
            width: 100%;
          }
        }
      }

      &::after {
        content: "\e90a";
        font-family: 'icomoon';
        font-weight: normal;
        font-size: 1.6rem;
        color: $orange;
        @include centering(false, true);
        right: 16px;
      }

      @include media-sp {
        font-size: 1.4rem;
        padding: 16px 24px 16px 16px;

        &::after {
          right: 8px;
        }
      }
    }

    >figure {
      position: absolute;
      top: 0;
      right: 0;
      width: calc(60% - 40px);
      opacity: 0;
      transition: opacity 0.3s;
      pointer-events: none;
      z-index: 10;

      .-img-left & {
        left: 0;
      }

      @include media-sp {
        display: none;
      }
    }

    @include media-pc-tb {
      &.-active {
        div {
          background: $gray_20;
        }

        figure {
          opacity: 1;
        }
      }
    }
  }

  &__img-sp {
    @include media-pc-tb {
      display: none;
    }

    @include media-sp {
      margin: 0 -20px 30px;

      img {
        width: 100%;
      }
    }
  }

  &__img-pc {
    @include media-sp {
      display: none;
    }

    position: absolute;
    top: 0;
    right: 0;
    width: calc(60% - 40px);
    opacity: 1;
    z-index: 9;

    .-img-left & {
      left: 0;
    }
  }
}

.l-block-scroll {

  @include media-sp {
    max-width: 100vw;
    overflow-y: auto;
    margin: 0 -20px;

    &__inner {
      padding: 0 20px;
      min-width: 1084px;
    }
  }
}
