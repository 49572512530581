@use '../base/setting' as *;

.c-tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  background: $gray_80;
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.2;
  color: $navy;

  @include media-sp {
    padding: 4px 12px;
  }

  &.-orange {
    background: $orange_80;
    color: $white;
  }

  &.-beige {
    background: $beige_60;
  }

  &.-gray {
    background: #999;
    color: $white;
  }

  &.-border {
    background: $white;
    border: 1px solid $navy_40;
    color: $navy_60;
  }

  &.-required {
    border-radius: 0;
    padding: 6px 8px;
    background: $orange;
    color: $white;
    font-size: 1.2rem;
    margin-right: 1em;
  }
}

.c-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  @include media-sp {
    gap: 8px 12px;

    &.-sp-column {
      >span {
        width: 100%;
      }
    }
  }

  img {
    display: block;
  }

  span {
    font-size: 1.4rem;
    font-weight: 700;

    @include media-pc-tb {
      margin-right: 8px;
    }
  }

  @media (hover: hover) {
    a:hover {
      opacity: 0.7;
    }
  }
}