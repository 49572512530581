@use '../base/setting' as *;

.l-section {
  margin: 64px 0;
  padding: 0 115px;
  background: $white;

  &:first-child {
    @include media-sp {
      padding-top: 48px;
    }
  }

  @include media-pc-s {
    padding: 0 72px;
  }

  @include media-tb {
    padding: 0 48px;
  }

  @include media-sp {
    margin: 48px 0;
    padding: 0 20px;
  }

  &.-bg-gray {
    background: $gray_20;
    margin: 0;
  }

  &.-bg-blue {
    background: #F4F9FF;
    margin: 0;
  }

  &.-bg-dark-beige {
    background: $beige_40;
    margin: 0;
  }

  &.-bg-beige {
    background: $beige_20;
    margin: 0;
  }

  &.-bg-image {
    margin: 0;
  }

  &.-round-top {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    margin-top: -24px;

    @include media-sp {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      margin-top: -16px;
    }
  }

  &:has(+ .-round-top) {
    padding-bottom: 24px;

    @include media-sp {
      padding-bottom: 16px;
    }
  }

  &__full {
    margin: 0 -115px;

    @include media-pc-s {
      margin: 0 -72px;
    }

    @include media-tb {
      margin: 0 -48px;
    }

    @include media-sp {
      margin: 0 -20px;
    }
  }

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;

    &.-narrow {
      max-width: $narrow-content-width;
    }

    &.-xnarrow {
      max-width: 744px;
    }

    .l-section.-bg-gray &,
    .l-section.-bg-blue &,
    .l-section.-bg-dark-gray &,
    .l-section.-bg-beige &,
    .l-section.-bg-image & {
      padding: 64px 0;

      @include media-sp {
        padding: 48px 0;
      }
    }

    .l-section.-round-top & {
      padding-top: 64px;

      @include media-sp {
        padding-top: 48px;
      }
    }
  }

  @include media-pc-tb {
    &__column {
      display: flex;
      gap: 60px;
      max-width: $base-content-width;
      margin: 0 auto;

      &.-narrow {
        max-width: $narrow-content-width;
      }
    }

    &__contents {
      flex: 1;
    }

    &__nav {
      width: 234px;
    }
  }

  @include media-sp {
    &__contents {
      margin-bottom: 60px;
    }
  }
}
