@use '../../base/setting' as *;

.company-top-mv {
  position: relative;

  &__slider {

    .slick-slide {
      opacity: 1 !important;
    }
  }

  &__txt {
    width: 100%;
    max-width: 1377px;
    height: 34.375vw;
    display: flex;
    justify-content: center;
    align-items: center;
    @include centering(true, true);

    img {
      max-height: 100%;
    }

    @include media-sp {
      padding: 2.66vw;
      height: 106.66vw;
    }
  }

  &__item {
    height: 34.375vw;
    position: relative;
    z-index: 10;
    overflow: hidden;
    vertical-align: bottom;
    opacity: 0;
    transition: opacity 0s;

    @include media-sp {
      height: 106.66vw;
    }
  }

  .slick-active .company-top-mv__item {
    opacity: 1;
    transition: opacity ease 7.0s;
  }

  .slick-continue .company-top-mv__item {
    opacity: 0;
    transition: opacity ease 7.0s;
  }

  &__bg {
    width: 100%;
    height: 100%;
    background: no-repeat center center / cover;
    z-index: 10;
    overflow: hidden;
    margin: 0;
    transform: scale(1, 1);
    transition: transform 0s;

    &.-bg01 {
      background-image: url(/company/assets/img/bg_main01-pc.jpg);

      @include media-sp {
        background-image: url(/company/assets/img/bg_main01-sp.jpg);
      }
    }

    &.-bg02 {
      background-image: url(/company/assets/img/bg_main02-pc.jpg);

      @include media-sp {
        background-image: url(/company/assets/img/bg_main02-sp.jpg);
      }
    }

    &.-bg03 {
      background-image: url(/company/assets/img/bg_main03-pc.jpg);

      @include media-sp {
        background-image: url(/company/assets/img/bg_main03-sp.jpg);
      }
    }

    &.-bg04 {
      background-image: url(/company/assets/img/bg_main04-pc.jpg);

      @include media-sp {
        background-image: url(/company/assets/img/bg_main04-sp.jpg);
      }
    }

    &.-bg05 {
      background-image: url(/company/assets/img/bg_main05-pc.jpg);

      @include media-sp {
        background-image: url(/company/assets/img/bg_main05-sp.jpg);
      }
    }

    &.-bg06 {
      background-image: url(/company/assets/img/bg_main06-pc.jpg);

      @include media-sp {
        background-image: url(/company/assets/img/bg_main06-sp.jpg);
      }
    }
  }

  .slick-active .company-top-mv__bg {
    transform: scale(1.2, 1.2);
    transition: transform ease 15.0s;
  }

  .slick-continue .company-top-mv__bg {
    transform: scale(1.3, 1.3);
    transition: transform ease 15.0s;
  }
}

.company-top-banner {
  margin: 50px 0;
  padding: 0 48px;

  @include media-sp {
    margin: 32px 0;
    padding: 0 36px;
  }

  &__list {
    max-width: $narrow-content-width;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 16px 28px;

    img {
      width: 100%;
      max-width: 360px;
    }

    @include media-sp-s {
      flex-direction: column;
      align-items: center;
    }

    @media (hover: hover) {
      a:hover {
        opacity: 0.7;
      }
    }
  }
}

.company-top-important-news {
  margin-bottom: 75px;
  padding: 0 115px;

  @include media-pc-s {
    padding: 0 72px;
  }

  @include media-tb {
    padding: 0 48px;
  }

  @include media-sp {
    margin-bottom: 32px;
    padding: 0 20px;
  }

  li {
    color: #B11919;
  }

  a {
    color: #B11919;
    font-weight: 500;

    &[target="_blank"]::after {
      content: "\e900";
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      margin-left: 0.25em;
    }

    &[href*=".pdf"] {
      margin-right: 0.25em;

      &::after {
        content: "\e904";
      }
    }
  }

  &__block {
    max-width: $base-content-width;
    margin: 0 auto;
    background: #FFF6F5;
    padding: 30px;

    @include media-pc-tb {
      display: flex;
      gap: 4vw;
    }

    @include media-sp {
      padding: 16px;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 700;

    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    @include media-sp {
      margin-bottom: 24px;
    }
  }

  &__list {
    @include media-pc-tb {
      flex: 1;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 20px;

        @include media-sp {
          margin-bottom: 12px;
        }
      }

      a {
        span {
          text-decoration: underline;
        }

        @media (hover: hover) {
          &:hover span {
            text-decoration: none;
          }
        }
      }
    }
  }

  &__link {

    @include media-sp {
      text-align: right;
    }

    a {
      @media (hover: hover) {
        &:hover span {
          text-decoration: underline;
        }
      }
    }
  }
}

.company-top-cta {
  @include media-pc-tb {
    display: none;
  }

  @include media-sp {
    background: $gray_20;
    padding: 24px 36px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  @include media-sp-s {
    flex-direction: column;
  }
}

.company-top-news {
  @include media-pc-tb {
    display: flex;
    gap: 48px;

    &__latest {
      width: calc(57.3% - 24px);
    }

    &__ir {
      width: calc(42.7% - 24px);
    }
  }

  @include media-sp {
    &__latest {
      margin-bottom: 32px;
    }
  }
}

.company-top-news-list {
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;

  li {
    margin: 32px 0;

    @include media-sp {
      margin: 24px 0;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: 230px 1fr;
    grid-template-rows: min-content 1fr;
    gap: 16px 28px;

    @include media-sp {
      grid-template-columns: 144px 1fr;
      gap: 16px;
    }

    &[target="_blank"] {
      .company-top-news-list__ttl span:last-child::before {
        content: "\e900";
        margin-left: 0.25em;
      }
    }

    &[href*=".pdf"] {
      .company-top-news-list__ttl>span:last-child::before {
        content: "\e904";
        margin: 0 0.25em;
      }
    }

    @media (hover: hover) {
      &:hover {
        .company-top-news-list__img img {
          transform: scale(1.1, 1.1);
        }

        .company-top-news-list__ttl span:first-child {
          text-decoration: underline;
        }
      }
    }
  }

  &__img {
    grid-area: 1 / 1 / 3 / 2;
    overflow: hidden;

    img {
      border: 1px solid $gray_60;
      @include transition;
    }

    @include media-sp {
      grid-area: 2 / 1 / 3 / 2;
    }
  }

  &__date {
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-top: 16px;

    >span {
      &:first-child {
        color: $navy_80;
        font-size: 1.4rem;
        font-weight: 700;
        margin-right: 8px;

        @include media-sp {
          display: block;
          width: 100%;
        }
      }
    }

    @include media-sp {
      grid-area: 1 / 1 / 2 / 3;
      padding-top: 0;
      flex-wrap: wrap;
    }
  }

  &__ttl {
    grid-area: 2 / 2 / 3 / 3;
    font-weight: 700;

    >span:last-child::before {
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
    }
  }
}

.company-top-about,
.company-top-ir,
.company-top-sustainability,
.company-top-recruit {

  @include media-pc-tb {
    margin: 80px 0;

    &__inner {
      gap: 60px;
      width: 100%;
    }

    &__img {
      width: calc((50% - 760px) + (1520px * 3 / 5) - 60px);
      position: relative;

      figure {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &__contents {
      width: calc(1520px * 2 / 5);
      display: flex;

      >div {
        width: 100%;
      }
    }

    &__list {
      display: flex;
      gap: 28px;

      >div {
        flex: 1;
      }
    }

  }

  @include media-sp {
    margin: 48px 0;

    &__img {
      margin: 0 -20px 32px 0;
    }

    &__contents {
      padding: 0 20px;
    }

    &__list {
      >div:first-child a:last-child {
        border-bottom: 0;
      }
    }
  }

  &__inner {
    display: flex;
  }

  &__txt {
    margin-bottom: 40px;

    @include media-sp {
      margin-bottom: 32px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 700;
    line-height: 1.5;
    position: relative;
    padding: 16px 32px 16px 16px;
    border-top: 1px solid $gray;
    @include transition;

    &:last-child {
      border-bottom: 1px solid $gray;
    }

    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-weight: normal;
      font-size: 1.6rem;
      color: $orange;
      @include centering(false, true);
      right: 16px;
    }

    &[target="_blank"]::after {
      content: "\e900";
    }

    @include media-sp {
      font-size: 1.4rem;
      padding: 20px 24px 20px 16px;

      &::after {
        right: 8px;
      }
    }

    @media (hover: hover) {
      &:hover {
        background: #F2F5F7;
      }
    }
  }

  &__btn {
    display: flex;
    margin-top: 32px;

    @include media-sp {
      justify-content: center;
    }
  }
}

.company-top-about,
.company-top-sustainability {
  @include media-pc-tb {

    &__inner {
      justify-content: flex-start;
    }

    &__contents {
      margin-right: 115px;

      @include media-pc-s {
        margin-right: 72px;
      }

      @include media-tb {
        margin-right: 48px;
      }
    }
  }

  @include media-sp {

    &__inner {
      flex-direction: column-reverse;
    }
  }
}

.company-top-ir,
.company-top-recruit {
  @include media-pc-tb {

    &__inner {
      justify-content: flex-end;
    }

    &__contents {
      margin-left: 115px;

      @include media-pc-s {
        margin-left: 72px;
      }

      @include media-tb {
        margin-left: 48px;
      }
    }
  }

  @include media-sp {

    &__inner {
      flex-direction: column;
    }
  }
}

.company-top-recruit {

  &__inner {
    @include media-pc-tb {
      min-height: 26.54vw;
      background: url(/company/assets/img/img_recruit.jpg) no-repeat calc(100% - 5vw) 50% / 60vw auto;
    }
  }

  @include media-sp {
    &__txt {
      margin-bottom: 20px;
    }

    &__img {
      margin: 0 -20px 24px;
      padding: 0;
    }

    &__loop {
      margin: 0 -20px 36px;
    }
  }
}
