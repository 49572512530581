@use '../../base/setting' as *;

.xork-header {
  width: 100%;
  background: $white;
  height: 100px;
  border-bottom: 1px solid $navy_20;

  @include media-sp {
    height: 68px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 32px;

    @include media-sp {
      padding-left: 16px;
    }
  }

  &__logo {
    width: 132px;

    @include media-sp {
      width: 103px;
    }
  }

  &__cta {
    display: flex;
    gap: 1px;
    height: 100%;
  }

  &__btn {
    display: flex;
    align-items: center;
    background: $navy;
    color: $white;
    padding: 24px;
    font-size: 1.4rem;
    font-weight: 700;
    position: relative;

    @include media-sp {
      font-size: 1.1rem;
      line-height: 1.45;
      padding: 12px;
    }

    &::after {
      content: "\e900";
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      margin-left: 8px;

      @include media-sp {
        font-size: 1.3rem;
        position: absolute;
        top: 4px;
        right: 4px;
      }
    }

    &.-reserve {
      &::before {
        content: "\e914";
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        font-size: 2.2rem;
        margin-right: 12px;

        @include media-sp {
          margin-right: 5px;
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        background: $orange;
      }
    }
  }
}

.xork-mv {
  height: 34.375vw;
  background: url(/special/xork/assets/img/bg_main-pc.jpg) no-repeat center center / cover;
  position: relative;

  @include media-sp {
    height: 90.66vw;
    background-image: url(/special/xork/assets/img/bg_main-sp.jpg);
  }

  &__inner {
    width: 100%;
    max-width: $narrow-content-width;
    height: 34.375vw;
    display: flex;
    justify-content: center;
    align-items: center;
    @include centering(true, true);

    img {
      max-height: 100%;
    }

    @include media-sp {
      height: 90.66vw;
    }
  }
}

.xork-section {
  padding: 64px 115px;

  @include media-pc-s {
    padding: 64px 72px;
  }

  @include media-tb {
    padding: 64px 48px;
  }

  @include media-sp {
    padding: 48px 20px;
  }

  &__inner {
    max-width: $narrow-content-width;
    margin: 0 auto;
  }

  &__heading {
    text-align: center;
    margin-bottom: 24px;

    img {
      height: 31px;

      @include media-sp {
        height: 27px;
      }
    }

    span {
      display: block;
      font-size: 1.4rem;
      letter-spacing: 0.06em;
      color: #48606A;
      margin-top: 16px;

      @include media-sp {
        margin-top: 8px;
      }
    }
  }
}

.xork-intro-layout {

  &__lead {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 24px;

    @include media-sp {
      font-size: 2.4rem;
      margin-bottom: 16px;
    }
  }

  @include media-pc-tb {
    display: grid;
    grid-template-columns: 1fr 32%;
    grid-template-rows: auto 1fr;
    gap: 64px 28px;

    &__txt {
      grid-area: 1 / 1 / 2 / 2;
    }

    &__img01 {
      grid-area: 1 / 2 / 3 / 3;

      img {
        position: relative;
        width: calc(100% + 128px);
        max-width: 488px;

        @media (max-width: 1920px) {
          width: calc(100% + 6.5vw);
        }
      }
    }

    &__img02 {
      grid-area: 2 / 1 / 3 / 2;

      img {
        position: relative;
        left: -256px;
        width: calc(100% + 256px);
        max-width: 994px;

        @media (max-width: 1920px) {
          left: -13vw;
          width: calc(100% + 13vw);
        }
      }
    }
  }

  @include media-sp {
    &__txt {
      margin-bottom: 32px;
    }

    &__img01 {
      margin-bottom: 16px;
    }
  }
}

.xork-concept {
  background: url(/special/xork/assets/img/bg_concept-pc.png) no-repeat center center / cover;

  @include media-sp {
    background: $gray_20;
  }
}

.xork-concept-movie {
  max-width: 750px;
  margin: 0 auto;

  &__caption {
    padding: 16px;
    background: $white;

    span {
      display: block;
      text-align: center;

      &:first-child {
        font-weight: 500;
      }

      &:last-child {
        font-size: 1.4rem;
        color: #797979;

        @include media-sp {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.xork-concept-loop {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0 -115px;

  &__img {
    display: flex;
    align-items: center;
    animation: loop infinite linear 80s both;

    li {
      width: 4644px;

      @include media-sp {
        width: 2057px;
      }
    }
  }
}

.xork-effect {
  background: url(/special/xork/assets/img/bg_effect-pc.png) no-repeat center center / cover;
  color: $white;

  @include media-sp {
    background-image: url(/special/xork/assets/img/bg_effect-sp.png);
  }

  &__heading {
    margin-bottom: 24px;

    @include media-sp {
      margin-bottom: 16px;
    }

    span {
      display: block;
      width: fit-content;
      margin: 0 auto;

      &:first-child {
        margin-bottom: 8px;

        @include media-sp {
          width: 286px;
        }
      }
    }
  }
}

.xork-effect-list {
  &__txt {
    font-weight: 700;

    span {
      padding: 0 4px;
      background: $white;
      margin-right: 4px;
      color: $navy;
    }
  }

  @include media-pc-tb {
    display: flex;

    &__item {
      flex: 1;
      padding: 0 16px 16px;

      &:not(:last-child) {
        border-right: 1px solid #505A79;
      }
    }

    &__icon {
      width: fit-content;
      margin: 0 auto 12px;

      img {
        width: 120px;
      }
    }

    &__txt {
      font-size: 1.8rem;
      line-height: 1.65;
      text-align: center;
      margin-bottom: 12px;
    }

    &__num {
      width: fit-content;
      margin: 0 auto;

      img {
        height: 31px;
      }
    }
  }

  @include media-sp {
    border-top: 1px solid #505A79;

    &__item {
      display: grid;
      grid-template-columns: 80px 1fr;
      gap: 12px 16px;
      padding: 16px 0;
      border-bottom: 1px solid #505A79;
    }

    &__icon {
      display: flex;
      align-items: center;
      grid-area: 1 / 1 / 3 / 2;

      img {
        width: 80px;
      }
    }

    &__txt {
      grid-area: 1 / 2 / 2 / 3;
      font-size: 1.6rem;
      line-height: 1.5;
    }

    &__num {
      grid-area: 2 / 2 / 3 / 3;

      img {
        height: 26px;
      }
    }
  }
}

.xork-space-movie {
  display: flex;

  @include media-sp {
    flex-direction: column;
    max-width: 750px;
    margin: 0 auto;
  }

  &__caption {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $gray_20;
    padding: 24px 40px;

    @include media-tb {
      padding: 24px 32px;
    }

    @include media-sp {
      padding: 24px 16px;
    }

    span {
      display: block;

      &:first-child {
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 1.75;
        margin-bottom: 20px;

        @include media-tb {
          font-size: 2.1rem;
        }
      }

      &:last-child {
        @include media-tb {
          font-size: 1.4rem;
        }
      }
    }
  }

  &__thumb {
    width: calc(50% - 14px);

    @include media-sp {
      width: 100%;
    }
  }
}

.xork-products {
  background: $gray_20;
}

.xork-cta {
  background: url(/special/xork/assets/img/bg_cta-pc.png) no-repeat center center / cover;
  color: $white;
  text-align: center;

  @include media-sp {
    background-image: url(/special/xork/assets/img/bg_cta-sp.png);
  }

  &__lead {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 24px;

    @include media-sp {
      font-size: 3.4rem;
      margin-bottom: 16px;
    }
  }
}

.xork-access {
  background: $gray_20;
}

.xork-access-map {

  @include media-pc-tb {
    display: flex;
    align-items: center;
    gap: 28px;

    &__txt {
      flex: 1;
    }

    &__img {
      width: 42%;
    }
  }

  @include media-sp {
    &__txt {
      margin-bottom: 32px;
    }
  }

  &__name {
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 24px;
  }

  &__item {
    &+& {
      margin-top: 24px;
    }

    display: flex;
    gap: 8px;

    span {
      display: block;

      &:first-child {
        width: 16px;
        height: 1.75em;
        display: flex;
      }

      &:last-child {
        flex: 1;
      }
    }
  }
}

.xork-share-list {
  display: flex;
  justify-content: center;
  gap: 28px;

  @media (hover: hover) {
    a:hover {
      opacity: 0.7;
    }
  }
}
