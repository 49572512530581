@use '../../base/setting' as *;

.consumer-column-topics {
  @include media-pc-tb {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include media-sp {
    display: block;
  }

  &__img {
    border-radius: 16px;
    overflow: hidden;

    @include media-pc-tb {
      width: calc(60% - 20px);
    }

    @include media-sp {
      border-radius: 8px;
      margin-bottom: 16px;
    }

    img {
      width: 100%;
      @include transition;
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include media-pc-tb {
      width: calc(40% - 20px);
    }
  }

  &__tag {
    display: flex;
    margin-right: 16px;
    padding-right: 16px;
    position: relative;

    @include media-sp {
      margin-right: 12px;
      padding-right: 12px;
    }

    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 24px;
      background: $navy_20;
      @include centering(false, true);
      right: 0;

      @include media-sp {
        height: 16px;
      }
    }
  }

  &__date {
    font-size: 1.4rem;
    font-weight: 700;
    color: $navy_80;

    @include media-sp {
      font-size: 1.2rem;
    }
  }

  &__ttl {
    width: 100%;
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.5;
    margin-top: 20px;

    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-weight: normal;
      font-size: 2.4rem;
      line-height: 1;
      color: $orange;
      margin: 0 0.25em;

      @include media-sp {
        font-size: 1.8rem;
      }
    }

    @include media-sp {
      font-size: 2rem;
      margin-top: 12px;
    }
  }

  @media (hover: hover) {
    &:hover {
      .consumer-column-topics__img {
        img {
          transform: scale(1.1, 1.1);
        }
      }

      .consumer-column-topics__ttl span {
        text-decoration: underline;
      }
    }
  }
}

.consumer-column-item {
  display: block;

  &:only-child {
    height: 100%;
  }

  &__img {
    margin-bottom: 16px;
    border-radius: 16px;
    border: 1px solid $navy_20;
    overflow: hidden;

    @include media-sp {
      border-radius: 8px;
    }

    img {
      width: 100%;
      @include transition;
    }
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__tag {
    display: flex;
    margin-right: 12px;
    padding-right: 12px;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 16px;
      background: $navy_20;
      @include centering(false, true);
      right: 0;
    }
  }

  &__date {
    white-space: nowrap;
    font-size: 1.4rem;
    font-weight: 700;
    color: $navy_80;

    >.c-tag {
      margin-left: 8px;
    }

    @include media-sp {
      font-size: 1.2rem;
    }
  }

  &__ttl {
    font-weight: 700;
    line-height: 1.5;
    margin-top: 20px;

    &::after {
      content: "\e90a";
      font-size: 1.6rem;
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
      margin: 0 0.25em;
    }

    @include media-sp {
      margin-top: 12px;
    }
  }

  @media (hover: hover) {
    &:hover {
      .consumer-column-item__img {
        img {
          transform: scale(1.1, 1.1);
        }
      }

      .consumer-column-item__ttl span {
        text-decoration: underline;
      }
    }
  }
}

.consumer-column-category-list {
  display: flex;
  gap: 16px 28px;

  &__item {
    flex: 1;
    display: flex;
    align-items: center;
    font-weight: 700;
    line-height: 1.5;
    background: $beige_20;
    border-radius: 16px;
    padding: 16px 32px 16px 24px;
    position: relative;

    &::after {
      content: "\e90a";
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      @include centering(false, true);
      right: 16px;
      color: $orange;
    }

    @include media-sp {
      border-radius: 8px;
      min-height: 80px;
    }

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include media-sp {
    flex-direction: column;
  }
}

.consumer-column-detail {

  &__info {
    display: flex;
    align-items: center;
    gap: 12px 20px;
    margin-bottom: 40px;

    @include media-sp {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 30px;
    }
  }

  &__tag {
    .c-tag {
      font-size: 1.4rem;
    }
  }

  &__date {
    font-size: 1.8rem;
    font-weight: 700;
    color: $navy;

    @include media-sp {
      font-size: 1.4rem;
    }
  }

  &__ttl {
    font-size: 3.4rem;
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: 30px;

    @include media-sp {
      font-size: 2.8rem;
    }
  }

  &__share {
    margin-bottom: 50px;

    @include media-sp {
      margin-bottom: 30px;
    }
  }

  &__kv {
    border-radius: 16px;
    overflow: hidden;

    @include media-sp {
      border-radius: 8px;
    }
  }
}

.consumer-column-detail-bottom {
  &__category {
    padding: 30px;
    border: 1px solid $navy_20;
    border-radius: 16px;
    margin-bottom: 40px;

    @include media-pc-tb {
      display: flex;
      gap: 0 64px;
    }

    @include media-sp {
      padding: 15px;
      border-radius: 8px;
      margin-bottom: 30px;
    }

    >span {
      display: block;
      font-size: 1.8rem;
      font-weight: 700;

      @include media-pc-tb {
        width: 100px;
      }

      @include media-sp {
        margin-bottom: 8px;
      }
    }
  }
}
