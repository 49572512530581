@use '../base/setting' as *;

.c-getreader {
  display: flex;
  gap: 20px 40px;
  align-items: center;

  @include media-sp {
    align-items: flex-start;
    flex-direction: column;
  }

  a,
  img,
  span {
    display: block;
  }

  span {
    flex: 1;
  }

  @media (hover: hover) {
    a:hover {
      opacity: 0.7;
    }
  }
}
