/* ============================================================ */
/* settings */
/* ============================================================ */

/* colors
----------------------------------------------------------- */
$white: #fff;
$black: #000;
$base-font-color: #333333;

$orange: #E63D30;
$orange_80: #EA6359;
$orange_60: #EF8A82;
$orange_40: #F4B0AB;
$orange_20: #F9D7D4;

$navy: #003349;
$navy_80: #325B6D;
$navy_60: #658491;
$navy_40: #98ACB5;
$navy_20: #CBD5DA;

$beige: #D1CCBD;
$beige_80: #DAD6CA;
$beige_60: #E3E0D7;
$beige_40: #ECEAE4;
$beige_20: #F5F4F1;

$green: #0eaa00;
$red: #EA2426;
$bg-danger: #FDF2F0;
$border-danger: #EF9E9F;
$bg-warning: #fffedf;

$gray: #B7C9D3;
$gray_80: #C5D3DB;
$gray_60: #D3DEE4;
$gray_40: #E2E9ED;
$gray_20: #F0F4F6;

/* width
----------------------------------------------------------- */
$base-content-width: 1520px;
$narrow-content-width: 1136px;

/* box-shadow
----------------------------------------------------------- */
$base-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);

/* font
----------------------------------------------------------- */

/*----- base-font-family -----*/
$base-font-family: 'Noto Sans JP', Helvetica, Arial, 'ヒラギノ角ゴ', '游ゴシック', 'メイリオ', sans-serif;

/*----- base-font-family (serif) -----*/
/*$font-serif: 'Hiragino Mincho ProN', 'Yu Mincho', 游明朝, 'HGS明朝E', serif;*/


/* ============================================================ */
/* mediaquery */
/* ============================================================ */

@mixin media-ie11 {

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    @content;
  }
}

@mixin media-pc-s {
  @media (min-width: 1280px) and (max-width: 1366px) {
    @content;
  }
}

@mixin media-pc-tb {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin media-pc {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin media-tb {
  @media (max-width: 1279px) {
    @content;
  }
}

@mixin media-sp {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin media-sp-s {
  @media (max-width: 767px) {
    @content;
  }
}

/* ============================================================ */
/* utility */
/* ============================================================ */

/* circle
----------------------------------------------------------- */
@mixin circle($size) {
  width: $size + px;
  height: $size + px;
  border-radius: 50%;
}

/* transition
----------------------------------------------------------- */
@mixin transition {
  transition: 0.3s all;
}

/* centering
----------------------------------------------------------- */
@mixin centering($horizontal: true, $vertical: true) {
  // @include centering(true, true);
  position: absolute;

  @if $horizontal and $vertical {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @else if $horizontal {
    left: 50%;
    transform: translate(-50%, 0);
  }

  @else if $vertical {
    top: 50%;
    transform: translate(0, -50%);
  }
}
