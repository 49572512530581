@use '../base/setting' as *;

.c-title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 29vw;
  max-height: 560px;
  padding: 0 115px;
  position: relative;

  @include media-pc-s {
    padding: 0 72px;
  }

  @include media-tb {
    padding: 0 48px;
  }

  @include media-sp {
    padding: 0 20px;
    height: 53vw;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background: url(/assets/img/bg_title.png) no-repeat left center / cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__inner {
    width: 100%;
    max-width: $base-content-width;
    margin: 0 auto;
    position: relative;
    z-index: 2;

    &.-narrow {
      max-width: $narrow-content-width;
    }

    &.-xnarrow {
      max-width: 744px;
    }
  }

  &__heading {
    h1 {
      font-weight: 700;
      color: $white;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @include media-sp {
        gap: 8px;
      }

      span {
        line-height: 1.2;

        &:first-child {
          font-size: 4.2rem;

          @include media-sp {
            font-size: 2.6rem;
          }
        }

        &:last-child {
          font-size: 1.4rem;

          @include media-sp {
            font-size: 1rem;
          }
        }
      }
    }

    &.-black {
      h1 {
        color: $base-font-color;
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include media-sp {
      width: 100%;
    }
  }

  &.-consumer {
    &::before {
      background-image: url(/consumer/assets/img/bg_title.png);
    }

    .c-title__heading {
      h1 {
        color: $base-font-color;
      }
    }

    @include media-sp {
      &::before {
        background-position: 25% center;
      }
    }
  }

  &.-company {
    &::before {
      display: none;
    }

    .c-title__heading {

      h1 {
        span {
          &:first-child {
            font-size: 4rem;

            @include media-sp {
              font-size: 3rem;
            }
          }

          &:last-child {
            font-size: 1.6rem;
            color: $orange;

            @include media-sp {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }

  &.-simple {
    height: inherit;
    background: $gray_20;

    &::before {
      display: none;
    }

    @include media-sp {
      height: inherit;
      min-height: 100px;
      padding: 20px;
    }

    .c-title__inner {
      padding: 48px 0;

      @include media-sp {
        padding: 0;
      }
    }

    .c-title__heading {

      h1 {
        font-size: 3.4rem;
        line-height: 1.5;
        color: $base-font-color;

        @include media-sp {
          font-size: 2.8rem;
        }
      }
    }

    &.-beige {
      background: $beige_20;
    }
  }
}
