@use '../base/setting' as *;

.seminar-list {
  &__item {
    &:not(:last-child) {
      margin-bottom: 50px;

      @include media-sp {
        margin-bottom: 32px;
      }
    }
  }
}