@use '../base/setting' as *;

.c-area-link {
  &__item {
    padding: 32px 0;
    border-bottom: 1px solid $gray_60;

    @include media-pc-tb {
      display: flex;
      gap: 20px 0;

      dt {
        width: 200px;
      }

      dd {
        flex: 1;
      }
    }

    @include media-sp {
      padding-top: 24px;

      dt {
        margin-bottom: 16px;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 16px 32px;

      @include media-sp {
        gap: 16px 24px;
      }

      li a {
        position: relative;

        &::after {
          content: "\e90a";
          font-family: 'icomoon';
          font-weight: normal;
          line-height: 1;
          margin-left: 0.25em;
          color: $orange;
        }

        @media (hover: hover) {
          &:hover span {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.c-dealer {

  &__map {
    margin-bottom: 20px;

    @include media-sp {
      margin-bottom: 10px;
    }

    >div {
      width: 100%;
      height: 400px;

      @include media-sp {
        height: 350px;
      }
    }
  }

  &__map-link {
    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.c-dealer-item {
  padding: 32px 0;
  border-bottom: 1px solid $gray_60;

  &__name {
    font-size: 1.8rem;
    font-weight: 700;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    @include media-sp {
      font-size: 1.6rem;
    }
  }

  &__address {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__info {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    @include media-pc-tb {
      display: flex;
      gap: 40px;
    }

    >div {
      width: calc(50% - 20px);

      @include media-sp {
        display: contents;
      }

      >span {
        display: block;

        >span {
          display: inline-block;
          width: 100px;

          @include media-sp {
            width: 96px;
          }
        }
      }
    }
  }
}

.c-area-accordion {
  border-top: 1px solid $navy_20;
  border-bottom: 1px solid $navy_20;
  @include transition;

  &+& {
    border-top: 0;
  }

  &__ttl {
    font-size: 1.8rem;
    font-weight: 700;
    padding: 20px 64px 20px 20px;
    position: relative;
    cursor: pointer;
    @include transition;

    @include media-sp {
      font-size: 1.6rem;
      padding: 15px 64px 15px 15px;
    }

    >div {
      &:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 1px solid $navy_20;
        border-radius: 100%;
        background: $white;
        @include centering(false, true);
        right: 20px;

        @include media-sp {
          right: 15px;
        }

        &::after {
          content: "\e918";
          font-family: 'icomoon';
          font-weight: normal;
          line-height: 1;
          color: $orange;
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        background: #F6F8F9;
      }
    }
  }

  &.-active {
    background: #F6F8F9;

    .c-area-accordion__ttl>div:last-child::after {
      content: "\e917";
    }
  }

  &__contents {
    display: none;
    padding: 0 20px 30px;

    @include media-sp {
      padding: 0px 15px 20px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 16px 32px;

      @include media-sp {
        gap: 16px 24px;
      }

      li a {
        position: relative;

        &::after {
          content: "\e90a";
          font-family: 'icomoon';
          font-weight: normal;
          line-height: 1;
          margin-left: 0.25em;
          color: $orange;
        }

        @media (hover: hover) {
          &:hover span {
            text-decoration: underline;
          }
        }
      }
    }
  }
}