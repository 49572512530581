@use '../../base/setting' as *;

.consumer-showroom-news {
  padding: 32px 0;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  font-size: 1.6rem;

  h2 {
    font-weight: 700;

    @include media-sp {
      margin-bottom: 16px;
    }
  }

  @include media-pc-tb {
    display: flex;
    gap: 30px;

    span {
      flex: 1;
    }
  }
}
