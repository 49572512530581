@use '../base/setting' as *;

.c-tab {
  &__index {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 40px;

    @include media-sp {
      margin-bottom: 30px;
    }

    .c-tab__contents+& {
      margin: 40px 0 0 0;

      @include media-sp {
        margin: 30px 0 0 0;
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.5;
    position: relative;
    @include transition;

    @include media-pc-tb {
      flex: 1;
    }

    @include media-pc-tb {
      &.-pc-col-3 {
        flex: none;
        width: calc(100%/4);
      }
    }

    @include media-sp {
      width: 50%;

      &.-sp-col-12 {
        flex: none;
        width: 100%;
      }
    }

    &.-active {
      background: #F6F8F9;

      &::after {
        content: "";
        display: block;
        background: $orange;
        @include centering(true, false);
        bottom: 0;
      }
    }

    @media (hover: hover) {
      &:hover {
        background: #F6F8F9;
      }
    }
  }

  &.-type01 {
    @include media-sp {
      margin: 0 -20px;

      .c-tab__contents {
        padding: 0 20px;
      }
    }

    .c-tab__btn {
      font-size: 2rem;
      min-height: 60px;
      padding: 12px 4px;
      border-bottom: 1px solid $navy_20;

      &:not(:last-child) {
        border-right: 2px solid $white;
      }

      @include media-sp {
        font-size: 1.8rem;
      }

      &.-active {
        font-weight: 700;

        &::after {
          width: 100%;
          height: 3px;
        }
      }
    }
  }

  &.-type02 {
    @include media-sp {
      border-top: 1px solid $navy_20;
    }

    .c-tab__btn {
      min-height: 60px;
      padding: 12px 4px;
      border: 1px solid $navy_20;
      border-left: 0;

      @include media-pc-tb {
        &:first-child {
          border-left: 1px solid $navy_20;
        }
      }

      @include media-sp {
        border-top: 0;

        &:nth-child(odd) {
          border-left: 1px solid $navy_20;
        }
      }

      &.-active {
        font-weight: 700;

        &::after {
          width: 100%;
          height: 3px;
        }
      }
    }
  }

  &.-type03 {
    .c-tab__index {
      gap: 20px 0;

      @include media-sp {
        gap: 10px 0;
      }
    }

    .c-tab__btn {
      min-height: 54px;
      padding: 12px;
      font-weight: 700;
      border-right: 1px solid $navy_20;

      &:first-child {
        border-left: 1px solid $navy_20;
      }

      @include media-pc-tb {
        &.-pc-col-3 {
          &:nth-child(4n+1) {
            border-left: 1px solid $navy_20;
          }
        }
      }

      @include media-sp {
        min-height: 44px;

        &:nth-child(odd) {
          border-left: 1px solid $navy_20;
        }
      }

      &.-active {
        &::after {
          width: calc(100% - 20px);
          height: 4px;
        }
      }
    }
  }
}
