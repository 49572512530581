@use '../base/setting' as *;

.c-heading {
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.5;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &.-large {
    font-size: 3.4rem;
  }

  &.-medium {
    font-size: 2.2rem;
  }

  &.-small {
    font-size: 2rem;
  }

  &.-xsmall {
    font-size: 1.8rem;
  }

  &.-xxsmall {
    font-size: 1.6rem;
  }

  @include media-sp {
    font-size: 2.4rem;

    &:not(:last-child) {
      margin-bottom: 16px;
    }


    &.-large {
      font-size: 2.8rem;
    }

    &.-medium {
      font-size: 2.1rem;
    }

    &.-small {
      font-size: 1.9rem;
    }
  }

  &.-en {
    span {
      display: block;

      &:first-child {
        font-size: 2.8rem;

        @include media-sp {
          font-size: 2.4rem;
        }
      }

      &:last-child {
        font-size: 1.2rem;

        @include media-sp {
          font-size: 1rem;
        }
      }
    }
  }

  &.-accent {
    position: relative;
    padding-left: 1em;

    &::before {
      content: "";
      display: block;
      width: 14px;
      height: 3px;
      background: $orange;
      position: absolute;
      top: 0.8em;
      left: 0;
      transform: rotate(-45deg);
    }
  }

  &.-line {
    position: relative;
    border-bottom: 1px solid $navy_60;
    padding-bottom: 16px;
  }

  &.-line-gray {
    position: relative;
    border-bottom: 1px solid $gray_80;
    padding-bottom: 16px;
  }

  &.-border-navy {
    position: relative;
    padding-left: 0.75em;

    &::before {
      content: "";
      width: 2px;
      height: 1.2em;
      background: $navy;
      position: absolute;
      top: 0.2em;
      left: 0;
    }
  }

  &.-border-orange {
    position: relative;
    padding-left: 0.75em;

    &::before {
      content: "";
      width: 2px;
      height: 1.2em;
      background: $orange;
      position: absolute;
      top: 0.2em;
      left: 0;
    }
  }

  &.-icon {
    position: relative;
    padding-left: 58px;

    img {
      width: 40px;
      position: absolute;
      top: 0;
      left: 0;
    }

    @include media-sp {
      padding-left: 42px;

      img {
        width: 30px;
      }
    }

    &.-medium img {
      top: 1px;

      @include media-sp {
        top: 3px;
      }
    }

    &.-large img {
      top: 5px;
    }
  }
}

.c-headimg-image {
  position: relative;
  width: 100%;
  min-height: 255px;

  @include media-sp {
    min-height: 188px;
    margin: 0 -20px;
    width: calc(100% + 40px);
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 255px;
    padding: 40px;
    color: $white;
    position: relative;
    z-index: 5;
    font-weight: 700;

    h2 {
      font-size: 2.8rem;
      line-height: 1.5;
      position: relative;
      padding-left: 0.75em;
      margin-bottom: 10px;

      &::before {
        content: "";
        width: 2px;
        height: 1.2em;
        background: $orange;
        position: absolute;
        top: 0.2em;
        left: 0;
      }
    }

    @include media-sp {
      padding: 20px;
      min-height: 188px;

      h2 {
        font-size: 2.4rem;
      }

      p {
        font-size: 1.2rem;
      }
    }
  }
}