@use '../base/setting' as *;

.c-pagelink {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 28px;
  padding: 32px 40px;
  border: 1px solid $navy_20;
  background: $white;

  &.-beige {
    border: 0;
    background: $beige_20;
    gap: 20px 40px;
    border-radius: 16px;

    @include media-sp {
      border-radius: 8px;
    }
  }

  @include media-sp {
    gap: 15px 30px;
    padding: 25px 20px;
  }

  &__link {
    font-weight: 700;

    &::after {
      content: "\e902";
      margin-left: 0.25em;
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
    }

    @include media-pc-tb {

      .c-pagelink:not(.-beige) &:not(:last-child) {
        padding-right: 28px;
        position: relative;

        &::before {
          content: "";
          display: block;
          width: 1px;
          height: 1.8em;
          background: $navy_20;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    @media (hover: hover) {
      &:hover span {
        text-decoration: underline;
      }
    }
  }
}

.c-pagelink-image {
  display: flex;
  gap: 4px;

  @include media-sp {
    flex-wrap: wrap;
    gap: 12px 2px;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 10px 22px 10px 10px;
    position: relative;

    @include media-pc-tb {
      flex: 1;
    }

    @include media-sp {
      padding: 10px 14px 8px 4px;
      width: calc((100% - 6px)/4);
    }

    &::before {
      content: "";
      width: calc(100% - 20px);
      height: 100%;
      background: #F2F5F7;
      position: absolute;
      top: 0;
      left: 0;

      @include media-sp {
        width: calc(100% - 15px);
      }
    }

    &::after {
      content: "";
      width: 20px;
      height: 100%;
      clip-path: polygon(0 0, 100% 50%, 0 100%);
      background: #F2F5F7;
      position: absolute;
      top: 0;
      right: 0;

      @include media-sp {
        width: 15px;
      }
    }

    img,
    span {
      position: relative;
      z-index: 5;
    }

    img {
      width: 40px;

      @include media-sp {
        width: 30px;
      }
    }

    span {
      &:first-of-type {
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.3;
        text-align: center;

        @include media-sp {
          font-size: 1.2rem;
        }
      }

      &:last-of-type {
        font-size: 1.4rem;
        line-height: 1;

        &::after {
          content: "\e902";
          font-family: 'icomoon';
          font-weight: normal;
          color: $orange;
        }
      }
    }

    @media (hover: hover) {
      &:hover span:first-of-type {
        text-decoration: underline;
      }
    }
  }
}

.c-pagelink-solution {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 28px;

  @include media-pc-tb {
    padding: 32px 40px;
    border: 1px solid $navy_20;
    background: $white;
  }

  @include media-sp {
    gap: 15px 14px;
  }

  &__link {
    line-height: 1.4;
    font-weight: 700;
    text-align: center;

    &::after {
      content: "\e902";
      margin-left: 0.25em;
      font-family: 'icomoon';
      font-weight: normal;
      line-height: 1;
      color: $orange;
    }

    @include media-pc-tb {
      &:not(:last-child) {
        padding-right: 28px;
        position: relative;

        &::before {
          content: "";
          display: block;
          width: 1px;
          height: 1.8em;
          background: $navy_20;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    @include media-sp {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(50% - 7px);
      height: 56px;
      border: 1px solid $navy_20;
      padding: 10px 20px;
      position: relative;

      &::after {
        @include centering(false, true);
        right: 8px;
      }
    }

    @media (hover: hover) {
      &:hover span {
        text-decoration: underline;
      }
    }
  }
}