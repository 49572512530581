@use '../base/setting' as *;

.l-contact {
  padding: 0 16px 16px 16px;
  margin-top: 16px;

  &__inner {
    background: rgba(#FFEBEA, 0.8);
    text-align: center;
    padding: 50px 16px 64px;
  }

  &__heading {
    text-align: center;
    margin-bottom: 16px;
  }

  &__txt {
    font-size: 1.8rem;
    margin-bottom: 40px;

    @include media-sp {
      font-size: 1.6rem;
      margin-bottom: 30px;
    }
  }
}