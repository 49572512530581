@use '../../base/setting' as *;

.about-loop {
  display: flex;
  align-items: center;
  overflow: hidden;

  &__txt {
    display: flex;
    align-items: center;
    animation: loop infinite linear 80s both;

    li {
      width: 4420px;

      @include media-sp {
        width: 3890px;
      }
    }
  }

  &__img {
    display: flex;
    align-items: center;
    animation: loop infinite linear 60s both;

    li {
      margin: 0 50px;
      position: relative;
      height: 264px;

      @include media-sp {
        height: 154px;
      }

      img {
        position: absolute;
        top: calc(50% - 132px);
        left: 0;

        @include media-sp {
          top: calc(50% - 77px);
        }

        &:first-child {
          opacity: 1;
        }

        &:last-child {
          opacity: 0;
        }
      }

      @include media-sp {
        margin: 0 25px;
      }

      &:nth-child(odd) {
        width: 352px;

        @include media-sp {
          width: 205px;
        }
      }

      &:nth-child(even) {
        width: 226px;

        @include media-sp {
          width: 131px;
        }
      }
    }
  }

  &__anime {

    &.-omote {
      animation: rotate-omote 60s linear infinite;
    }

    &.-ura {
      animation: rotate-ura 60s linear infinite;
    }

    &.-delay-2 {
      animation-delay: 2s;
    }

    &.-delay-6 {
      animation-delay: 6s;
    }

    &.-delay-10 {
      animation-delay: 10s;
    }

    &.-delay-14 {
      animation-delay: 14s;
    }

    &.-delay-17 {
      animation-delay: 17s;
    }

    &.-delay-21 {
      animation-delay: 21s;
    }

    &.-delay-25 {
      animation-delay: 25s;
    }

    &.-delay-29 {
      animation-delay: 29s;
    }

    @include media-sp {
      &.-omote {
        animation: rotate-omote 30s linear infinite;
      }

      &.-ura {
        animation: rotate-ura 30s linear infinite;
      }

      &.-delay-2 {
        animation-delay: 1s;
      }

      &.-delay-6 {
        animation-delay: 3s;
      }

      &.-delay-10 {
        animation-delay: 5s;
      }

      &.-delay-14 {
        animation-delay: 7s;
      }

      &.-delay-17 {
        animation-delay: 8s;
      }

      &.-delay-21 {
        animation-delay: 10s;
      }

      &.-delay-25 {
        animation-delay: 12s;
      }

      &.-delay-29 {
        animation-delay: 9s;
      }
    }
  }
}

@keyframes loop {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes rotate-omote {
  0% {
    transform: rotateY(0);
    opacity: 1;
  }

  9% {
    transform: rotateY(0);
    opacity: 1;
  }

  10% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  49% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  50% {
    transform: rotateY(0);
    opacity: 1;
  }

  100% {
    transform: rotateY(0);
    opacity: 1;
  }
}

@keyframes rotate-ura {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  9% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  10% {
    transform: rotateY(0);
    opacity: 1;
  }

  49% {
    transform: rotateY(0);
    opacity: 1;
  }

  50% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  100% {
    transform: rotateY(180deg);
    opacity: 0;
  }
}

@keyframes rotate-omote-sp {
  0% {
    transform: rotateY(0);
    opacity: 1;
  }

  8% {
    transform: rotateY(0);
    opacity: 1;
  }

  10% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  48% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  50% {
    transform: rotateY(0);
    opacity: 1;
  }

  100% {
    transform: rotateY(0);
    opacity: 1;
  }
}

@keyframes rotate-ura-sp {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  8% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  10% {
    transform: rotateY(0);
    opacity: 1;
  }

  48% {
    transform: rotateY(0);
    opacity: 1;
  }

  50% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  100% {
    transform: rotateY(180deg);
    opacity: 0;
  }
}

@keyframes BgAnimePc {
  0% {
    background-image: url(/company/about/assets/img/bg_brand01-pc.jpg);
  }

  20% {
    background-image: url(/company/about/assets/img/bg_brand01-pc.jpg);
  }

  35% {
    background-image: url(/company/about/assets/img/bg_brand02-pc.jpg);
  }

  53% {
    background-image: url(/company/about/assets/img/bg_brand02-pc.jpg);
  }

  68% {
    background-image: url(/company/about/assets/img/bg_brand03-pc.jpg);
  }

  85% {
    background-image: url(/company/about/assets/img/bg_brand03-pc.jpg);
  }

  100% {
    background-image: url(/company/about/assets/img/bg_brand01-pc.jpg);
  }
}

@keyframes BgAnimeSp {
  0% {
    background-image: url(/company/about/assets/img/bg_brand01-sp.jpg);
  }

  20% {
    background-image: url(/company/about/assets/img/bg_brand01-sp.jpg);
  }

  35% {
    background-image: url(/company/about/assets/img/bg_brand02-sp.jpg);
  }

  53% {
    background-image: url(/company/about/assets/img/bg_brand02-sp.jpg);
  }

  68% {
    background-image: url(/company/about/assets/img/bg_brand03-sp.jpg);
  }

  85% {
    background-image: url(/company/about/assets/img/bg_brand03-sp.jpg);
  }

  100% {
    background-image: url(/company/about/assets/img/bg_brand01-sp.jpg);
  }
}

.about-block-brand {
  background: no-repeat center center / cover;
  animation: BgAnimePc 20s ease infinite;
  padding: 52px 115px;
  color: $white;

  @include media-pc-s {
    padding: 52px 72px;
  }

  @include media-tb {
    padding: 52px 48px;
  }

  @include media-sp-s {
    margin: 0;
    padding: 32px 20px 56vw;
    background-position: center bottom;
    animation: BgAnimeSp 20s ease infinite;
  }

  &__inner {
    max-width: $narrow-content-width;
    margin: 0 auto;

    p {
      margin-bottom: 35px;

      @include media-sp-s {
        text-align: center;
        margin-bottom: 25px;
      }
    }
  }

  &__heading {
    margin-bottom: 32px;

    @include media-sp-s {
      text-align: center;
      margin-bottom: 25px;
    }

    img {
      width: 100%;
      max-width: 510px;

      @include media-sp-s {
        max-width: 320px;
      }
    }
  }

  p {
    font-size: 1.8rem;
    font-weight: 700;

    @include media-sp-s {
      font-size: 1.4rem;
      line-height: 2;
    }
  }

  .c-btn-wrap.-pc-left {
    @media (min-width: 768px) {
      justify-content: flex-start;
    }
  }
}

.about-history-tab-btn {

  >span {
    display: block;
    width: fit-content;

    @include media-sp {
      width: 100%;
    }

    >span {
      display: block;

      &:first-child {
        font-size: 1.4rem;
        font-weight: 400;

        @include media-sp {
          font-size: 1.2rem;
        }
      }

      &:last-child {
        font-size: 1.5rem;
        line-height: 1.5;
        margin-top: 4px;

        @include media-sp {
          font-size: 1.3rem;
          line-height: 1.4;
        }
      }
    }
  }

  @include media-sp {
    &.-sp-simple {
      >span {
        >span {
          &:first-child {
            text-align: center;
            font-weight: 700;
            font-size: 1.4rem;
          }

          &:last-child {
            display: none;
          }
        }
      }
    }
  }
}

.about-history-list {
  &__split {
    position: relative;

    @include media-pc-tb {
      display: flex;

      &:first-child {
        .about-history-list__year::before {
          height: calc(100% - 0.5em);
          top: 0.5em;
        }
      }

      &+& {
        margin-top: 50px;

        &::before {
          content: "";
          display: block;
          width: 2px;
          height: 50px;
          background: $orange;
          position: absolute;
          top: -50px;
          left: 98px;
        }
      }
    }

    @include media-sp {
      padding-left: 26px;

      &::before {
        content: "";
        display: block;
        width: 2px;
        height: calc(100% - 10px);
        background: $orange;
        position: absolute;
        top: 10px;
        left: 4px;
      }

      &::after {
        content: "";
        display: block;
        width: 11px;
        height: 11px;
        border-radius: 100%;
        background: $orange;
        position: absolute;
        top: 10px;
        left: 0;
      }

      &+& {
        margin-top: 50px;

        &::before {
          height: calc(100% + 50px);
          top: -50px;
        }
      }
    }
  }

  &__year {
    font-size: 3.4rem;
    line-height: 1;
    color: $orange;
    position: relative;

    @include media-pc-tb {
      width: 100px;

      &::before {
        content: "";
        display: block;
        width: 2px;
        height: 100%;
        background: $orange;
        position: absolute;
        top: 0;
        right: 0;
      }

      &::after {
        content: "";
        display: block;
        width: 11px;
        height: 11px;
        border-radius: 100%;
        background: $orange;
        position: absolute;
        top: calc(0.5em - 5px);
        right: -5px;
      }
    }

    @include media-sp {
      font-size: 2.6rem;
      margin-bottom: 30px;
    }
  }

  &__item {

    @include media-pc-tb {
      margin-top: 0.25em;
      padding-left: 40px;
      flex: 1;
    }

    dl {
      >div {

        @include media-pc-tb {
          display: flex;
        }

        &:not(:last-child) {
          margin-bottom: 50px;
        }

        dt {
          font-weight: 700;
          margin-bottom: 5px;

          @include media-pc-tb {
            width: 72px;
          }
        }

        dd {
          @include media-pc-tb {
            flex: 1;
          }

          a {
            color: $navy_60;

            span {
              text-decoration: underline;
            }

            &::after {
              content: "\e90a";
              font-family: 'icomoon';
              font-weight: normal;
              line-height: 1;
              color: $orange;
              margin-left: 0.25em;
            }

            &[target="_blank"]::after {
              content: "\e900";
            }

            @include media-pc-tb {
              &:hover span {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

  &__img-block {
    display: flex;
    gap: 20px;
    margin-top: 20px;

    @include media-sp {
      gap: 16px;
    }

    figure {
      max-width: 253px;
      width: calc(50% - 8px);

      @include media-sp {
        width: 147px;
        max-width: calc(50% - 8px);
      }

      figcaption {
        font-size: 1.2rem;
        margin-top: 10px;
      }
    }
  }
}

.about-process-flow {
  width: 120px;
  height: 40px;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
  background: $navy_20;
  margin: 32px auto;
}

.about-process-capitals-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  li {
    display: flex;
    flex-direction: column;

    @include media-pc-tb {
      width: calc((100% - 150px) / 6);
    }

    @include media-sp {
      width: calc(50% - 15px);
    }

    span {

      &:first-child {
        text-align: center;
        margin-bottom: 12px;
      }

      &:nth-child(2) {
        font-weight: 700;
        margin-bottom: 6px;
      }

      &:last-child {
        font-size: 1.4rem;

        @include media-sp {
          font-size: 1.3rem;
        }
      }
    }
  }
}

.about-process-business-list {
  display: flex;
  gap: 4px;

  @include media-sp {
    flex-wrap: wrap;
    gap: 12px 2px;
  }

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 10px 22px 10px 2px;
    position: relative;

    @include media-pc-tb {
      flex: 1;
    }

    @include media-sp {
      padding: 10px 14px 8px 2px;
      width: calc((100% - 6px)/4);
    }

    &::before {
      content: "";
      width: calc(100% - 20px);
      height: 100%;
      background: #F2F5F7;
      position: absolute;
      top: 0;
      left: 0;

      @include media-sp {
        width: calc(100% - 15px);
      }
    }

    &::after {
      content: "";
      width: 20px;
      height: 100%;
      clip-path: polygon(0 0, 100% 50%, 0 100%);
      background: #F2F5F7;
      position: absolute;
      top: 0;
      right: 0;

      @include media-sp {
        width: 15px;
      }
    }

    img,
    span {
      position: relative;
      z-index: 5;
    }

    img {
      width: 40px;

      @include media-sp {
        width: 30px;
      }
    }

    span {
      flex: 1;
      display: flex;
      align-items: center;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 1.3;
      text-align: center;

      @include media-sp {
        font-size: 1.2rem;
      }
    }
  }
}

.about-process-outcome-list {
  display: flex;
  gap: 15px 30px;

  @include media-sp {
    flex-direction: column;
  }

  li {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 116px;
    height: 100%;
    padding: 20px 10px;
    border: 8px solid #F2F5F7;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;

    small {
      font-size: 1.8rem;
    }

    strong {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 4.4rem;
      line-height: 1.2;
    }
  }
}


.about-officer-profile {
  &+& {
    margin-top: 20px;
    padding-top: 40px;
    border-top: 1px solid $navy_20;

    @include media-sp {
      margin-top: 40px;
      padding-top: 40px;
    }
  }

  @include media-pc-tb {
    display: grid;
    grid-template-columns: 280px 1fr;
    grid-template-rows: auto 1fr;
    gap: 20px 40px;

    &__img {
      grid-area: 1 / 1 / 2 / 2;
    }

    &__data {
      grid-area: 2 / 1 / 3 / 2;
    }

    &__career {
      grid-area: 1 / 2 / 3 / 3;
    }
  }

  &__img {
    img {
      max-width: 254px;
    }

    @include media-sp {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  &__data {
    @include media-sp {
      margin-bottom: 20px;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    gap: 20px;

    span {
      &:first-child {
        font-size: 2.8rem;
        font-weight: 700;

        @include media-sp {
          font-size: 2.4rem;
        }
      }

      &:last-child {
        font-size: 1.4rem;

        @include media-sp {
          font-size: 1.2rem;
        }
      }
    }
  }

  &__position {
    font-size: 1.4rem;
    font-weight: 700;
    color: $navy_80;

    @include media-sp {
      font-size: 1.2rem;
    }
  }

  &__career {

    dl {
      >div {
        display: flex;
        gap: 10px;

        @include media-sp {
          gap: 5px;
        }

        dt {
          font-weight: 400;
          width: 105px;

          @include media-sp {
            width: 85px;
          }
        }

        dd {
          flex: 1;
        }

        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
  }
}
