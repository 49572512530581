@use '../base/setting' as *;

.c-report {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  @include media-sp {
    flex-direction: column;
    gap: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    border: 1px solid $gray_60;
    padding: 20px;
    background: $white;

    @include media-pc-tb {
      width: calc(50% - 15px);
    }
  }

  &__heading {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.5;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray_60;
    margin-bottom: 16px;

    @include media-sp {
      font-size: 1.6rem;
      padding-bottom: 16px;
    }
  }

  &__date {
    font-size: 1.8rem;
    font-weight: 700;
    color: $navy_80;
    margin-bottom: 4px;

    @include media-sp {
      font-size: 1.6rem;
    }
  }

  &__ttl {
    a {
      font-weight: 700;
      position: relative;

      &::after {
        content: "\e90a";
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        margin-left: 0.5em;
        color: $orange;
      }

      &[target="_blank"]::after {
        content: "\e900";
      }

      &[href*=".pdf"] {
        padding-right: 0.5em;

        &::after {
          content: "\e904";
        }
      }

      @media (hover: hover) {
        &:hover span {
          text-decoration: underline;
        }
      }
    }
  }

  &__archive {
    margin-top: auto;

    a {
      &::after {
        content: "\e90a";
        font-family: 'icomoon';
        font-weight: normal;
        line-height: 1;
        color: $orange;
        margin-left: 0.25em;
      }

      @media (hover: hover) {
        &:hover span {
          text-decoration: underline;
        }
      }
    }
  }

  *:has(+ .c-report__archive) {
    margin-bottom: 32px;

    @include media-sp {
      margin-bottom: 24px;
    }
  }
}
