@use '../base/setting' as *;

/* text-align
----------------------------------------------------------- */

.u-ta-c {
  text-align: center !important;
}

@include media-pc-tb {
  .u-pc-ta-c {
    text-align: center !important;
  }
}

@include media-sp {
  .u-sp-ta-c {
    text-align: center !important;
  }
}

.u-ta-r {
  text-align: right !important;
}

@include media-pc-tb {
  .u-pc-ta-r {
    text-align: right !important;
  }
}

@include media-sp {
  .u-sp-ta-r {
    text-align: right !important;
  }
}

.u-ta-l {
  text-align: left !important;
}

@include media-pc-tb {
  .u-pc-ta-l {
    text-align: left !important;
  }
}

@include media-sp {
  .u-sp-ta-l {
    text-align: left !important;
  }
}

/* font
----------------------------------------------------------- */

.u-font-nomal {
  font-weight: 400 !important;
}

.u-font-medium {
  font-weight: 500 !important;
}

.u-font-bold {
  font-weight: 700 !important;
}

.u-font-red {
  color: $red;
}

.u-font-green {
  color: $green;
}

.u-font-navy80 {
  color: $navy_80;
}

.u-font-s {
  font-size: 1.2rem !important;
}

.u-font-ms {
  font-size: 1.4rem !important;

  @include media-sp {
    font-size: 1.2rem !important;
  }
}

.u-font-m {
  font-size: 1.6rem !important;

  @include media-sp {
    font-size: 1.4rem !important;
  }
}

.u-font-l {
  font-size: 1.8rem !important;

  @include media-sp {
    font-size: 1.6rem !important;
  }
}

@include media-pc-tb {
  .u-pc-font-l {
    font-size: 1.8rem !important;
  }
}

@for $i from 1 through 20 {
  @include media-pc-tb {
    .u-pc-font-#{$i} {
      font-size: #{$i*0.1}rem !important;
    }
  }

  @include media-sp {
    .u-sp-font-#{$i} {
      font-size: #{$i*0.1}rem !important;
    }
  }
}

/* border
----------------------------------------------------------- */

.u-bd-top {
  border-top: 1px solid;
}

.u-bd-gray {
  border-color: $navy_20;
}

/* margin
----------------------------------------------------------- */
@for $i from 0 through 20 {
  .u-mb-#{$i*5} {
    margin-bottom: #{$i*5}px !important;
  }

  .u-mb-#{$i*8} {
    margin-bottom: #{$i*8}px !important;
  }

  .u-mt-#{$i*5} {
    margin-top: #{$i*5}px !important;
  }

  .u-mt-#{$i*8} {
    margin-top: #{$i*8}px !important;
  }

  .u-mr-#{$i*5} {
    margin-right: #{$i*5}px !important;
  }

  .u-ml-#{$i*5} {
    margin-left: #{$i*5}px !important;
  }
}

@for $i from 13 through 25 {
  .u-mb-#{$i*10} {
    margin-bottom: #{$i*10}px !important;
  }

  .u-mt-#{$i*10} {
    margin-top: #{$i*10}px !important;
  }
}

@for $i from 0 through 20 {
  @include media-pc-tb {
    .u-pc-mb-#{$i*5} {
      margin-bottom: #{$i*5}px !important;
    }

    .u-pc-mb-#{$i*8} {
      margin-bottom: #{$i*8}px !important;
    }

    .u-pc-mt-#{$i*5} {
      margin-top: #{$i*5}px !important;
    }

    .u-pc-mt-#{$i*8} {
      margin-top: #{$i*8}px !important;
    }

    .u-pc-mr-#{$i*5} {
      margin-right: #{$i*5}px !important;
    }

    .u-pc-ml-#{$i*5} {
      margin-left: #{$i*5}px !important;
    }
  }
}

@for $i from 0 through 20 {
  @include media-sp {
    .u-sp-mb-#{$i*5} {
      margin-bottom: #{$i*5}px !important;
    }

    .u-sp-mb-#{$i*8} {
      margin-bottom: #{$i*8}px !important;
    }

    .u-sp-mt-#{$i*5} {
      margin-top: #{$i*5}px !important;
    }

    .u-sp-mt-#{$i*8} {
      margin-top: #{$i*8}px !important;
    }

    .u-sp-mr-#{$i*5} {
      margin-right: #{$i*5}px !important;
    }

    .u-sp-ml-#{$i*5} {
      margin-left: #{$i*5}px !important;
    }
  }
}

/* flex
-------------------------------------------------- */

.u-d-f {
  display: flex !important;
}

.u-fxd-r {
  flex-direction: row !important;
}

.u-fxd-rr {
  flex-direction: row-reverse !important;
}

.u-fxd-c {
  flex-direction: column !important;
}

.u-fxd-cr {
  flex-direction: column-reverse !important;
}

.u-fxw-n {
  flex-wrap: nowrap !important;
}

.u-fxw-w {
  flex-wrap: wrap !important;
}

.u-fxw-wr {
  flex-wrap: wrap-reverse !important;
}

.u-jc-fs {
  justify-content: flex-start !important;
}

.u-jc-fe {
  justify-content: flex-end !important;
}

.u-jc-c {
  justify-content: center !important;
}

.u-jc-sb {
  justify-content: space-between !important;
}

.u-jc-sa {
  justify-content: space-around !important;
}

.u-ai-fs {
  align-items: flex-start !important;
}

.u-ai-fe {
  align-items: flex-end !important;
}

.u-ai-c {
  align-items: center !important;
}

.u-ai-b {
  align-items: baseline !important;
}

.u-ai-s {
  align-items: stretch !important;
}

.u-ac-fs {
  align-content: flex-start !important;
}

.u-ac-fe {
  align-content: flex-end !important;
}

.u-ac-c {
  align-content: center !important;
}

.u-ac-sb {
  align-content: space-between !important;
}

.u-ac-sa {
  align-content: space-around !important;
}

.u-ac-s {
  align-content: stretch !important;
}

.u-fx-n {
  flex: none !important;
}

.u-as-a {
  align-self: auto !important;
}

.u-as-fs {
  align-self: flex-start !important;
}

.u-as-fe {
  align-self: flex-end !important;
}

.u-as-c {
  align-self: center !important;
}

.u-as-b {
  align-self: baseline !important;
}

.u-as-s {
  align-self: stretch !important;
}

@include media-sp {
  .u-sp-fxd-r {
    flex-direction: row !important;
  }

  .u-sp-fxd-rr {
    flex-direction: row-reverse !important;
  }

  .u-sp-fxd-c {
    flex-direction: column !important;
  }

  .u-sp-fxd-cr {
    flex-direction: column-reverse !important;
  }

  .u-sp-fxw-n {
    flex-wrap: nowrap !important;
  }

  .u-sp-fxw-w {
    flex-wrap: wrap !important;
  }

  .u-sp-fxw-wr {
    flex-wrap: wrap-reverse !important;
  }

  .u-sp-jc-fs {
    justify-content: flex-start !important;
  }

  .u-sp-jc-fe {
    justify-content: flex-end !important;
  }

  .u-sp-jc-c {
    justify-content: center !important;
  }

  .u-sp-jc-sb {
    justify-content: space-between !important;
  }

  .u-sp-jc-sa {
    justify-content: space-around !important;
  }

  .u-sp-ai-fs {
    align-items: flex-start !important;
  }

  .u-sp-ai-fe {
    align-items: flex-end !important;
  }

  .u-sp-ai-c {
    align-items: center !important;
  }

  .u-sp-ai-b {
    align-items: baseline !important;
  }

  .u-sp-ai-s {
    align-items: stretch !important;
  }

  .u-sp-ac-fs {
    align-content: flex-start !important;
  }

  .u-sp-ac-fe {
    align-content: flex-end !important;
  }

  .u-sp-ac-c {
    align-content: center !important;
  }

  .u-sp-ac-sb {
    align-content: space-between !important;
  }

  .u-sp-ac-sa {
    align-content: space-around !important;
  }

  .u-sp-ac-s {
    align-content: stretch !important;
  }

  .u-sp-fx-n {
    flex: none !important;
  }

  .u-sp-as-a {
    align-self: auto !important;
  }

  .u-sp-as-fs {
    align-self: flex-start !important;
  }

  .u-sp-as-fe {
    align-self: flex-end !important;
  }

  .u-sp-as-c {
    align-self: center !important;
  }

  .u-sp-as-b {
    align-self: baseline !important;
  }

  .u-sp-as-s {
    align-self: stretch !important;
  }
}

.m-block {
  height: 355px;
  background: #FBE8FE;

  &.-green {
    height: 225px;
    background: #A6FFA2;
  }
}
